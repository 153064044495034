import React, {useEffect, useState} from 'react'
import {Fieldset} from "primereact/fieldset";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Panel} from "primereact/panel";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import DHDialog from "../../general/DHDialog";
import DHNumberTextField from "../../general/DHNumberTextField";
import DHSelect from "../../general/DHSelect";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";
import DHIconMinus from "../../general/DHIconMinus";
import {IconButton} from "@mui/material";
import DHIconUp from "../../general/DHIconUp";
import DHIconDown from "../../general/DHIconDown";
import DHButtonSave from "../../general/DHButtonSave";
import DHIconAdd from "../../general/DHIconAdd";

export default function TurnierSetzlisteGruppeDialog({tournament, onRefresh, readOnly, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [setzliste, setSetzliste] = useState([]);

    let [filterPlayer, setFilterPlayer] = useState("");
    let [filterGroup, setFilterGroup] = useState("");

    let [selectedGroup, setSelectedGroup] = useState(undefined);

    let [anzahlGruppen, setAnzahlGruppen] = useState(1);

    useEffect(() => {
        let tmpSetzliste = [];
        if( tournament.tournamentSetzlisteEntities !== undefined && tournament.tournamentSetzlisteEntities !== null ) {
            for (const setzlisteeintrag of tournament.tournamentSetzlisteEntities) {
                tmpSetzliste.push({gruppe: setzlisteeintrag.gruppe, playerId: setzlisteeintrag.playerid, setzplatz: setzlisteeintrag.setzplatz});
            }
        }
        setSetzliste(tmpSetzliste);
    }, []);

    const getGroupAuswahl = () => {
        let groups = [];
        if( anzahlGruppen === null || anzahlGruppen === undefined || anzahlGruppen === "") {
            return groups;
        }
        for( let i = 1 ; i <= anzahlGruppen; i++ ) {
            groups.push({value:i + "", id: i});
        }
        return groups;
    }

    const getPlayers = () => {
        if( filterPlayer === "") {
            return tournament.tournamentPlayerEntities;
        }
        let players = [];
        for (const player of tournament.tournamentPlayerEntities) {
            if( player.name.toUpperCase().includes(filterPlayer.toUpperCase())) {
                players.push(player);
            }
        }
        return players;
    }

    const getPlayerRow = (player) => {
        let breitsGesetzt = setzliste.filter(tmpGroup => tmpGroup.playerId === player.id).length > 0;
        return <div key={"key_player_" + player.id} style={{display: "flex", textAlign: "left", alignItems: "center", marginBottom: 10}}>
            <IconButton disabled={breitsGesetzt || readOnly} color="primary" onClick={() => hinzufuegen(player.id)}>
                <DHIconAdd fontSize={30}/>
            </IconButton>
            <img src={player.bezahlt ? "/images/euro_green.png" : "/images/euro_black.png"}
                 style={{width: 40, height: 40, cursor: "pointer", marginRight: 10, marginTop: 5}}/>
            <img src={player.anwesend ? "/images/anwesend_color.png" : "/images/anwesend_black.png"}
                 style={{width: 30, height: 30, cursor: "pointer", marginRight: 10, marginTop: 5}}/>
            <span>{player.name}</span>
        </div>
    }

    const getGroessteGruppeInSetzliste = () => {
        let gruppe = 0;
        for (const setzlisteElement of setzliste) {
            if (setzlisteElement.gruppe > gruppe) {
                gruppe = setzlisteElement.gruppe;
            }
        }
        return gruppe;
    }

    const getGroesstePlatzInSetzliste = (tmpSetzliste) => {
        let platz = 0;
        for (const setzlisteElement of tmpSetzliste) {
            if( setzlisteElement.setzplatz > platz) {
                platz = setzlisteElement.setzplatz;
            }
        }
        return platz;
    }


    const getSetzlisteRows = () => {
        let rows = [];
        rows.push(<div key={"key_setzliste_header"} style={{paddingBottom: 10, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
            <div style={{width: 150}}>
            </div>
            <div style={{width: 60, textAlign: "center"}}>
                Gruppe
            </div>
            <div style={{width: 60, textAlign: "center"}}>
                Platz
            </div>
            <div>Spieler</div>
        </div>);

        for( let gruppe = 1; gruppe <= getGroessteGruppeInSetzliste() ; gruppe++) {
            if (filterGroup !== "" && gruppe !== Number(filterGroup)) {
                continue;
            }
            rows.push(<div key={"key_gruppe_" + gruppe} style={{marginBottom: 10, fontWeight:"bold"}}>
                Gruppe: {gruppe}
            </div>);
            let setzlisteGruppe = setzliste.filter(tmp => tmp.gruppe === gruppe);
            setzlisteGruppe.sort(function(a, b) {
                return a.setzplatz - b.setzplatz
            });
            let groessterPlatz = getGroesstePlatzInSetzliste(setzlisteGruppe);
            for (const tmpSetzliste of setzlisteGruppe) {
                let player = tournament.tournamentPlayerEntities.filter(player => player.id === tmpSetzliste.playerId)[0];
                if (!player) {
                    rows.push(<div style={{marginBottom: 10}}>
                        Fehler, Spieler nicht gefunden! Speichere erneut, dann wird dieser Eintrag entfernt.
                    </div>);
                } else {
                    rows.push(<div key={"key_player_setzliste_" + player.id} style={{paddingBottom: 10, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
                        <div style={{width: 150}}>
                            <IconButton disabled={readOnly} color="primary" onClick={() => entfernen(player.id)}>
                                <DHIconMinus fontSize={30}/>
                            </IconButton>
                            <IconButton disabled={readOnly || tmpSetzliste.setzplatz === 1} color="primary"
                            onClick={() => up(gruppe, tmpSetzliste.setzplatz)}>
                                <DHIconUp fontSize={30}/>
                            </IconButton>
                            <IconButton disabled={readOnly || tmpSetzliste.setzplatz === groessterPlatz} color="primary"
                             onClick={() => down(gruppe, tmpSetzliste.setzplatz)}>
                                <DHIconDown fontSize={30}/>
                            </IconButton>
                        </div>
                        <div style={{width: 60, textAlign: "center"}}>
                            {tmpSetzliste.gruppe}
                        </div>
                        <div style={{width: 60, textAlign: "center"}}>
                            {tmpSetzliste.setzplatz}
                        </div>
                        <div style={{display: "flex", textAlign: "left", alignItems: "center",}}>
                            <img src={player.bezahlt ? "/images/euro_green.png" : "/images/euro_black.png"}
                                 style={{width: 40, height: 40, cursor: "pointer", marginRight: 10, marginTop: 5}}/>
                            <img src={player.anwesend ? "/images/anwesend_color.png" : "/images/anwesend_black.png"}
                                 style={{width: 30, height: 30, cursor: "pointer", marginRight: 10, marginTop: 5}}/>
                            {player.name}
                        </div>
                    </div>);
                }
            }
        }
        return rows;
    }

    const up = (group, platz) => {
        let newPlatz = platz - 1;
        let platzZumVeraendern = setzliste.filter(tmp => tmp.gruppe === group && tmp.setzplatz === newPlatz)[0];
        let playerIdZumVeraendern = platzZumVeraendern.playerId;
        let aktuellerPlatz = setzliste.filter(tmp => tmp.gruppe === group && tmp.setzplatz === platz)[0];
        let playerIdAktuellerPlatz = aktuellerPlatz.playerId;

        platzZumVeraendern = {...platzZumVeraendern, playerId: playerIdAktuellerPlatz};
        aktuellerPlatz = {...aktuellerPlatz, playerId: playerIdZumVeraendern};

        let newSetzliste = [];
        newSetzliste.push(platzZumVeraendern);
        newSetzliste.push(aktuellerPlatz);
        for (const setzlisteElement of setzliste) {
            if( setzlisteElement.gruppe !== group ) {
                newSetzliste.push(setzlisteElement);
            } else if( setzlisteElement.setzplatz !== newPlatz && setzlisteElement.setzplatz !== platz) {
                newSetzliste.push(setzlisteElement);
            }
        }
        setSetzliste(newSetzliste);
    }

    const down = (group, platz) => {
        let newPlatz = platz + 1;
        let platzZumVeraendern = setzliste.filter(tmp => tmp.gruppe === group && tmp.setzplatz === newPlatz)[0];
        let playerIdZumVeraendern = platzZumVeraendern.playerId;
        let aktuellerPlatz = setzliste.filter(tmp => tmp.gruppe === group && tmp.setzplatz === platz)[0];
        let playerIdAktuellerPlatz = aktuellerPlatz.playerId;

        platzZumVeraendern = {...platzZumVeraendern, playerId: playerIdAktuellerPlatz};
        aktuellerPlatz = {...aktuellerPlatz, playerId: playerIdZumVeraendern};

        let newSetzliste = [];
        newSetzliste.push(platzZumVeraendern);
        newSetzliste.push(aktuellerPlatz);
        for (const setzlisteElement of setzliste) {
            if( setzlisteElement.gruppe !== group ) {
                newSetzliste.push(setzlisteElement);
            } else if( setzlisteElement.setzplatz !== newPlatz && setzlisteElement.setzplatz !== platz) {
                newSetzliste.push(setzlisteElement);
            }
        }
        setSetzliste(newSetzliste);
    }

    const entfernen = (playerId) => {
        let neueEinteilung = setzliste.filter(tmpGroup => tmpGroup.playerId !== playerId);
        setSetzliste(neueEinteilung);
    }

    const hinzufuegen = (playerId) => {
        setErrorMessage(undefined);
        if( selectedGroup === undefined) {
            setErrorMessage("Gruppe muss gewählt sein.");
            return;
        }
        let setzlisteGruppe = setzliste.filter(tmp => tmp.gruppe === selectedGroup);
        let groessterPlatz = getGroesstePlatzInSetzliste(setzlisteGruppe);
        let neueEinteilung = setzliste.slice();
        neueEinteilung.push({gruppe: selectedGroup, playerId: playerId, setzplatz: groessterPlatz+1});
        setSetzliste(neueEinteilung);
    }

    const save = () => {
        let tmpSetzliste = [];
        for (const tmp of setzliste) {
            tmpSetzliste.push({gruppe: tmp.gruppe, playerid: tmp.playerId, setzplatz: tmp.setzplatz});
        }
        let data = {tournamentId: tournament.id, tournametSetzlisteEinzelnDtoList: tmpSetzliste};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/savesetzliste",
            data,
            json => {
                setInfoMessage("Speichern war erfolgreich!")
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );
    }

    const getDialogFooter = () => {
        return <div>
            <DHButtonSave label="Speichern" disabled={readOnly} working={false} onClick={() => save()}/>
        </div>
    };

    return <DHDialog title="Setzliste bearbeiten" visible={true} buttons={getDialogFooter()}
                onClose={() => {
                    onRefresh();
                    onClose();
                }}  showCloseButton={true} comp={<>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
            <Fieldset legend="Hinweise..." style={{marginBottom: 20}} toggleable={true}>
                <div style={{color:"red", marginBottom:10}}>- Sobald die Gruppeneinteilung von Hand ausgewählt hast, macht die Setzliste keinen Sinn.</div>
                <div style={{color:"red", marginBottom:10}}>- Bitte achte selbst darauf, dass du nicht zuviele Spieler in einer Gruppe setzt und damit evtl. die Gruppengrößen überschreitest.</div>
                <div>- Bei Gruppenturnieren werden die gewünschten Teilnehmer direkt einer Gruppe zugewiesen.</div>
                <div>- Bitte wähle die Anzahl der Gruppen die du maximal im Turnier hast.</div>
                <div>- Danach wählst du die Gruppe in der der Spieler soll und fügst diese mit + der Gruppe hinzu.</div>
            </Fieldset>
            <div style={{marginBottom: 20}}>
                <DHNumberTextField id="id-anzahl-gruppen" value={anzahlGruppen} onChange={(text) => setAnzahlGruppen(text)}
                                   label="Anzahl Gruppen" min={1} required={true} width={300}/>
            </div>
            <div style={{marginBottom: 20}}>
                <DHSelect id="id-gruppen" value={selectedGroup} width={300} label="Gruppe" onChange={(id) => setSelectedGroup(id)}
                          model={getGroupAuswahl()}/>
            </div>
            <div className="grid">
                <div className="col">
                    <Panel header="Spielerübersicht">
                        <div style={{marginLeft:10, marginBottom:10}}>
                            <DHTextFieldSearch id="id-search-player" value={filterPlayer} onChange={(text) => setFilterPlayer(text)} label="Filter" width="100%"/>
                        </div>
                        <hr style={{marginBottom:10}}/>
                        {getPlayers().map(player => getPlayerRow(player))}
                    </Panel>
                </div>
                <div className="col">
                    <Panel header="Gruppeneinteilung">
                        <div style={{marginLeft: 10, marginBottom: 10}}>
                            <DHTextFieldSearch id="id-search-gruppe" value={filterGroup} onChange={(text) => setFilterGroup(text)} label="Filter Gruppe" width="100%"/>
                        </div>
                        <hr/>
                        {getSetzlisteRows()}
                    </Panel>
                </div>
            </div>
    </>}/>
}

