import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import * as React from "react";
import {useRef, useState} from "react";
import {PrimeIcons} from "primereact/api";
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import KODKOErgebnisEintragenDialog from "./KODKOErgebnisEintragenDialog";
import BoardSelectionDialog from "../veranstalter/BoardSelectionDialog";
import StatistikGameDialog from "../public/tournaments/StatistikGameDialog";
import ChangeModusOrBoardKODialog from "./ChangeModusOrBoardKODialog";


export default function KoDKOGameListe({tournamentId, tournamentGroup, scoringWithDarthelfer, finaleMitSatzausgleich,
                                           eventOperatorId, online, tournamentBeschreibungen, models,
                                           readonly, boardGames, liveGames, ergebnisse,
                                           boardReihenfolge, tournamentPlayerEntities, onChangeView, onRefresh}) {
    const CELL_PADDING_VALUE = 8;

    const filterOP = useRef(null);
    const [selectedModelPrefix, setSelectedModelPrefix] = useState(undefined);
    const [selectedModelPrefixBezeichnung, setSelectedModelPrefixBezeichnung] = useState(undefined);

    const [showGameDetailDialog, setShowGameDetailDialog] = useState(false);
    const [selectedTournamentGameId, setSelectedTournamentGameId] = useState(undefined);
    const [showFreilose, setShowFreilose] = useState(true);
    const [showMitErgebnis, setShowMitErgebnis] = useState(true);
    const [showNurMitBoards, setShowNurMitBoards] = useState(false);
    const [selectedErgebnisEdit, setSelectedErgebnisEdit] = useState(undefined);
    const [selectedErgebnisBoardVergabe, setSelectedErgebnisBoardVergabe] = useState(undefined);

    const getSpielerName = (id) => {
        if (id === undefined) {
            return "";
        }

        for (let i = 0; i < tournamentPlayerEntities.length; i++) {
            let spieler = tournamentPlayerEntities[i];
            if (spieler.id === id || spieler.userId === id || spieler.doppelpartnerId === id) {
                return spieler.name;
            }
        }
        return "Freilos";
    }

    const getBoardReihenfolgeByPrefix = (prefix) => {
        let rows = [];
        for (const [key, value] of Object.entries(boardReihenfolge)) {
             if (key.startsWith(prefix)) {
                 rows.push({feld: key, platz: value});
             }
        }
        return rows;
    }

    const generateHeaderRow = (modelFeldPrefix, bezeichnung) => {
        let style = {color: "white", background:"#1a67b6", fontSize: 18, textAlign:"left", padding:CELL_PADDING_VALUE};
        let gamesByBoardReihenfolge = getBoardReihenfolgeByPrefix(modelFeldPrefix);
        let rows = getTableRows(gamesByBoardReihenfolge);

        if( rows.length === 0) {
            return "";
        }
        let returnRows = [];
        returnRows.push( <TableRow key={modelFeldPrefix}>
            <TableCell colSpan={readonly ? 6 : 5} style={style}>{bezeichnung}</TableCell>
            {!readonly &&
                <TableCell style={{...style, textAlign:"center", alignContent:"center"}}>
                    <Button icon="pi pi-pencil" tooltip="Bearbeiten" style={{marginRight: 5}}
                            onClick={() => {
                                setSelectedModelPrefix(modelFeldPrefix);
                                setSelectedModelPrefixBezeichnung(bezeichnung);
                            }}/>
                </TableCell>
            }
        </TableRow>);
        returnRows = returnRows.concat(rows);
        return returnRows;
    }

    const getBoardSchreiberArea = (ergebnis) => {
        if( ergebnis === undefined || ergebnis === null ) {
            return "-";
        }
        let values = [];
        if( ergebnis !== undefined && getBoardGame(ergebnis.id) !== undefined) {
            values.push(<span key={"key_ergebnis_board_" + ergebnis.id} style={{whiteSpace:"nowrap", border: "1px solid #022836", color:"white", borderRadius:10,  backgroundColor:"#67adf5", padding:10}}>{getBoardGame(ergebnis.id).boardBezeichnung}</span>);
            if( getBoardGame(ergebnis.id).schreiberId !== undefined && getBoardGame(ergebnis.id).schreiberId !== null) {
                values.push(<span key={"key_ergebnis_schreiber_" + ergebnis.id} style={{whiteSpace:"nowrap", border: "1px solid #022836", color:"white", borderRadius:10,  backgroundColor:"#004591", padding:10, marginLeft:5}}>Schreiber: {getBoardGame(ergebnis.id).schreiberName}</span>);
            }
        } else {
            values.push(<span key={"key_ergebnis_board_aus_ergebnis_" + ergebnis.id} style={{whiteSpace:"nowrap"}}>{ergebnis.boardName}</span>);
            if( ergebnis.schreiberName !== undefined && ergebnis.schreiberName !== null && ergebnis.schreiberName !== "") {
                values.push(<span key={"key_ergebnis_schreiber_aus_ergebnis_" + ergebnis.id} style={{whiteSpace:"nowrap"}}> / Schreiber: {ergebnis.schreiberName}</span>);
            }
        }

        return values
    }

    const getTableRows = (gamesByBoardReihenfolge) => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18};
        gamesByBoardReihenfolge.forEach(tmp => {

            let model = getModel(tmp.feld);
            let myErgebnisArray = ergebnisse.filter(ergebnis => ergebnis.gruppe === tournamentGroup && ergebnis.feld === tmp.feld);
            let myErgebnis = undefined;
            if( myErgebnisArray.length === 1) {
                myErgebnis = myErgebnisArray[0];
            }
            let existiertSpieler1 = myErgebnis !== undefined && myErgebnis.spieler1Id !== null && myErgebnis.spieler1Id !== undefined;
            let existiertSpieler2 = myErgebnis !== undefined && myErgebnis.spieler2Id !== null && myErgebnis.spieler2Id !== undefined;
            let existiertErgebnis = myErgebnis !== undefined && myErgebnis.anzahlSpieler1 !== null && myErgebnis.anzahlSpieler2 !== null;
            let spieler1Gewonnen = existiertErgebnis && myErgebnis !== undefined && myErgebnis.anzahlSpieler1 > myErgebnis.anzahlSpieler2;
            let spieler2Gewonnen = existiertErgebnis && myErgebnis !== undefined && myErgebnis.anzahlSpieler2 > myErgebnis.anzahlSpieler1;
            let freilosVorhanden = existiertErgebnis && myErgebnis !== undefined && (myErgebnis.spieler1Id === "freilos" || myErgebnis.spieler2Id === "freilos");

            let anzahlSpieler1Finale2 = undefined;
            let anzahlSpieler2Finale2 = undefined;
            let existiertErgebnisFinale2 = false;
            let ergebnisFinale2;
            if( model.tournamentModus === "finale") {
                ergebnisFinale2 = ergebnisse.filter(ergebnis => ergebnis.feld === "finale2")[0];
                if( ergebnisFinale2 !== undefined && ergebnisFinale2.anzahlSpieler1 !== null)  {
                    existiertErgebnisFinale2 = true;
                    anzahlSpieler1Finale2 = ergebnisFinale2.anzahlSpieler1;
                    anzahlSpieler2Finale2 = ergebnisFinale2.anzahlSpieler2;
                    spieler1Gewonnen = anzahlSpieler1Finale2 > anzahlSpieler2Finale2;
                    spieler2Gewonnen = anzahlSpieler2Finale2 > anzahlSpieler1Finale2;
                }
            }

            if( (showNurMitBoards && myErgebnis === undefined) || (showNurMitBoards && myErgebnis !== undefined && getBoardGame(myErgebnis.id) === undefined)) {
                return;
            }
            if( !showFreilose && freilosVorhanden) {
                return;
            }
            if( !showMitErgebnis && existiertErgebnis && !freilosVorhanden) {
                return;
            }

            let boardGame = myErgebnis !== undefined ? getBoardGame(myErgebnis.id) : undefined;
            let liveGame = getLiveGame(boardGame);

            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={"key_" + tmp.feld}>
                <TableCell style={{...style, textAlign:"center", padding:CELL_PADDING_VALUE}}>{tmp.platz}</TableCell>
                <TableCell style={{...style, textAlign:"right", padding:CELL_PADDING_VALUE, color: spieler1Gewonnen && "green", fontWeight: spieler1Gewonnen && "bolder"}}>{existiertSpieler1 ? getSpielerName(myErgebnis.spieler1Id) : "-"}</TableCell>
                <TableCell style={{...style, textAlign: "center", padding: CELL_PADDING_VALUE, cursor: existiertErgebnis && !freilosVorhanden && scoringWithDarthelfer ? "pointer" : ""}}>
                    <div onClick={() => {
                        if (existiertErgebnis && !freilosVorhanden && scoringWithDarthelfer) {
                            setShowGameDetailDialog(true);
                            setSelectedTournamentGameId(myErgebnis.id);
                        }
                    }}>
                        {existiertErgebnis ?
                            myErgebnis.anzahlSpieler1 + " : " + myErgebnis.anzahlSpieler2 : getLiveGameErgebnis(liveGame)}
                    </div>
                    {existiertErgebnisFinale2 &&
                        <div onClick={() => {
                            if (scoringWithDarthelfer) {
                                setShowGameDetailDialog(true);
                                setSelectedTournamentGameId(ergebnisFinale2.id);
                            }
                        }}>
                            {anzahlSpieler1Finale2 + " : " + anzahlSpieler2Finale2}
                        </div>
                    }
                </TableCell>
                <TableCell style={{
                    ...style,
                    textAlign: "left",
                    padding: CELL_PADDING_VALUE,
                    color: spieler2Gewonnen && "green",
                    fontWeight: spieler2Gewonnen && "bolder"
                }}>{existiertSpieler2 ? getSpielerName(myErgebnis.spieler2Id) : "-"}</TableCell>
                <TableCell style={{...style, textAlign: "left", padding: CELL_PADDING_VALUE}}>
                    {getBoardSchreiberArea(myErgebnis)}
                </TableCell>
                <TableCell style={{...style, textAlign:"center", padding:CELL_PADDING_VALUE}}>
                    {(eventOperatorId !== null && eventOperatorId !== undefined &&!readonly && !online && !existiertErgebnis && !freilosVorhanden) &&
                        <img src="/images/kneipe.png" alt="Board vergeben" title="Board vergeben"
                             style={{cursor: "pointer", width: 30, marginLeft:10, marginRight:10}}
                             onClick={() => {
                                 setSelectedErgebnisBoardVergabe(myErgebnis);
                             }}/>
                    }
                    {!readonly && !freilosVorhanden &&
                        <Button icon="pi pi-pencil"
                                onClick={() => setSelectedErgebnisEdit(myErgebnis)}
                            style={{height: 40, width: 40, margin:5}}/>
                    }
                </TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    const getBoardGame = (id) => {
        if( boardGames === null || boardGames === undefined) {
            return undefined;
        }
        for (const boardGame of boardGames) {
            if( boardGame.tournamentId === tournamentId && boardGame.tournamentGameId === id) {
                return boardGame;
            }
        }
        return undefined;
    }

    const getFilterButton = (checked, onClick) => {
        return <Button icon={checked && "pi pi-check"} style={{marginRight:10, backgroundColor: checked ? "#009f5a" : ""}} onClick={() => onClick()}/>
    }

    const getModel = (feld) => {
        for (const model of models) {
            if( model.feld === feld) {
                return model;
            }
        }
        return undefined;
    }

    const getSchreiberNameFromErgebnisse = (id) => {
        // da im selectedErgebnisBoardVergabe die Daten vor dem Laden und somit vor dem evtl. Change des Schreibers stehen,
        // muss das Ergebnis neu ermittelt werden.
        return ergebnisse.filter(ergebnis => ergebnis.id === id)[0].schreiberName;
    }

    const getLiveGame = (boardGame) => {
        if( liveGames === null || liveGames === undefined || boardGame === null || boardGame === undefined) {
            return undefined;
        }
        let gameId = boardGame.gameId;
        let tmpGames = liveGames.find(game => game.gameId === gameId);
        return tmpGames
    }

    const getLiveGameErgebnis = (liveGame) => {
        if( liveGame === undefined) {
            return "-";
        }
        if( liveGame.startssoon) {
            return <img src="/images/dot_red.png" style={{width:20}}/>
        }
        if( liveGame.inGame) {
            return <img src="/images/dot_green.png" style={{width:20}}/>
        }
        if( liveGame.sets > 1 ) {
            return <span style={{opacity:0.5}}>{liveGame.spieler1Sets} : {liveGame.spieler2Sets}</span>
        } else {
            return <span style={{opacity:0.5}}>{liveGame.spieler1Legs} : {liveGame.spieler2Legs}</span>
        }
    }

    return (
        <div style={{backgroundColor:"#022836"}}>
            {showGameDetailDialog &&
                <StatistikGameDialog tournamentGameId={selectedTournamentGameId} tournamentId={tournamentId} onClose={() => {
                    setShowGameDetailDialog(false);
                    setSelectedTournamentGameId(undefined)
                }}/>
            }
            {selectedErgebnisBoardVergabe !== undefined &&
                <BoardSelectionDialog onClose={() => {
                    setSelectedErgebnisBoardVergabe(undefined);
                }}
                                      eventOperatorId={eventOperatorId}
                                      spieler1Name={getSpielerName(selectedErgebnisBoardVergabe.spieler1Id)}
                                      spieler2Name={getSpielerName(selectedErgebnisBoardVergabe.spieler2Id)}
                                      schreiberName={getSchreiberNameFromErgebnisse(selectedErgebnisBoardVergabe.id)}
                                      showSchreiberArea={true}
                                      players={tournamentPlayerEntities}
                                      callBackLoadData={onRefresh}
                                      tournamentId={tournamentId}
                                      tournamentGameId={selectedErgebnisBoardVergabe.id}/>}
            <OverlayPanel ref={filterOP}
                          showCloseIcon={false} dismissable={true}
                          style={{height: 140, width: 300}}>
                <div style={{marginBottom:10}}>
                    {getFilterButton(showFreilose, () => setShowFreilose(!showFreilose) )} zeige Spiele mit Freilose an
                </div>
                <div style={{marginBottom:10}}>
                    {getFilterButton(showMitErgebnis, () => setShowMitErgebnis(!showMitErgebnis) )} zeige Spiele mit Ergebnis an
                </div>
                <div style={{marginBottom:10}}>
                    {getFilterButton(showNurMitBoards, () => setShowNurMitBoards(!showNurMitBoards) )} zeige Spiele nur mit Boards
                </div>
            </OverlayPanel>
            <div style={{textAlign:"left", padding:10}}>
                <Button icon={PrimeIcons.SHARE_ALT}
                        onClick={() => onChangeView()}
                        style={{marginRight: 10}}/>
                <Button icon={PrimeIcons.FILTER}
                        onClick={(e) => filterOP.current.toggle(e)}
                        style={{marginRight: 10}}/>
            </div>
            {selectedModelPrefix !== undefined && <ChangeModusOrBoardKODialog tournamentId={tournamentId}
                                                                              showBoardVergabe={true}
                                                                              eventOperatorId={eventOperatorId}
                                                                              prefix={selectedModelPrefix}
                                                                              bezeichnung={selectedModelPrefixBezeichnung} onClose={() => {
                setSelectedModelPrefix(undefined)
                setSelectedModelPrefixBezeichnung(undefined)
            }} /> }
            {!readonly && selectedErgebnisEdit !== undefined && <KODKOErgebnisEintragenDialog spieler1Id={selectedErgebnisEdit.spieler1Id} spieler2Id={selectedErgebnisEdit.spieler2Id}
                                                                                 spielername1={getSpielerName(selectedErgebnisEdit.spieler1Id)}
                                                                                 spielername2={getSpielerName(selectedErgebnisEdit.spieler2Id)}
                                                                                 models={models}
                                                                                 model={getModel(selectedErgebnisEdit.feld)}
                                                                                 feld={selectedErgebnisEdit.feld}
                                                                                 gruppe={tournamentGroup}
                                                                                 tournamentId={tournamentId}
                                                                                 tournamentGameId={selectedErgebnisEdit.id}
                                                                                 finaleMitSatzausgleich={finaleMitSatzausgleich}
                                                                                 ergebnisse={ergebnisse}
                                                                                 readonly={readonly}
                                                                                 onRefresh={onRefresh}
                                                                                 onClose={() => setSelectedErgebnisEdit(undefined)} />}
            {(tournamentBeschreibungen === null || tournamentBeschreibungen === undefined || tournamentBeschreibungen.length === 0) ?
                <div style={{backgroundColor: "#022836", color: "white", fontSize: 20}}>Dieser Bereich kann nicht angezeigt werden.</div>
                :
                <TableContainer style={{maxHeight: "100%", paddingBottom: 0}}>
                    <Table stickyHeader style={{backgroundColor: "#022836", color: "white", fontSize: 20}}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{
                                    backgroundColor: "#ac1c27",
                                    color: "white",
                                    fontSize: 20,
                                    width: 100,
                                    textAlign: "center", padding:CELL_PADDING_VALUE
                                }}>Spielnr.</TableCell>
                                <TableCell style={{
                                    backgroundColor: "#ac1c27",
                                    color: "white",
                                    fontSize: 20,
                                    textAlign: "right",
                                    whiteSpace: "nowrap", padding:CELL_PADDING_VALUE
                                }}>Spieler 1</TableCell>
                                <TableCell style={{
                                    backgroundColor: "#ac1c27",
                                    color: "white",
                                    fontSize: 20,
                                    width: 100,
                                    textAlign: "left", padding:CELL_PADDING_VALUE
                                }}>Ergebnis</TableCell>
                                <TableCell style={{
                                    backgroundColor: "#ac1c27",
                                    color: "white",
                                    fontSize: 20,
                                    textAlign: "left",
                                    whiteSpace: "nowrap", padding:CELL_PADDING_VALUE
                                }}>Spieler 2</TableCell>
                                <TableCell style={{
                                    backgroundColor: "#ac1c27",
                                    color: "white",
                                    fontSize: 20,
                                    textAlign: "center", padding:CELL_PADDING_VALUE
                                }}>Board/Schreiber</TableCell>
                                <TableCell style={{
                                    backgroundColor: "#ac1c27",
                                    color: "white",
                                    fontSize: 20,
                                    width: 50,
                                    textAlign: "center", padding:CELL_PADDING_VALUE
                                }}>#</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                tournamentBeschreibungen.map(tournamentBeschreibung => generateHeaderRow(tournamentBeschreibung.modelFeldPrefix, tournamentBeschreibung.bezeichnung))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
    );
}
