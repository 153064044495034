import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export default function DHSelect({ id, value, model, onChange, label, width}) {

    const [v, setV] = useState("");

    useEffect(() => {
        setV(value);
    }, [value]);

    const getMenuItems = () => {
        let rows = [];
        rows.push(<MenuItem value={undefined}>
            <em style={{height:20}}></em>
        </MenuItem>);
        for (const modelElement of model) {
            rows.push(<MenuItem key={"key_select_" + modelElement.id} value={modelElement.id}>{modelElement.value}</MenuItem>)
        }
        return rows;
    }

    return <FormControl variant="filled" sx={{ minWidth: width }}>
        <InputLabel id={id + "label"}>{label}</InputLabel>
        <Select
            labelId={id + "label"}
            id={id}
            value={v}
            onChange={(e) => onChange(e.target.value)}>
            {getMenuItems()}
        </Select>
    </FormControl>
}