import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import Turniergame from "../../tournament/Turniergame";


export default function PublicTournamentGroupRefreshComp({tournament, gruppenTabellen, boardGames, liveGames, onChangeView}) {

    let [showMenu, setShowMenu] = useState(false);
    let [startCounter, setStartcounter] = useState(9);
    let [counter, setCounter] = useState(9);
    let [rotation, setRotation] = useState(false);
    let [selectedPlayerId, setSelectedPlayerId] = useState(undefined);
    let [selectedGroup, setSelectedGroup] = useState(1);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if( counter <=  0) {
                setCounter(startCounter);
                if( rotation ) {
                    if( selectedGroup === tournament.anzahlGruppen) {
                        setSelectedGroup(1);
                    } else {
                        setSelectedGroup(selectedGroup+1);
                    }
                }
                return;
            }
            setCounter(counter-1);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [counter, tournament]);

    const isGruppenphaseSetModus = () => {
        let modusGruppenphase = tournament.tournamentModusEntities.find(element => element.modusTyp === "gruppenturnier");
        return modusGruppenphase.sets > 1;
    }

    const getGroupEntites = () => {
        let gruppe = [];
        for (let i = 0; i < tournament.tournamentGroupGameEntities.length; i++) {
            let gruppenGameentity = tournament.tournamentGroupGameEntities[i];
            if (gruppenGameentity.gruppe === selectedGroup) {
                gruppe.push(gruppenGameentity);
            }
        }
        return gruppe;
    }

    const getAnzahlSpieltage = (gruppenEintraege) => {
        let returnValue = 0;
        for (const gruppenEintraegeElement of gruppenEintraege) {
            if (gruppenEintraegeElement.spielrunde >= returnValue) {
                returnValue = gruppenEintraegeElement.spielrunde;
            }
        }
        return returnValue;
    }

    const getGames = () => {
        let gruppenEintraege = getGroupEntites();

        let spieltage = [];
        for (let i = 1; i <= getAnzahlSpieltage(gruppenEintraege); i++) {
            let games = [];

            for (const gruppenEintraegeElement of gruppenEintraege) {
                if( gruppenEintraegeElement.spielrunde !== i) {
                    continue;
                }
                games.push(<Turniergame tournament={tournament} tournamentGroup={gruppenEintraegeElement.gruppe} userId={undefined}
                                        gruppennummer={selectedGroup}
                                        game={gruppenEintraegeElement}
                                        readOnly={true}
                                        callBackRefresh={() => ""}
                                        callbackGetSpielerName={getSpielerName}
                                        selectedPlayerId={selectedPlayerId}
                                        boardGames={boardGames}
                                        liveGames={liveGames}
                                        onPlayerSelect={(playerId) => setSelectedPlayerId(playerId)}/>);
            }
            spieltage.push(<div style={{
                width: 400,
                backgroundColor: "white",
                color: "black",
                textAlign: "left",
                marginRight: 10,
                marginBottom: 10,
                boxShadow: "0 2px 15px #888888"
            }}>
                {games}
            </div>)
        }

        return spieltage;
    }

    const getGroupButtons = () => {
        let gruppen = [];
        for (let i = 0; i < tournament.anzahlGruppen; i++) {
            let gruppennummer = i + 1;
            gruppen.push(<Button label={"Gruppe " + gruppennummer}
                                 className={gruppennummer === selectedGroup ? "p-button-primary" : "p-button-secondary"}
                                 style={{marginRight: 5, marginTop:5}}
                                 onClick={() => setSelectedGroup(gruppennummer)}/>);
        }
        return gruppen;
    }

    const getTabelle = () => {
        if (gruppenTabellen === null || gruppenTabellen === undefined) {
            return undefined;
        }
        for (const gruppenTabelle of gruppenTabellen) {
            if (gruppenTabelle.gruppe === selectedGroup) {
                return gruppenTabelle;
            }
        }
        return undefined;
    }

    const getSpielerName = (id) => {
        if (id === undefined) {
            return "";
        }

        for (let i = 0; i < tournament.tournamentPlayerEntities.length; i++) {
            let spieler = tournament.tournamentPlayerEntities[i];
            if (spieler.id === id) {
                let spielername = spieler.name;
                if (spieler.inaktiv) {
                    return <s>{spielername}</s>;
                } else {
                    return spielername;
                }
            }
        }
    }

    const existiertRangliste = () => {
        let tabelle = getTabelle();
        if (tabelle === null || tabelle === undefined || tabelle.rangliste === undefined || tabelle.rangliste === null || tabelle.rangliste.length === 0) {
            return false;
        }
        return true;
    }

    const getTableArea = () => {
        let tabelle = getTabelle();
        if (!existiertRangliste()) {
            return "";
        }
        let rows = [];
        let position = 1;
        rows.push(<div key={"table_header_1_group_" + selectedGroup} className="grid"
                       style={{paddingLeft: 10, paddingRight: 10, paddingTop: 0}}>
            <div className="col">
            </div>
            <div className="col-fixed"
                 style={{width: 50, textAlign: "center", backgroundColor: "#E6946C", fontSize: 12}}>Punkte
            </div>
            <div className="col-fixed"
                 style={{width: 75, textAlign: "center", backgroundColor: "#E0AF97", fontSize: 12}}>Spiele
            </div>
            <div className="col-fixed" style={{
                width: 50,
                textAlign: "center",
                backgroundColor: "#E6946C",
                fontSize: 12
            }}>{isGruppenphaseSetModus() ? "Sets" : "Legs"}</div>
        </div>);
        rows.push(<div key={"table_header_2_group_" + selectedGroup} className="grid"
                       style={{paddingLeft: 10, paddingRight: 10}}>
            <div className="col-fixed" style={{width: 20}}></div>
            <div className="col"></div>
            <div className="col-fixed" style={{width: 50, backgroundColor: "#E6946C"}}></div>
            <div className="col-fixed" style={{width: 25, backgroundColor: "#E0AF97"}}>S</div>
            <div className="col-fixed" style={{width: 25, backgroundColor: "#E0AF97"}}>U</div>
            <div className="col-fixed" style={{width: 25, backgroundColor: "#E0AF97"}}>V</div>
            <div className="col-fixed" style={{width: 25, backgroundColor: "#E6946C"}}>G</div>
            <div className="col-fixed" style={{width: 25, backgroundColor: "#E6946C"}}>V</div>
        </div>);
        let raengeGold = [];
        let raengeSilber = [];
        let raengeBronze = [];
        if( tournament.anzahlSpielerGold ) {
            for (let i = 1; i <= tournament.anzahlSpielerGold; i++) {
                raengeGold.push(i);
            }
        }
        if( tournament.anzahlSpielerGold && tournament.anzahlSpielerSilber ) {
            let maxRang = tournament.anzahlSpielerGold + tournament.anzahlSpielerSilber;
            let minRang = tournament.anzahlSpielerGold + 1;
            for (let i = minRang; i <= maxRang; i++) {
                raengeSilber.push(i);
            }
        }
        if( tournament.anzahlSpielerGold && tournament.anzahlSpielerSilber && tournament.anzahlSpielerBronze ) {
            let maxRang = tournament.anzahlSpielerGold + tournament.anzahlSpielerSilber + tournament.anzahlSpielerBronze;
            let minRang = tournament.anzahlSpielerGold + tournament.anzahlSpielerSilber + 1;
            for (let i = minRang; i <= maxRang; i++) {
                raengeBronze.push(i);
            }
        }
        let rangliste = tabelle.rangliste;
        for (let i = 0; i < rangliste.length; i++) {
            let ranglisteEinzel = rangliste[i];
            let styleRow = {};
            if( raengeGold.includes(i+1)) {
                styleRow = {backgroundColor: "#82e3d9"};
            }
            if( raengeSilber.includes(i+1)) {
                styleRow = {backgroundColor: "#79d3ca"};
            }
            if( raengeBronze.includes(i+1)) {
                styleRow = {backgroundColor: "#6cbdb5"};
            }
            if (selectedPlayerId !== undefined && selectedPlayerId === ranglisteEinzel.playerid) {
                styleRow = {...styleRow, backgroundColor: "#FD8943"};
            }
            rows.push(<div key={"table_header_group_" + selectedGroup + "_playerid_" + ranglisteEinzel.playerid}
                           className="grid" style={{paddingLeft: 10, paddingRight: 10, paddingTop: 5}}>
                <div className="col-fixed" style={{...styleRow, width: 20}}>{position}.</div>
                <div className="col" style={styleRow}>{getSpielerName(ranglisteEinzel.playerid)}</div>
                <div className="col-fixed"
                     style={{width: 50, backgroundColor: "#E6946C"}}>{ranglisteEinzel.punkte}</div>
                <div className="col-fixed"
                     style={{width: 25, backgroundColor: "#E0AF97"}}>{ranglisteEinzel.gewonnenespiele}</div>
                <div className="col-fixed"
                     style={{width: 25, backgroundColor: "#E0AF97"}}>{ranglisteEinzel.unentschiedenspiele}</div>
                <div className="col-fixed"
                     style={{width: 25, backgroundColor: "#E0AF97"}}>{ranglisteEinzel.verlorenespiele}</div>
                <div className="col-fixed"
                     style={{width: 25, backgroundColor: "#E6946C"}}>{ranglisteEinzel.anzahlLegsGewonnen}</div>
                <div className="col-fixed"
                     style={{width: 25, backgroundColor: "#E6946C"}}>{ranglisteEinzel.anzahlLegsVerloren}</div>
            </div>);
            position++;
        }
        return rows;
    }

    return <div style={{
        color: "white",
        padding: 20,
        fontSize: 16,
        backgroundColor: "#022836",
        minHeight: "100vh",
        height: "100%"
    }}>
        <div className="grid" style={{paddingBottom: 10, marginLeft: 0, marginRight: 0, backgroundColor: "#022836"}}>
            <Button icon="pi pi-table"
                    onClick={() => onChangeView()}
                    style={{marginRight: 10, marginTop:5}}/>
            <Button icon={showMenu ? "pi pi-chevron-left" : "pi pi-chevron-right"}
                    className={showMenu ? "p-button-primary" : "p-button-secondary"}
                    onClick={() => setShowMenu(!showMenu)}
                    style={{marginRight: 10, marginTop:5}}/>
            {showMenu && <div>
                <Button icon={rotation ? "pi pi-eye" : "pi pi-eye-slash"}
                        label={rotation ? "( " + counter + " )" : ""}
                        className={rotation ? "p-button-primary" : "p-button-secondary"}
                        onClick={() => setRotation(!rotation)}
                        style={{marginRight: 10, marginTop:5}}/>
                {rotation && <Button icon="pi pi-chevron-up"
                                     label={startCounter}
                                     className="p-button-secondary"
                                     onClick={() => {
                                         setStartcounter(startCounter + 1);
                                         setCounter(startCounter + 1);
                                     }}
                                     style={{marginRight: 5, marginTop:5}}/>
                }
                {rotation && <Button icon="pi pi-chevron-down"
                                     label={startCounter}
                                     disabled={startCounter === 1}
                                     className="p-button-secondary"
                                     onClick={() => {
                                         setStartcounter(startCounter - 1);
                                         setCounter(startCounter - 1);
                                     }}
                                     style={{marginRight: 10, marginTop:5}}/>
                }
            </div>
            }
            {getGroupButtons()}
        </div>
        <div className="grid" style={{backgroundColor: "#022836", marginTop:10}}>
            {existiertRangliste() &&
                <div className="col-fixed" style={{width: 470, backgroundColor: "#022836", padding:0}}>
                    <div style={{
                        width: 450,
                        backgroundColor: "white",
                        color: "black",
                        textAlign: "left",
                        marginRight: 20,
                        marginTop: 5,
                        marginBottom: 10,
                    }}>
                        {getTableArea()}
                    </div>
                </div>
            }
            <div className="col" style={{padding:0}}>
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", backgroundColor: "#022836"}}>
                    {getGames()}
                </div>
            </div>
        </div>
    </div>
}
