import React from "react";
import {Button,} from "@mui/material";

import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';

export default function DHButtonSave({label, working, disabled, onClick}) {

    return <Button variant="contained" disabled={disabled || working} onClick={() => onClick()} startIcon={working ? <CircularProgress size={20}/> : <SaveIcon/>}
                   style={{textTransform: "none", letterSpacing:"0.1em"}}>{label !== undefined ? label : "Speichern"}</Button>
}