import React from 'react'
import ParticipantTable from "./ParticipantTable";
import DHTextFieldSearchPublicArea from "../../../general/DHTextFieldSearchPublicArea";


export default function PublicTournamentParticipantTab({tournamentId, participants, tournamentGroupEntities, maxAnzahlTeilnehmer, playersSortedByTime, isBezahlung, isParticipantsMailConfirmation, supportsWebPush, pushSpielerIds, updateSubscriptions}) {

    const [filterText, setFilterText] = React.useState("");

    const filterParticipants = (warteliste) => {
        let players = participants.filter(player => player.warteliste === warteliste);
        if( filterText === "") {
            return players;
        }
        let returnList = [];
        for (const player of players) {
            if( player.name.toUpperCase().includes(filterText.toUpperCase())) {
                returnList.push(player);
            }
        }
        return returnList;
    }

    const getWarteliste = () => {
        return playersSortedByTime.filter(player => player.warteliste);
    }

    return <div>
        <div style={{display: "flex", marginTop:20, marginLeft: 15, marginBottom: 10, alignItems:"center"}}>
            <div style={{color: "white", marginRight: 10, fontSize: 20}}>Anzahl der
                Teilnehmer: {participants.length} {getWarteliste().length > 0 && " - Warteliste: " + getWarteliste().length}
                {maxAnzahlTeilnehmer !== null && maxAnzahlTeilnehmer !== undefined && " - max. Anzahl: " + maxAnzahlTeilnehmer}
            </div>
        </div>
        <div style={{display: "flex", marginTop: 20, marginLeft: 15, marginBottom: 10, alignItems: "center"}}>
            <DHTextFieldSearchPublicArea id="id-name-filter" value={filterText} onChange={(text) => setFilterText(text)} width={100} label="Suche" variant="filled"/>
            {filterText !== "" &&
                <div style={{color: "white", marginLeft: 10, fontSize: 20}}>Gefiltert: {filterParticipants(false).length}</div>
            }
        </div>
        <ParticipantTable tournamentId={tournamentId} tournamentGroupEntities={tournamentGroupEntities} participants={filterParticipants(false)}
                          isBezahlung={isBezahlung} isParticipantsMailConfirmation={isParticipantsMailConfirmation}
                          supportsWebPush={supportsWebPush} pushSpielerIds={pushSpielerIds}  updateSubscriptions={updateSubscriptions}/>
        {getWarteliste().length > 0 &&
            <>
                <div style={{display: "flex", marginTop: 20, marginLeft: 15, marginBottom: 10}}>
                    <div style={{color: "white", marginRight: 10, fontSize: 20}}>Anzahl auf
                        Warteliste: {getWarteliste().length}</div>
                </div>
                <ParticipantTable tournamentId={tournamentId} participants={getWarteliste()}
                                  isBezahlung={isBezahlung} isParticipantsMailConfirmation={isParticipantsMailConfirmation}
                                  supportsWebPush={supportsWebPush} pushSpielerIds={pushSpielerIds} updateSubscriptions={updateSubscriptions}/>
            </>
        }
    </div>
}

