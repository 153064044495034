import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {PrimeIcons} from "primereact/api";
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import StatistikGameDialog from "../public/tournaments/StatistikGameDialog";


export default function GroupDKOAllGamesListe({tournamentWrapper, liveGames}) {

    const CELL_PADDING_VALUE = 8;

    const filterOP = useRef(null);

    const [showGameDetailDialog, setShowGameDetailDialog] = useState(false);
    const [selectedTournamentGameId, setSelectedTournamentGameId] = useState(undefined);
    const [showFreilose, setShowFreilose] = useState(true);
    const [showMitErgebnis, setShowMitErgebnis] = useState(true);
    const [showNurMitBoards, setShowNurMitBoards] = useState(false);

    useEffect(() => {
        for (const liveGame of liveGames) {
            console.log(getSpielerName(liveGame.spieler1Id),getSpielerName(liveGame.spieler2Id));
            console.log(tournamentWrapper.boardGames);
        }
    }, [liveGames, tournamentWrapper]);

    const getSpielerName = (id) => {
        if (id === undefined) {
            return "";
        }

        for (let i = 0; i < tournamentWrapper.turnierMain.tournamentPlayerEntities.length; i++) {
            let spieler = tournamentWrapper.turnierMain.tournamentPlayerEntities[i];
            if (spieler.id === id || spieler.userId === id || spieler.doppelpartnerId === id) {
                return spieler.name;
            }
        }
        return "Freilos";
    }

    const getBoardSchreiberArea = (ergebnis) => {
        if (ergebnis === undefined || ergebnis === null) {
            return "-";
        }
        let values = [];
        if (getBoardGame(ergebnis.id) !== undefined) {
            values.push(<span key={"key_ergebnis_board_" + ergebnis.id} style={{
                whiteSpace: "nowrap",
                border: "1px solid #022836",
                color: "white",
                borderRadius: 10,
                backgroundColor: "#67adf5",
                padding: 10
            }}>{getBoardGame(ergebnis.id).boardBezeichnung}</span>);
            if (getBoardGame(ergebnis.id).schreiberId !== undefined && getBoardGame(ergebnis.id).schreiberId !== null) {
                values.push(<span key={"key_ergebnis_schreiber_" + ergebnis.id} style={{
                    whiteSpace: "nowrap",
                    border: "1px solid #022836",
                    color: "white",
                    borderRadius: 10,
                    backgroundColor: "#004591",
                    padding: 10,
                    marginLeft: 5
                }}>Schreiber: {getBoardGame(ergebnis.id).schreiberName}</span>);
            }
        } else {
            values.push(<span key={"key_ergebnis_board_aus_ergebnis_" + ergebnis.id} style={{whiteSpace: "nowrap"}}>{ergebnis.boardName}</span>);
            if (ergebnis.schreiberName !== undefined && ergebnis.schreiberName !== null && ergebnis.schreiberName !== "") {
                values.push(<span key={"key_ergebnis_schreiber_aus_ergebnis_" + ergebnis.id} style={{whiteSpace: "nowrap"}}> / Schreiber: {ergebnis.schreiberName}</span>);
            }
        }

        return values
    }

    const getBoardGame = (id) => {
        if (tournamentWrapper.boardGames === null || tournamentWrapper.boardGames === undefined) {
            return undefined;
        }
        for (const boardGame of tournamentWrapper.boardGames) {
            if (boardGame.tournamentId === tournamentWrapper.turnierMain.id && boardGame.tournamentGameId === id) {
                return boardGame;
            }
        }
        return undefined;
    }

    const getFilterButton = (checked, onClick) => {
        return <Button icon={checked && "pi pi-check"} style={{marginRight: 10, backgroundColor: checked ? "#009f5a" : ""}} onClick={() => onClick()}/>
    }

    const getLiveGame = (boardGame) => {
        if (liveGames === null || liveGames === undefined || boardGame === null || boardGame === undefined) {
            return undefined;
        }
        let gameId = boardGame.gameId;
        let tmpGames = liveGames.find(game => game.gameId === gameId);
        return tmpGames
    }

    const getLiveGameErgebnis = (liveGame) => {
        if (liveGame === undefined) {
            return "-";
        }
        if (liveGame.startssoon) {
            return <img src="/images/dot_red.png" style={{width: 20}}/>
        }
        if (liveGame.inGame) {
            return <img src="/images/dot_green.png" style={{width: 20}}/>
        }
        if (liveGame.sets > 1) {
            return <span style={{opacity: 0.5}}>{liveGame.spieler1Sets} : {liveGame.spieler2Sets}</span>
        } else {
            return <span style={{opacity: 0.5}}>{liveGame.spieler1Legs} : {liveGame.spieler2Legs}</span>
        }
    }

    const getAnzahlGruppen = () => {
        let gruppe = 0;
        for (const tmp of tournamentWrapper.gruppeModelWrapperListe) {
            if( gruppe < tmp.gruppe) {
                gruppe = tmp.gruppe;
            }
        }
        return gruppe;
    }

    const getMaximaleSpielnummer = () => {
        let max = 0;
        for (const tmp of tournamentWrapper.gruppeModelWrapperListe) {
            for( const boardR in tmp.boardReihenfolge) {
                if( max < tmp.boardReihenfolge[boardR]) {
                    max = tmp.boardReihenfolge[boardR];
                }
            }
        }
        return max;
    }

    const getFeldZurGruppeUndSpielnummer = (gruppe, spielnummer) => {
        let modelWrapperZurGruppe = tournamentWrapper.gruppeModelWrapperListe.filter(tmp => tmp.gruppe === gruppe)[0];
        for( const boardR in modelWrapperZurGruppe.boardReihenfolge) {
            if( spielnummer === modelWrapperZurGruppe.boardReihenfolge[boardR]) {
                return boardR;
            }
        }
        return undefined;
    }

    const getErgebnisZurGruppeUndFeld = (gruppe, feld) => {
        return tournamentWrapper.turnierMain.tournamentErgebnisEntities.filter(tmp => tmp.gruppe === gruppe && tmp.feld === feld)[0];
    }

    const getRows = () => {
        let background = false;
        let styleWithBackground = {color: "#022836", background: "#eaf1f5", fontSize: 18};
        let styleWithoutBackground = {color: "#022836", background: "white", fontSize: 18};

        let returnRows = [];
        for( let spielnummer = 1; spielnummer <= getMaximaleSpielnummer(); spielnummer++ ) {
            for( let gruppe = 1;  gruppe <= getAnzahlGruppen(); gruppe++) {
                let feld = getFeldZurGruppeUndSpielnummer(gruppe, spielnummer);
                let ergebnis = getErgebnisZurGruppeUndFeld(gruppe, feld);
                let existiertSpieler1 = ergebnis !== undefined && ergebnis.spieler1Id !== null && ergebnis.spieler1Id !== undefined;
                let existiertSpieler2 = ergebnis !== undefined && ergebnis.spieler2Id !== null && ergebnis.spieler2Id !== undefined;
                let existiertErgebnis = ergebnis !== undefined && ergebnis.anzahlSpieler1 !== null && ergebnis.anzahlSpieler2 !== null;
                let spieler1Gewonnen = ergebnis && ergebnis !== undefined && ergebnis.anzahlSpieler1 > ergebnis.anzahlSpieler2;
                let spieler2Gewonnen = ergebnis && ergebnis !== undefined && ergebnis.anzahlSpieler2 > ergebnis.anzahlSpieler1;
                let freilosVorhanden = existiertErgebnis && ergebnis !== undefined && (ergebnis.spieler1Id === "freilos" || ergebnis.spieler2Id === "freilos");

                if ((showNurMitBoards && ergebnis === undefined) || (showNurMitBoards && ergebnis !== undefined && getBoardGame(ergebnis.id) === undefined)) {
                    continue;
                }
                if (!showFreilose && freilosVorhanden) {
                    continue;
                }
                if (!showMitErgebnis && existiertErgebnis && !freilosVorhanden) {
                    continue;
                }

                let boardGame = ergebnis !== undefined ? getBoardGame(ergebnis.id) : undefined;
                let liveGame = getLiveGame(boardGame);


                let style = background ? styleWithBackground : styleWithoutBackground;
                let row = <TableRow key={"key_gruppe_" + gruppe + "_spielnummer_" + spielnummer}>
                    <TableCell style={{...style, textAlign: "center", padding: CELL_PADDING_VALUE}}>Gruppe {gruppe}</TableCell>
                    <TableCell style={{...style, textAlign: "center", padding: CELL_PADDING_VALUE}}>{spielnummer}</TableCell>
                    <TableCell style={{
                        ...style,
                        textAlign: "right",
                        padding: CELL_PADDING_VALUE,
                        color: spieler1Gewonnen && "green",
                        fontWeight: spieler1Gewonnen && "bolder"}}>
                        {existiertSpieler1 ? getSpielerName(ergebnis.spieler1Id) : "-"}</TableCell>
                    <TableCell style={{...style, textAlign: "center", padding: CELL_PADDING_VALUE, cursor: existiertErgebnis && !freilosVorhanden && tournamentWrapper.turnierMain.scoringWithDarthelfer ? "pointer" : ""}}>
                        <div onClick={() => {
                            if (existiertErgebnis && !freilosVorhanden && tournamentWrapper.turnierMain.scoringWithDarthelfer) {
                                setShowGameDetailDialog(true);
                                setSelectedTournamentGameId(ergebnis.id);
                            }
                        }}>
                            {existiertErgebnis ?
                                ergebnis.anzahlSpieler1 + " : " + ergebnis.anzahlSpieler2 : getLiveGameErgebnis(liveGame)}
                        </div>
                    </TableCell>
                    <TableCell style={{
                        ...style,
                        textAlign: "left",
                        padding: CELL_PADDING_VALUE,
                        color: spieler2Gewonnen && "green",
                        fontWeight: spieler2Gewonnen && "bolder"}}>
                        {existiertSpieler2 ? getSpielerName(ergebnis.spieler2Id) : "-"}
                    </TableCell>
                    <TableCell style={{...style, textAlign: "center", padding: CELL_PADDING_VALUE}}>
                        {getBoardSchreiberArea(ergebnis)}
                    </TableCell>
                </TableRow>
                if( ergebnis === undefined || getBoardGame(ergebnis.id) === undefined) {
                    returnRows.push(row);
                } else {
                    returnRows.unshift(row);
                }
                background = !background;
            }
        }
        return returnRows;
    }

    const getTableHeader = () => {
        return <TableHead>
            <TableRow>
                <TableCell style={{
                    backgroundColor: "#ac1c27",
                    color: "white",
                    fontSize: 20,
                    width: 120,
                    textAlign: "center", padding: CELL_PADDING_VALUE
                }}>Gruppe</TableCell>
                <TableCell style={{
                    backgroundColor: "#ac1c27",
                    color: "white",
                    fontSize: 20,
                    width: 100,
                    textAlign: "center", padding: CELL_PADDING_VALUE
                }}>Spielnr.</TableCell>
                <TableCell style={{
                    backgroundColor: "#ac1c27",
                    color: "white",
                    fontSize: 20,
                    textAlign: "right",
                    whiteSpace: "nowrap", padding: CELL_PADDING_VALUE
                }}>Spieler 1</TableCell>
                <TableCell style={{
                    backgroundColor: "#ac1c27",
                    color: "white",
                    fontSize: 20,
                    width: 100,
                    textAlign: "left", padding: CELL_PADDING_VALUE
                }}>Ergebnis</TableCell>
                <TableCell style={{
                    backgroundColor: "#ac1c27",
                    color: "white",
                    fontSize: 20,
                    textAlign: "left",
                    whiteSpace: "nowrap", padding: CELL_PADDING_VALUE
                }}>Spieler 2</TableCell>
                <TableCell style={{
                    backgroundColor: "#ac1c27",
                    color: "white",
                    fontSize: 20,
                    textAlign: "center", padding: CELL_PADDING_VALUE
                }}>Board/Schreiber</TableCell>
            </TableRow>
        </TableHead>
    }

    return (
        <div style={{backgroundColor: "#022836"}}>
            {showGameDetailDialog &&
                <StatistikGameDialog tournamentGameId={selectedTournamentGameId} tournamentId={tournamentWrapper.turnierMain.id} onClose={() => {
                    setShowGameDetailDialog(false);
                    setSelectedTournamentGameId(undefined)
                }}/>
            }
            <OverlayPanel ref={filterOP}
                          showCloseIcon={false} dismissable={true}
                          style={{height: 140, width: 300}}>
                <div style={{marginBottom: 10}}>
                    {getFilterButton(showFreilose, () => setShowFreilose(!showFreilose))} zeige Spiele mit Freilose an
                </div>
                <div style={{marginBottom: 10}}>
                    {getFilterButton(showMitErgebnis, () => setShowMitErgebnis(!showMitErgebnis))} zeige Spiele mit Ergebnis an
                </div>
                <div style={{marginBottom: 10}}>
                    {getFilterButton(showNurMitBoards, () => setShowNurMitBoards(!showNurMitBoards))} zeige Spiele nur mit Boards
                </div>
            </OverlayPanel>
            <div style={{textAlign: "left", padding: 10}}>
                <Button icon={PrimeIcons.FILTER}
                        onClick={(e) => filterOP.current.toggle(e)}
                        style={{marginRight: 10}}/>
            </div>

            <TableContainer style={{maxHeight: "100%", paddingBottom: 0}}>
                <Table stickyHeader style={{backgroundColor: "#022836", color: "white", fontSize: 20}}>
                    {getTableHeader()}
                    <TableBody>
                        {getRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
