import React from "react";
import LoginContainer from "../../containers/LoginContainer";
import DHCarousel from "./DHCarousel";

class HeaderBig extends React.Component {

    constructor() {
        super();
        this.state = {index: 0};
    }

    render() {
        return <div className="grid p-dir-row" style={{
            paddingTop: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            marginRight: 0,
            marginLeft: 0,
            backgroundColor: "#f2f2f2",
            color: "black",
            borderBottom: "1px solid #888888",
            width: "100%",
        }}>
            <div className="col-12 sm:col-2 md:col-3 lg:col-1" style={{textAlign: "center"}}>
                <img alt="Logo" src={"/images/darthelfer.svg"} style={{width: "100%", maxWidth: "100px"}}/>
            </div>
            <div className="col-12 sm:col-10 md-9 lg:col-8 maintitle">
                <h1>Deine persönliche Dartplattform!</h1>
                <DHCarousel interval={5000} index={this.state.index} onIndexChange={(index) => this.setState({index: index})} model={[
                    {index: 0, value: <>... <strong>never</strong> stop the training</>},
                    {index: 1, value: <>... deinen <strong>Average</strong> immer im <strong>Blick</strong></>},
                    {index: 2, value: <>... <strong>Trainingseinheiten</strong> verwalten</>},
                    {index: 3, value: <>... dein <strong>Team</strong> beobachten</>},
                    {index: 4, value: <>... veranstalte <strong>deine</strong> Turniere</>},
                    {index: 5, value: <>... Ligaverwaltung</>},
                    {index: 6, value: <>... <strong>Statistiken</strong> ohne Ende</>},
                ]}/>
            </div>
            <div className="col-fixed" style={{width: 310, minWidth: 310}}>
                <LoginContainer/>
            </div>
        </div>
    }
}

export default HeaderBig;
