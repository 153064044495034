import React, {useState} from "react";
import DHDialog from "./DHDialog";
import ErrorInfoComponent from "./ErrorInfoComponent";
import DHDatePicker from "./DHDatePicker";
import DHButtonRefresh from "./DHButtonRefresh";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import CsvDownloader from "react-csv-downloader";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";

export function LigaSpieltagEdartStatistikDialog({eventOperatorId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [beginn, setBeginn] = useState(undefined);
    const [ende, setEnde] = useState(undefined);
    const [statistikRows, setStatistikRows] = useState([]);


    const ladeDaten = () => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/ligaspieltag/edart/statistik/eventoperator/" + eventOperatorId,
            {beginn: beginn, ende: ende},
            json => {
                setStatistikRows(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler:" + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler:" + error.message)
            })
    }

    const getColumns = () => {
        let cols = [
            {
                id: 'cell1',
                displayName: 'Name',
            },
            {
                id: 'cell2',
                displayName: 'Spiele',
            },
            {
                id: 'cell3',
                displayName: 'Gewonnen',
            },
            {
                id: 'cell4',
                displayName: 'Verloren',
            },
            {
                id: 'cell5',
                displayName: 'Unentschieden',
            },
            {
                id: 'cell6',
                displayName: 'T20',
            },
            {
                id: 'cell7',
                displayName: 'T19',
            },
            {
                id: 'cell8',
                displayName: 'T18',
            },
            {
                id: 'cell9',
                displayName: 'T17',
            },
            {
                id: 'cell10',
                displayName: 'T16',
            },
            {
                id: 'cell11',
                displayName: 'Bull',
            },
            {
                id: 'cell12',
                displayName: 'Schwarzer',
            },
            {
                id: 'cell13',
                displayName: '171',
            },
            {
                id: 'cell14',
                displayName: '180',
            },
            {
                id: 'cell15',
                displayName: 'Highscores',
            },
            {
                id: 'cell16',
                displayName: 'Anz.Highscores',
            },
            {
                id: 'cell17',
                displayName: 'Highfinishes',
            },
            {
                id: 'cell18',
                displayName: 'Anz.Highfinishes',
            },
        ];
        return cols;
    }

    const replaceChars = (value) => {
        if (value === null || value === undefined) {
            return "";
        }
        return "" + value.replace(";", ",");
    }

    const getData = () => {
        let rows = [];
        for (const statistikRow of statistikRows) {
            let name = replaceChars(statistikRow.name)
            let spiele = statistikRow.gewonnen + statistikRow.verloren + statistikRow.unentschieden;
            let gewonnen = statistikRow.gewonnen;
            let verloren = statistikRow.verloren;
            let untentschieden = statistikRow.unentschieden;
            let t20 = statistikRow.triple20;
            let t19 = statistikRow.triple19;
            let t18 = statistikRow.triple18;
            let t17 = statistikRow.triple17;
            let t16 = statistikRow.triple16;
            let bull = statistikRow.bull;
            let schwarzer = statistikRow.schwarzer;
            let _171 = statistikRow._171er;
            let _180 = statistikRow._180er;
            let highscores = statistikRow.highscores;
            let highfinishes = statistikRow.highfinishes;
            rows.push({
                cell1: name,
                cell2: spiele,
                cell3: gewonnen ,
                cell4: verloren,
                cell5: untentschieden,
                cell6: t20,
                cell7: t19,
                cell8: t18,
                cell9: t17,
                cell10: t16,
                cell11: bull,
                cell12: schwarzer,
                cell13: _171,
                cell14: _180,
                cell15: highscores,
                cell16: highscores.length,
                cell17: highfinishes,
                cell18: highfinishes.length,
                });

        }
        return rows;
    }

    return <DHDialog onClose={() => onClose()} title="E-Dart Statistiken exportieren" buttons={[]} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{padding: 10}}>
            <div>
                <DHDatePicker disabled={false} value={beginn} changeValue={setBeginn} label="Beginn"/>
            </div>
            <div style={{marginTop: 20}}>
                <DHDatePicker disabled={false} value={ende} changeValue={setEnde} label="Ende"/>
            </div>
            <div style={{marginTop: 20}}>
                <DHButtonRefresh disabled={!beginn || !ende} onClick={() => ladeDaten()} label="Aktualisieren"/>
            </div>
            {statistikRows.length == 0 &&
                <div style={{marginTop: 20}}>
                    Keine Daten vorhanden...
                </div>
            }

            {statistikRows.length > 0 &&
                <div style={{marginTop: 20}}>
                    <CsvDownloader filename="edartstatistikexport.csv" datas={getData()} columns={getColumns()} separator=";">
                        <Button hr icon={PrimeIcons.DOWNLOAD} label="Teilnehmer exportieren (csv)"/>
                    </CsvDownloader>
                </div>
            }
        </div>
    </>}/>
}
