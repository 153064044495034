import * as React from 'react';
import {useEffect, useState} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function DHRadioGroup({id, value, model, onChange, label}) {

    const [v, setV] = useState(false);

    useEffect(() => {
        setV(value);
    }, [value]);

    const getItems = () => {
        let rows = [];
        for (const modelElement of model) {
            rows.push(<FormControlLabel value={modelElement.value} control={<Radio />} label={modelElement.label}
                                        onClick={() => onChange(modelElement.value)}
            />);
        }
        return rows;
    }


    return (
        <FormControl>
            <FormLabel id={id}>{label}</FormLabel>
            <RadioGroup
                aria-labelledby={id}
                defaultValue={value}
                name="radio-buttons-group"
            >
                {getItems()}
            </RadioGroup>
        </FormControl>
    );
}