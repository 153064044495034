import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import ParticipantConfirmation from "../../../components/views/public/ParticipantConfirmation";
import ConfigUtil from "../../../util/ConfigUtil";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const startseiteUrl = ConfigUtil.getBasicClientUrl();
    const id = urlParams.get('id');
    return {id: id, startseiteUrl: startseiteUrl};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ParticipantConfirmation))
