import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../../util/FetchUtil";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import PlatzierungTable from "./PlatzierungTable";
import ConfigUtil from "../../../../util/ConfigUtil";
import DHTextFieldSearchPublicArea from "../../../general/DHTextFieldSearchPublicArea";

export default function PublicTournamentPlatzierungTab({tournamentId}) {
    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [filterText, setFilterText] = React.useState("");

    let [platzierungen, setPlatzierungen] = useState([]);

    useEffect(() => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.public + "/tournament/platzierung/" + tournamentId,
            {},
            json => {
                setPlatzierungen(json);
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
            },
            error => {
                setErrorMessage(error.message);
            })
    }, [tournamentId]);

    const filterPlatzierungen = () => {
        if( filterText === "") {
            return platzierungen;
        }
        let returnList = [];
        for (const platzierung of platzierungen) {
            if( platzierung.name.toUpperCase().includes(filterText.toUpperCase())) {
                returnList.push(platzierung);
            }
        }
        return returnList;
    }

    return <div style={{marginTop: 20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <div style={{color: "white", padding: 10, fontSize: 20}}>
                <DHTextFieldSearchPublicArea id="id-name-filter" value={filterText} onChange={(text) => setFilterText(text)} width={100} label="Suche" variant="filled"/>
            </div>
            <PlatzierungTable platzierungen={filterPlatzierungen()}/>
        </div>
        }

