import React from "react";
import {TextField,} from "@mui/material";

export default function DHTextFieldSearchPublicArea({id, label, width, value, variant, onChange}) {

    return <TextField id={id} variant={variant ? variant : "standard"}
                      hiddenLabel={true}
                      size={"small"}
                      style={{width: width, backgroundColor:"white"}} placeholder={label}
                      type="search" required={false} value={value} onChange={(event) => onChange(event.target.value)}/>

}