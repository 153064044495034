import React, {useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import ProgressButton from "../../general/ProgressButton";

export function AdministrationTournament() {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [generatePlayersWorks, setGeneratePlayersWorks] = useState(false);

    let [tournamentId, setTournamentId] = useState("");
    let [anzahl, setAnzahl] = useState("");

    const loeschen = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.admin + "/tournament/delete/" + tournamentId,
            {},
            () => {
                setInfoMessage("Gelöscht");
                setTournamentId("");
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Löschen: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Löschen: " + error.message);
            })
    }

    const refreshStatistik = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.admin + "/tournament/statistik/refresh/" + tournamentId,
            {},
            () => {
                setInfoMessage("Refresh ausgeführt");
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Refresh: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Refresh: " + error.message);
            })
    }

    const generateOfflineTeilnehmer = () => {
        setErrorMessage(undefined);
        setGeneratePlayersWorks(true);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.admin + "/generiereOfflineTurnierteilnehmer/" + tournamentId + "/" + anzahl,
            {},
            () => {
                setInfoMessage("Gespeichert");
                setGeneratePlayersWorks(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Generieren: " + responseNotOk.message);
                setGeneratePlayersWorks(false);
            },
            error => {
                setErrorMessage("Fehler beim Generieren: " + error.message);
                setGeneratePlayersWorks(false);
            })
    }

    const ergebnisseEintragen = () => {
        setErrorMessage(undefined);
        setGeneratePlayersWorks(true);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.admin + "/ergebnisse/eintragen/" + tournamentId,
            {},
            () => {
                setInfoMessage("Eintragen...");
                setGeneratePlayersWorks(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setGeneratePlayersWorks(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setGeneratePlayersWorks(false);
            }, 300000)
    }

    const generateTeilnehmer = () => {
        setErrorMessage(undefined);
        setGeneratePlayersWorks(true);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.admin + "/generiereTurnierteilnehmer/" + tournamentId + "/" + anzahl,
            {},
            () => {
                setInfoMessage("Gespeichert");
                setGeneratePlayersWorks(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Generieren: " + responseNotOk.message);
                setGeneratePlayersWorks(false);
            },
            error => {
                setErrorMessage("Fehler beim Generieren: " + error.message);
                setGeneratePlayersWorks(false);
            })
    }

    return <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid red"}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <h3>Löschen Turnier ACHTUNG!!!</h3>
        <div style={{width: "100%"}}>
            <InputText type="text" placeholder="tournamentId" style={{width: 600}} value={tournamentId}
                       onChange={(e) => setTournamentId(e.target.value)}/>
        </div>
        <div style={{marginTop: 20}}>
            <Button onClick={() => loeschen()} label="Löschen..."
                    disabled={tournamentId === ""}/>
        </div>
        <hr/>
        <h3>Ergebnisse am Turnier eintragen - Adminoption</h3>
        <div style={{width: "100%"}}>
            <InputText type="text" placeholder="tournamentId" style={{width: 600}} value={tournamentId}
                       onChange={(e) => setTournamentId(e.target.value)}/>
        </div>
        <div style={{marginTop: 20}}>
            <ProgressButton text="Eintragen..."
                            icon={undefined}
                            disabled={tournamentId === ""}
                            working={generatePlayersWorks}
                            onClick={() => ergebnisseEintragen()}/>
        </div>
        <hr/>
        <h3>Turnierteilnehmer am Turnier generieren - Onlinespieler - Adminoption</h3>
        <div style={{width: "100%"}}>
            <InputText type="text" placeholder="tournamentId" style={{width: 600}} value={tournamentId}
                       onChange={(e) => setTournamentId(e.target.value)}/>
        </div>
        <div style={{marginTop: 20}}>
            <InputText type="number" placeholder="Anzahl als Ganzzahl" value={anzahl}
                       onChange={(e) => setAnzahl(e.target.value)}/>
        </div>
        <div style={{marginTop: 20}}>
            <ProgressButton text="Generieren..."
                            icon={undefined}
                            disabled={anzahl === "" || tournamentId === ""}
                            working={generatePlayersWorks}
                            onClick={() => generateTeilnehmer()}/>
        </div>
        <hr/>
        <h3>Turnierteilnehmer am Turnier generieren - Offlineteilnehmer - Adminoption</h3>
        <div style={{width: "100%"}}>
            <InputText type="text" placeholder="tournamentId" style={{width: 600}} value={tournamentId}
                       onChange={(e) => setTournamentId(e.target.value)}/>
        </div>
        <div style={{marginTop: 20}}>
            <InputText type="number" placeholder="Anzahl als Ganzzahl" value={anzahl}
                       onChange={(e) => setAnzahl(e.target.value)}/>
        </div>
        <div style={{marginTop: 20}}>
            <ProgressButton text="Generieren..."
                            icon={undefined}
                            disabled={anzahl === "" || tournamentId === ""}
                            working={generatePlayersWorks}
                            onClick={() => generateOfflineTeilnehmer()}/>
        </div>
        <hr/>
        <h3>Refresh Turnierstatistik</h3>
        <div style={{width: "100%"}}>
            <InputText type="text" placeholder="tournamentId" style={{width: 600}} value={tournamentId}
                       onChange={(e) => setTournamentId(e.target.value)}/>
        </div>
        <div style={{marginTop: 20}}>
            <Button onClick={() => refreshStatistik()} label="Refresh..."
                    disabled={tournamentId === ""}/>
        </div>
    </div>
}
