import * as React from 'react';
import {useState} from 'react';
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {PrimeIcons} from "primereact/api";

export default function StatisticTable({participants, tournamentId, playerStatistiks, onShowStatistik, onShowGames}) {
    const CELL_PADDING_VALUE = 8;

    let [selectedParticipant, setSelectedParticipant] = useState(undefined);
    let [sortDown, setSortDown] = useState(true);
    let [sortColumn, setSortColumn] = useState("name");

    const getSortedParticipants = () => {
        let rows = [];
        participants.forEach(participant => {
            let statistik = findStatistik(participant.id, participant.idShort, playerStatistiks);
            rows.push({id: participant.id,
                idShort: participant.idShort,
                name: participant.name,
                average: statistik !== undefined ? statistik.average : "-",
                first9Average: statistik !== undefined ? statistik.first9Average : "-",
                _100Plus: statistik !== undefined ? statistik._100Plus : "-",
                _140Plus: statistik !== undefined ? statistik._140Plus : "-",
                _171er: statistik !== undefined ? statistik._171er : "-",
                _180er: statistik !== undefined ? statistik._180er : "-",
            });
        });
        if( sortColumn === "name") {
            rows.sort((player1, player2) => player1.name.localeCompare(player2.name));
        }
        if( sortColumn === "average") {
            rows.sort((player1, player2) => {
                if( player1.average === "-") {
                    return -1
                } else if( player2.average === "-" ) {
                    return 1;
                } else {
                    return player1.average - player2.average;
                }
            });
        }
        if( sortColumn === "first9average") {
            rows.sort((player1, player2) =>
            {
                if( player1.first9Average === "-") {
                    return -1
                } else if( player2.first9Average === "-" ) {
                    return 1;
                } else {
                    return player1.first9Average - player2.first9Average;
                }
            });
        }
        if( sortColumn === "100+") {
            rows.sort((player1, player2) =>
            {
                if( player1._100Plus === "-") {
                    return -1
                } else if( player2._100Plus === "-" ) {
                    return 1;
                } else {
                    return player1._100Plus - player2._100Plus;
                }
            });
        }
        if( sortColumn === "140+") {
            rows.sort((player1, player2) =>
            {
                if( player1._140Plus === "-") {
                    return -1
                } else if( player2._140Plus === "-" ) {
                    return 1;
                } else {
                    return player1._140Plus - player2._140Plus;
                }
            });
        }
        if( sortColumn === "171") {
            rows.sort((player1, player2) =>
            {
                if( player1._171er === "-") {
                    return -1
                } else if( player2._171er === "-" ) {
                    return 1;
                } else {
                    return player1._171er - player2._171er;
                }
            });
        }
        if( sortColumn === "180") {
            rows.sort((player1, player2) =>
            {
                if( player1._180er === "-") {
                    return -1
                } else if( player2._180er === "-" ) {
                    return 1;
                } else {
                    return player1._180er - player2._180er;
                }
            });
        }
        if( !sortDown ) {
            rows.reverse();
        }
        return rows;
    }

    const getTableRows = (participants, playerStatistiks, onShowStatistik, onShowGames) => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleSelected = {background: "#FD8943", color:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        getSortedParticipants().forEach(participant => {
            let statistik = findStatistik(participant.id, participant.idShort, playerStatistiks);
            let style;
            if( selectedParticipant !== undefined && selectedParticipant.id === participant.id) {
                style = styleSelected;
            } else {
                style = background ? styleWithBackground : styleWithoutBackground;
            }
            returnRows.push( <TableRow key={participant.id} onMouseOver={() => setSelectedParticipant(participant)}
            onClick={() => setSelectedParticipant(participant)}>
                <TableCell style={style}>{participant.name}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{participant.average}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{participant.first9Average}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{participant._100Plus}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{participant._140Plus}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{participant._171er}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{participant._180er}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>
                    {statistik !== undefined ? <>
                        <i className={PrimeIcons.CHART_LINE} style={{fontSize:20, paddingRight:2, cursor:"pointer"}} onClick={() => onShowStatistik(statistik, participant.name)}/>
                        {tournamentId !== undefined && <i className={PrimeIcons.DATABASE} style={{fontSize:20, padding:2, cursor:"pointer"}} onClick={() => onShowGames(participant.id)}/>}
                    </>
                        :
                        "-"
                    }
                </TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    const findStatistik = (playerId, idShort, playerStatistiks) => {
        for (const statistik of playerStatistiks) {
            if( statistik.playerid === playerId || statistik.idShort === idShort) {
                return statistik;
            }
        }
        return undefined;
    }

    const changeSort = (column) => {
        let newSortDown = !sortDown;
        setSortDown(newSortDown);
        setSortColumn(column)
    }

    if( participants.length === 0) {
        return ""
    }

    return (
        <TableContainer style={{ maxHeight: "100%", paddingBottom:0 }}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:20}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 20, cursor:"pointer", padding: CELL_PADDING_VALUE}}
                                   onClick={() => changeSort("name")}>
                            Name
                            {sortColumn === "name" && <i className={sortDown ? PrimeIcons.SORT_AMOUNT_DOWN : PrimeIcons.SORT_AMOUNT_UP}
                                                         style={{marginLeft:5, fontSize: 20, cursor: "pointer"}}/>
                            }
                        </TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 20, cursor:"pointer", width: 30, padding: CELL_PADDING_VALUE}} onClick={() => changeSort("average")}>
                            Avg.
                            {sortColumn === "average" && <i className={!sortDown ? PrimeIcons.SORT_AMOUNT_DOWN : PrimeIcons.SORT_AMOUNT_UP}
                                       style={{marginLeft:5, fontSize: 20, cursor: "pointer"}}/>
                            }
                        </TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 20, cursor:"pointer", width: 30, padding:CELL_PADDING_VALUE}} onClick={() => changeSort("first9average")}>
                            F-9 Avg.
                            {sortColumn === "first9average" && <i className={!sortDown ? PrimeIcons.SORT_AMOUNT_DOWN : PrimeIcons.SORT_AMOUNT_UP}
                                                            style={{marginLeft:5, fontSize: 20, cursor: "pointer"}}/>
                            }
                        </TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, cursor:"pointer", width:30, padding:CELL_PADDING_VALUE}} onClick={() => changeSort("100+")}>
                            100+
                            {sortColumn === "100+" && <i className={!sortDown ? PrimeIcons.SORT_AMOUNT_DOWN : PrimeIcons.SORT_AMOUNT_UP}
                                                                  style={{marginLeft:5, fontSize: 20, cursor: "pointer"}}/>
                            }
                        </TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, cursor:"pointer", width:30, padding:CELL_PADDING_VALUE}} onClick={() => changeSort("140+")}>
                            140+
                            {sortColumn === "140+" && <i className={!sortDown ? PrimeIcons.SORT_AMOUNT_DOWN : PrimeIcons.SORT_AMOUNT_UP}
                                                                  style={{marginLeft:5, fontSize: 20, cursor: "pointer"}}/>
                            }
                        </TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, cursor:"pointer", width:30, padding:CELL_PADDING_VALUE}} onClick={() => changeSort("171")}>
                            171
                            {sortColumn === "171" && <i className={!sortDown ? PrimeIcons.SORT_AMOUNT_DOWN : PrimeIcons.SORT_AMOUNT_UP}
                                                                  style={{marginLeft:5, fontSize: 20, cursor: "pointer"}}/>
                            }
                        </TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, cursor:"pointer", width:30, padding:CELL_PADDING_VALUE}} onClick={() => changeSort("180")}>
                            180
                            {sortColumn === "180" && <i className={!sortDown ? PrimeIcons.SORT_AMOUNT_DOWN : PrimeIcons.SORT_AMOUNT_UP}
                                                                  style={{marginLeft:5, fontSize: 20, cursor: "pointer"}}/>
                            }
                        </TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:100, padding:CELL_PADDING_VALUE}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows(participants, playerStatistiks, onShowStatistik, onShowGames)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
