import React from 'react'

export default function TurnierBaumSpielnummerAnzeige({nummer, xValue, yValue, textAlign}) {

    const style = {
        position: "absolute",
        width: 100,
        height: 18,
        backgroundColor: "#0a6c02",
        color: "#eee",
        fontWeight: "bold",
        paddingRight: 0,
        paddingTop: 0,
        top: yValue + "px",
        left: xValue + "px",
        textAlign: textAlign,
        marginTop: 5,
        fontSize: 10,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    };

    return <div key={"turnierkachel_spielnummer_" + nummer} style={{...style, padding: 3, margin: 0}}>
        Spiel: {nummer}
        </div>
}
