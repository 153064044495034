import {connect} from 'react-redux'
import withRouter from "../components/RouterComponentWrapper"
import RegistrationComplete from "../components/RegistrationComplete";
import ConfigUtil from "../util/ConfigUtil";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const startseiteUrl = ConfigUtil.getBasicClientUrl();
    const userRegistrationId = urlParams.get('id');
    return {userregistrationid: userRegistrationId, startseiteUrl: startseiteUrl};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationComplete))