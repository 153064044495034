import React from 'react'

import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import BoardSelectionDialog from "../veranstalter/BoardSelectionDialog";
import KODKOErgebnisEintragenDialog from "./KODKOErgebnisEintragenDialog";
import StatistikGameDialog from "../public/tournaments/StatistikGameDialog";

class TurnierKachel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anzahlSpieler1: 0,
            anzahlSpieler2: 0,
            showErgebnisEintragen: false,
            showBoardSelectionDialog: false,
            showGameDetailDialog: false,
            showGameFinal2DetailDialog: false,
        };
    }

    isInaktiv(id) {
        if (id === undefined || id === null) {
            return "";
        }

        for (let i = 0; i < this.props.spielerliste.length; i++) {
            let spieler = this.props.spielerliste[i];
            if (spieler.id === id) {
                return spieler.inaktiv;
            }
        }
        return false;
    }

    getSpielerName(id) {
        if (id === undefined || id === null) {
            return "";
        }

        for (let i = 0; i < this.props.spielerliste.length; i++) {
            let spieler = this.props.spielerliste[i];
            if (spieler.id === id) {
                return spieler.name;
            }
        }
        return "Freilos";
    }

    gibtEsErgbnisseDieAenderungVerhindern(feld) {
        let fields = [];
        for (const tmpModel of this.props.models) {
            if (tmpModel.parent1 === feld || tmpModel.parent2 === feld) {
                fields.push(tmpModel.feld);
            }
        }
        if (this.props.ergebnisse !== undefined && this.props.ergebnisse !== null) {
            for (const ergebnis of this.props.ergebnisse) {
                fields.includes(ergebnis.feld);
                if (fields.includes(ergebnis.feld) && ergebnis.anzahlSpieler1 !== undefined && ergebnis.anzahlSpieler1 !== null) {
                    return true;
                }
            }
        }
        return false;
    }

    getErgebnis(feld) {
        if (this.props.ergebnisse !== undefined && this.props.ergebnisse !== null) {
            for (const ergebnis of this.props.ergebnisse) {
                if (ergebnis.feld === feld) {
                    return ergebnis;
                }
            }
        }
        return undefined;
    }

    getAuslosung(feld) {
        if (this.props.auslosung !== undefined && this.props.auslosung !== null) {
            for (let i = 0; i < this.props.auslosung.length; i++) {
                let tmpAuslosung = this.props.auslosung[i];
                if (tmpAuslosung.feld === feld) {
                    return tmpAuslosung;
                }
            }
        }
        return undefined;
    }

    getSpieler2IstSieger(ergebnisVorhanden, anzahlSpieler1, anzahlSpieler2) {
        return ergebnisVorhanden && anzahlSpieler2 > anzahlSpieler1;
    }

    getLiveGame() {
        if( this.props.liveGames === null || this.props.liveGames === undefined || this.props.boardGame === null || this.props.boardGame === undefined) {
            return undefined;
        }
        let gameId = this.props.boardGame.gameId;
        let tmpGames = this.props.liveGames.find(game => game.gameId === gameId);
        return tmpGames
    }

    getLiveGameErgebnis(showPlayer1) {
        let liveGame = this.getLiveGame();
        if( liveGame === undefined) {
            return "";
        }
        if( liveGame.startssoon && showPlayer1) {
            return <img src="/images/dot_red.png" style={{width:20}}/>
        }
        if( liveGame.startssoon && !showPlayer1) {
            return "";
        }
        if( liveGame.inGame && showPlayer1) {
            return <img src="/images/dot_green.png" style={{width:20}}/>
        }
        if( liveGame.inGame && !showPlayer1) {
            return "";
        }
        if( liveGame.sets > 1 ) {
            return <span style={{opacity:0.5}}>{showPlayer1 ? liveGame.spieler1Sets : liveGame.spieler2Sets}</span>
        } else {
            return <span style={{opacity:0.5}}>{showPlayer1 ? liveGame.spieler1Legs : liveGame.spieler2Legs}</span>
        }
    }

    getDataArea(ergebnisVorhanden, aktuellerUserInGameVorhanden, beideSpielerVorhanden, feld, playerId, spieler1Id, spieler2Id, anzahlSpieler1, anzahlSpieler2, spielername1, spielername2, anzahlSpieler1Finale2, anzahlSpieler2Finale2) {
        let spieler1Inaktiv = this.isInaktiv(spieler1Id);
        let spieler2Inaktiv = this.isInaktiv(spieler2Id);
        let freilosVorhanden = spieler1Id === "freilos" || spieler2Id === "freilos";

        let player1Style = {
            width: "100%",
            paddingLeft: 5,
            paddingTop: 2,
            display: "flex",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            backgroundColor: (ergebnisVorhanden) && Number(anzahlSpieler1) > Number(anzahlSpieler2) ? "#6b9d25" : "",
            textDecoration: spieler1Inaktiv ? "line-through" : "none",
        };
        let player2Style = {
            width: "100%",
            paddingLeft: 5,
            paddingTop: 2,
            display: "flex",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            backgroundColor: ergebnisVorhanden && Number(anzahlSpieler2) > Number(anzahlSpieler1) ? "#6b9d25" : "",
            textDecoration: spieler2Inaktiv ? "line-through" : "none",
        };
        if (this.props.selectedPlayerId !== undefined && this.props.selectedPlayerId === spieler1Id) {
            player1Style = {...player1Style, backgroundColor: "#FD8943"};
        }
        if (this.props.selectedPlayerId !== undefined && this.props.selectedPlayerId === spieler2Id) {
            player2Style = {...player2Style, backgroundColor: "#FD8943"};
        }
        return <div key={"turnierkachel_" + feld} style={{margin: 0, padding: 0}}>
            {!this.props.readonly && !ergebnisVorhanden && aktuellerUserInGameVorhanden &&
                <NavLink to={NAVIGATION_ITEM.SCORING.route + "?gameid=" + this.props.game.id + "&playerid=" + playerId}
                         style={{textDecoration: "none"}}>
                    <img src="/images/dart_select.png"
                         style={{position: "absolute", top: 13, right: 0, cursor: "pointer", width: 30}} alt=""/>
                </NavLink>
            }
            {(!this.props.readonly && !this.props.tournament.online && !ergebnisVorhanden && beideSpielerVorhanden) &&
                <img src="/images/kneipe.png" alt="Board vergeben" title="Board vergeben"
                     style={{position: "absolute", top: 13, right: 0, cursor: "pointer", width: 30}}
                     onClick={() => this.setState({showBoardSelectionDialog: true})}/>
            }
            <div style={{display: "flex", height: 29}} onMouseOver={() => this.props.onPlayerSelect(spieler1Id)}
                 onMouseOut={() => this.props.onPlayerSelect(undefined)}>
                <div style={player1Style} onClick={(e) => this.props.model.endknoten || this.props.readonly || !beideSpielerVorhanden ? "" : this.setState({showErgebnisEintragen: true})}>{spielername1}</div>
                <div style={{
                    width: 23,
                    paddingTop: 2,
                    textAlign: "center",
                    borderLeft: "1px solid gray",
                    backgroundColor: ergebnisVorhanden ? (Number(anzahlSpieler1) > Number(anzahlSpieler2) ? "#6b9d25" : "#ac1c27") : "",
                    cursor: ergebnisVorhanden && !freilosVorhanden && this.props.tournament.scoringWithDarthelfer ? "pointer" : ""
                }} onClick={() => {
                    if( ergebnisVorhanden && !freilosVorhanden && this.props.tournament.scoringWithDarthelfer) {
                        this.setState({showGameDetailDialog: true})
                    }
                }}>{ergebnisVorhanden ? anzahlSpieler1 : this.getLiveGameErgebnis(true)}</div>
                {this.props.model.tournamentModus === "finale" && this.isFinaleMitSatzausgleich() && this.getSpieler2IstSieger(ergebnisVorhanden, anzahlSpieler1, anzahlSpieler2) &&
                    <div style={{
                        width: 23,
                        paddingTop: 2,
                        textAlign: "center",
                        borderLeft: "1px solid gray",
                        backgroundColor: anzahlSpieler1Finale2 !== null && anzahlSpieler1Finale2 !== undefined ? (Number(anzahlSpieler1Finale2) > Number(anzahlSpieler2Finale2) ? "#6b9d25" : "#ac1c27") : "",
                        cursor: this.props.tournament.scoringWithDarthelfer ? "pointer" : ""
                    }} onClick={() => {
                        if( this.props.tournament.scoringWithDarthelfer) {
                            this.setState({showGameFinal2DetailDialog: true})
                        }
                    }}>{anzahlSpieler1Finale2}</div>
                }
            </div>
            <div style={{display: "flex", height: 29}} onMouseOver={() => this.props.onPlayerSelect(spieler2Id)}
                 onMouseOut={() => this.props.onPlayerSelect(undefined)}>
                <div style={player2Style} onClick={(e) => this.props.model.endknoten || this.props.readonly || !beideSpielerVorhanden ? "" : this.setState({showErgebnisEintragen: true})}>{spielername2}</div>
                <div style={{
                    width: 23,
                    paddingTop: 2,
                    textAlign: "center",
                    borderLeft: "1px solid gray",
                    backgroundColor: ergebnisVorhanden ? (Number(anzahlSpieler2) > Number(anzahlSpieler1) ? "#6b9d25" : "#ac1c27") : "",
                    cursor: ergebnisVorhanden && !freilosVorhanden && this.props.tournament.scoringWithDarthelfer ? "pointer" : ""
                }} onClick={() => {
                    if( ergebnisVorhanden && !freilosVorhanden && this.props.tournament.scoringWithDarthelfer) {
                        this.setState({showGameDetailDialog: true})
                    }
                }}>{ergebnisVorhanden ? anzahlSpieler2 : this.getLiveGameErgebnis(false)}</div>
                {this.props.model.tournamentModus === "finale" && this.isFinaleMitSatzausgleich() && this.getSpieler2IstSieger(ergebnisVorhanden, anzahlSpieler1, anzahlSpieler2) &&
                    <div style={{
                        width: 23,
                        paddingTop: 2,
                        textAlign: "center",
                        borderLeft: "1px solid gray",
                        backgroundColor: anzahlSpieler2Finale2 !== null && anzahlSpieler2Finale2 !== undefined ? (Number(anzahlSpieler2Finale2) > Number(anzahlSpieler1Finale2) ? "#6b9d25" : "#ac1c27") : "",
                        cursor: this.props.tournament.scoringWithDarthelfer ? "pointer" : ""
                    }} onClick={() => {
                        if( this.props.tournament.scoringWithDarthelfer) {
                            this.setState({showGameFinal2DetailDialog: true})
                        }
                    }}>{anzahlSpieler2Finale2}</div>
                }
            </div>
        </div>
    }

    isFinaleMitSatzausgleich() {
        return this.props.tournament.finaleVerliererseiteMussZweiSpieleGewinnen !== null && this.props.tournament.finaleVerliererseiteMussZweiSpieleGewinnen;
    }

    render() {
        // Farbe darkmode = #F8F5F5 light = #999999
        let style = {
            position: "absolute",
            width: 200,
            height: 60,
            backgroundColor: "#999999",
            color: "#eee",
            fontWeight: "bold",
            paddingLeft: 5,
            paddingRight: 0,
            paddingTop: 0,
            top: this.props.y + "px",
            left: this.props.x + "px",
            border: "2px solid gray",
            borderRadius: 3,
            textAlign: "left",
            marginTop: 5,
            fontSize: 16,
        };
        let feld = this.props.model.feld;
        let spieler1Id = undefined;
        let spieler2Id = undefined;
        let spielername1 = undefined;
        let spielername2 = undefined;
        if (feld.length === 1) {
            // es handelt sich um ein Rootfeld, also erste Ebene. Da werden die Spieler einfach aus der Auslosung angezeigt.
            let auslosung = this.getAuslosung(feld);
            if (auslosung !== undefined) {
                spielername1 = this.getSpielerName(auslosung.spieler1Id);
                spielername2 = this.getSpielerName(auslosung.spieler2Id);
                spieler1Id = auslosung.spieler1Id;
                spieler2Id = auslosung.spieler2Id;
            }
        } else {
            let ergebnisZumFeld = this.getErgebnis(feld);
            if (ergebnisZumFeld !== undefined) {
                spieler1Id = ergebnisZumFeld.spieler1Id;
                spieler2Id = ergebnisZumFeld.spieler2Id;
                spielername1 = this.getSpielerName(ergebnisZumFeld.spieler1Id);
                spielername2 = this.getSpielerName(ergebnisZumFeld.spieler2Id);
            }
        }
        let beideSpielerVorhanden = spieler1Id !== null && spieler1Id !== undefined && spieler2Id !== null && spieler2Id !== undefined;
        let turnierIstAbgeschlossen = this.props.tournamentstatus === "abgeschlossen";

        let ergebnisAktuellesFeld = this.getErgebnis(feld);
        let anzahlSpieler1Finale2 = undefined;
        let anzahlSpieler2Finale2 = undefined;
        if( this.props.model.tournamentModus === "finale") {
            let ergebnisFinale2Feld = this.getErgebnis("finale2");
            anzahlSpieler1Finale2 = ergebnisFinale2Feld !== undefined ? ergebnisFinale2Feld.anzahlSpieler1 : "";
            anzahlSpieler2Finale2 = ergebnisFinale2Feld !== undefined ? ergebnisFinale2Feld.anzahlSpieler2 : "";
        }
        let anzahlSpieler1 = ergebnisAktuellesFeld !== undefined ? ergebnisAktuellesFeld.anzahlSpieler1 : "";
        let anzahlSpieler2 = ergebnisAktuellesFeld !== undefined ? ergebnisAktuellesFeld.anzahlSpieler2 : "";
        let schreiberName = ergebnisAktuellesFeld !== undefined ? ergebnisAktuellesFeld.schreiberName : "";
        let ergebnisVorhanden = anzahlSpieler1 !== null && anzahlSpieler1 !== undefined && anzahlSpieler1 !== "";
        let aktuellerUserInGameVorhanden = this.props.game !== null && this.props.game !== undefined && (this.props.game.user1id === this.props.userId || this.props.game.user2id === this.props.userId);
        let playerId;
        if (aktuellerUserInGameVorhanden) {
            if (this.props.game.user1id === this.props.userId) {
                playerId = this.props.game.player1id;
            }
            if (this.props.game.user2id === this.props.userId) {
                playerId = this.props.game.player2id;
            }
        }
        return <div key={"turnierkachel_oberste_ebene_" + this.props.tournament.id + "_" + feld} style={{...style, padding: 0, margin: 0}}>
            {this.state.showGameDetailDialog &&
                <StatistikGameDialog tournamentId={this.props.tournament.id} tournamentGameId={this.getErgebnis(feld).id} onClose={() => {
                    this.setState({showGameDetailDialog: false});
                }}/>
            }
            {this.state.showGameFinal2DetailDialog &&
                <StatistikGameDialog tournamentId={this.props.tournament.id} tournamentGameId={this.getErgebnis("finale2").id} onClose={() => {
                    this.setState({showGameFinal2DetailDialog: false});
                }}/>
            }
            {this.getDataArea(ergebnisVorhanden, aktuellerUserInGameVorhanden, beideSpielerVorhanden, feld, playerId, spieler1Id, spieler2Id, anzahlSpieler1, anzahlSpieler2, spielername1, spielername2, anzahlSpieler1Finale2, anzahlSpieler2Finale2)}
            {this.state.showErgebnisEintragen &&
                <KODKOErgebnisEintragenDialog spieler1Id={spieler1Id} spieler2Id={spieler2Id}
                       spielername1={this.getSpielerName(spieler1Id)}
                       spielername2={this.getSpielerName(spieler2Id)}
                       models={this.props.models}
                       model={this.props.model}
                       feld={feld}
                       gruppe={this.props.tournamentGroup}
                       tournamentGameId={this.getErgebnis(feld).id}
                       tournamentId={this.props.tournament.id}
                       finaleMitSatzausgleich={this.isFinaleMitSatzausgleich()}
                       ergebnisse={this.props.ergebnisse}
                       readonly={this.props.readonly || turnierIstAbgeschlossen}
                       onRefresh={this.props.callBackLoadData}
                       onClose={() => this.setState({showErgebnisEintragen: false})} />
            }
            {this.state.showBoardSelectionDialog &&
                <BoardSelectionDialog onClose={() => this.setState({showBoardSelectionDialog: false})}
                                      eventOperatorId={this.props.tournament.eventOperatorId}
                                      spieler1Name={spielername1} spieler2Name={spielername2}
                                      schreiberName={schreiberName}
                                      showSchreiberArea={true}
                                      players={this.props.tournament.tournamentPlayerEntities}
                                      callBackLoadData={this.props.callBackLoadData}
                                      tournamentId={this.props.tournament.id} tournamentGameId={this.getErgebnis(feld).id}/>}

        </div>
    }
}

TurnierKachel.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentGroup: PropTypes.number.isRequired,
    ergebnisse: PropTypes.array.isRequired,
    auslosung: PropTypes.array.isRequired,
    spielerliste: PropTypes.array.isRequired,
    games: PropTypes.object,
    model: PropTypes.object.isRequired,
    models: PropTypes.array.isRequired,
    callBackLoadData: PropTypes.func.isRequired,
    userId: PropTypes.string,
    readonly: PropTypes.bool.isRequired,
    onPlayerSelect: PropTypes.func.isRequired,
    selectedPlayerId: PropTypes.string,
    boardGame: PropTypes.object,
    liveGames: PropTypes.array,
};

export default TurnierKachel
