import React from "react";
import {TextField,} from "@mui/material";

export default function DHTextField({id, label, width, required, value, disabled, onChange, onKeyDown}) {

    return <TextField id={id} label={label} variant="filled" style={{width: width}} required={required}
                      value={value} onChange={(event) => onChange(event.target.value)}
                      disabled={disabled}
                      onKeyDown={(e) => {
                          if( onKeyDown !== undefined) {
                              onKeyDown(e);
                          }
                      }}
    />

}