import React, {useState} from 'react'
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {Sidebar} from "primereact/sidebar";
import {Button} from "primereact/button";
import StartseiteGameScoringInvitation from "./StartseiteGameScoringInvitation";
import StartseiteVideoInvitation from "./StartseiteVideoInvitation";
import StartseiteGameWatchInvitation from "./StartseiteGameWatchInvitation";

export default function StartseiteLinks({cols, isOnlineDashboard}) {

    let [showGameInvitation, setShowGameInvitation] = useState(false);
    let [showVideoInvitation, setShowVideoInvitation] = useState(false);
    let [showWatchInvitation, setShowWatchInvitation] = useState(false);


    const closeSidebar = () => {
        setShowGameInvitation(false);
        setShowVideoInvitation(false);
        setShowWatchInvitation(false);
    }

    return <div className={"col-12 md:col-12 lg:col-" + cols} style={{padding: 10}}>
        <div className="kachelStartseite" style={{height: "100%"}}>
            <h3>Quicklinks:</h3>
            <ul>
                <li style={{marginBottom: 10, cursor: "pointer"}}>
                    <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENTS.route}
                             style={{textDecoration: "none", color: "black"}}>
                        Events / Livescores <img src="images/star.png" style={{width:25}}/>
                    </NavLink>
                </li>
                <li style={{marginBottom: 10, cursor: "pointer"}} onClick={() => window.open("https://scorer.darthelfer.de", "_self")}>
                    darthelfer-scorer (App für Kneipe oder zuhause)
                </li>
            </ul>
            {isOnlineDashboard && <>
                <hr/>
                <ul>
                    <li style={{marginBottom: 10, cursor: "pointer"}}
                        onClick={() => setShowGameInvitation(true)}>Einladung zu
                        einem Game
                    </li>
                    <li style={{marginBottom: 10, cursor: "pointer"}}
                        onClick={() => setShowVideoInvitation(true)}>Meetingkey - Zuschauen (Video) bei einem Game
                    </li>
                    <li style={{marginBottom: 10, cursor: "pointer"}} onClick={() => setShowWatchInvitation(true)}>Key
                        - Zuschauen (Scoring) bei einem Game
                    </li>
                </ul>
                <hr/>
            </>
            }
            <ul>
                <li style={{marginBottom: 10, cursor: "pointer"}} onClick={() => window.open("https://support.darthelfer.de", "_blank")}>
                        Supportseite
                </li>
            </ul>
        </div>
        <Sidebar visible={showGameInvitation || showVideoInvitation || showWatchInvitation} position="bottom" showCloseIcon={false} onHide={() => closeSidebar()} style={{height:300}}>
            <div style={{display: "flex"}}>
                <div style={{marginRight:20}}><Button label="Schliessen" onClick={() => {
                    closeSidebar();
                }}/></div>
                {showGameInvitation && <StartseiteGameScoringInvitation/>}
                {showVideoInvitation && <StartseiteVideoInvitation/>}
                {showWatchInvitation && <StartseiteGameWatchInvitation/>}
            </div>
        </Sidebar>
    </div>
}
