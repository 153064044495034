import React, {useEffect, useState} from 'react'
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import LigaLigenUebersicht from "./LigaLigenUebersicht";


export default function LigaLigenComp({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
    }, []);

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div>
            <LigaLigenUebersicht eventOperatorId={eventOperatorId} onError={(message) => setErrorMessage(message)}/>
        </div>
    </div>
}
