import React, {useEffect, useState} from 'react'
import DHDialog from "../../general/DHDialog";
import DHButtonSave from "../../general/DHButtonSave";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHTextField from "../../general/DHTextField";
import DHSwitch from "../../general/DHSwitch";
import DHButtonNew from "../../general/DHButtonNew";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import {TableContainer} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DoneIcon from "@mui/icons-material/Done";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDialog from "../../general/ConfirmDialog";

export default function LigaZeitraumVerwaltenDialog({eventOperatorId, onRefresh, onClose}) {

    const CELL_PADDING_VALUE = 10;

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [working, setWorking] = useState(false);

    const [id, setId] = useState(undefined);
    const [bezeichnung, setBezeichnung] = useState("");
    const [aktiv, setAktiv] = useState(true);

    const [selectedZeitraum, setSelectedZeitraum] = useState(undefined);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [filter, setFilter] = useState("");
    const [zeitraeume, setZeitraeume] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/zeitraeume/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setZeitraeume(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const deleteZeitraum = () => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.league + "/zeitraum/delete/" + selectedZeitraum.id;
        FetchUtil.fetchDelete(url,
            {},
            json => {
                if( json ) {
                    setErrorMessage(json);
                    setSelectedZeitraum(undefined);
                    setShowDeleteDialog(false);
                    onRefresh();
                } else {
                    setInfoMessage("Löschen war erfolgreich...");
                    setSelectedZeitraum(undefined);
                    setShowDeleteDialog(false);
                    ladeDaten();
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedZeitraum(undefined);
                setShowDeleteDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setSelectedZeitraum(undefined);
                setShowDeleteDialog(false);
            });
    }

    const save = () => {
        setWorking(true);
        let data = {id: id, bezeichnung: bezeichnung, aktiv: true}
        let url = ConfigUtil.getConfig().resourceUrls.league + "/zeitraum/save/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            data,
            json => {
                setId(json.id);
                setInfoMessage("Speichern erfolgreich...");
                onRefresh();
                setWorking(false);
                ladeDaten();
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setWorking(false);
            });
    }

    const initDialog = () => {
        setId(undefined);
        setBezeichnung("");
        setAktiv(true);
    }

    const isDisabled = () => {
        return bezeichnung === "";
    }

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        let filteredZeitraeume;
        if( filter.length > 0 ) {
            filteredZeitraeume = zeitraeume.filter(tmp => tmp.bezeichnung.toUpperCase().includes(filter.toUpperCase()));
        } else {
            filteredZeitraeume = zeitraeume;
        }

        filteredZeitraeume.forEach(zeitraum => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={zeitraum.id}>
                <TableCell style={style}>{zeitraum.bezeichnung}</TableCell>
                <TableCell style={style}>{zeitraum.aktiv ? <DoneIcon/> : <BlockIcon/>}</TableCell>
                <TableCell style={style}>
                    <EditIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setId(zeitraum.id);
                        setBezeichnung(zeitraum.bezeichnung);
                        setAktiv(zeitraum.aktiv);
                    }}/>
                    <DeleteIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setSelectedZeitraum(zeitraum);
                        setShowDeleteDialog(true);
                    }}/>
                </TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    const getTable = () => {
        return <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
            <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Bezeichnung</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 50,}}>Aktiv</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 100,}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows()}
                </TableBody>
            </Table>
        </TableContainer>
    }


    const getDataArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <div style={{marginBottom: 20}}>
                Eine Liga wird in einem Zeitraum gespielt. z.B. 2025 - 1. Halbjahr oder 2025 oder 2026/2027.<br/>
                Sobald du den Zeitraum auf AKTIV setzt, wird dieser im öffentlichen Bereich angezeigt. Solange du somit an einer Liga planst, wäre hier auf jeden Fall nicht aktiv auszuwählen.
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-region-bezeichnung" label="Bezeichnung" width={500} required={true} value={bezeichnung} onChange={(text) => setBezeichnung(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHSwitch id="id-aktiv" label="Aktiv" value={aktiv} onChange={(value) => setAktiv(value)} required={false}/>
            </div>
            <div>
                <DHButtonSave onClick={() => save()} working={working} disabled={isDisabled()}/>,
                <DHButtonNew onClick={() => initDialog()}/>
            </div>
            <hr/>
            <div style={{paddingTop: 20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
                <DHTextFieldSearch id="id-filter" onChange={(text) => setFilter(text)} value={filter} width={100} label="Filter"/>
            </div>

            {getTable()}

            {showDeleteDialog && <ConfirmDialog visible={true} header={"Spielort löschen"} textOben={<div>
                <div style={{color: "red", marginBottom: 20}}>Du kannst den Zeitraum nur löschen, sobald dieser noch keiner Liga zugeordnet wurde.</div>
                <div>Möchtest du den Zeitraum wirklich löschen?</div>
            </div>} callBackOnNein={() => {
                setShowDeleteDialog(false);
                setSelectedZeitraum(undefined);
            }} callBackOnJa={() => deleteZeitraum()}/>}
        </div>
    }

    return <DHDialog onClose={() => onClose()} title="Zeiträume verwalten" show={true} showCloseButton={true}
                     buttons={[]} comp={getDataArea()}/>
}
