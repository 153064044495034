import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Fieldset} from "primereact/fieldset";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import {PrimeIcons} from "primereact/api";
import ConfirmDialog from "../../../general/ConfirmDialog";
import ErgebnisDialog from "./ErgebnisDialog";
import {InputNumber} from "primereact/inputnumber";
import BoardAufrufRow from "./BoardAufrufRow";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";

let stompClient = undefined;

export default function BoardAufrufArea({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [minutesToError, setMinutesToError] = useState(20);
    const [boards, setBoards] = useState([]);
    const [boardsMitGame, setBoardsMitGame] = useState([]);
    const [selectedBoardGameId, setSelectedBoardGameId] = useState(undefined);
    const [selectedBoardGame, setSelectedBoardGame] = useState(undefined);

    const [showAufrufDialog, setShowAufrufDialog] = useState(false);
    const [showErgebnisDialog, setShowErgebnisDialog] = useState(false);

    const [showHinweis, setShowHinweis] = useState(false);

    useEffect(() => {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                console.log("Websocket - connect success");
                const destination = "/topic/public/eventoperator/board/infos/" + eventOperatorId;
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, (payload) => {
                    const body = JSON.parse(payload.body);
                    setBoards(body.boards);
                    setBoardsMitGame(body.boardsMitGame);
                });
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });

        return () => {
            if( stompClient !== null && stompClient !== undefined) {
                console.log("Websocket - Chat - disconnect:");
                stompClient.disconnect(() => {});
            }
        };
    }, []);

    const getBoardMitGame = (boardId) => {
        for (const boardGame of boardsMitGame) {
            if( boardId === boardGame.boardId) {
                return boardGame;
            }
        }
        return undefined;
    }

    const boardAufrufen = () => {
        setErrorMessage(undefined)
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/boardaufrufen/" + selectedBoardGameId,
            {},
            json => {
                setInfoMessage("Game wurde als aufgerufen markiert.");
                setShowAufrufDialog(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setShowAufrufDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setShowAufrufDialog(false);
            }
        );
    }

    const getBoardRows = (boardListe) => {
        if( boardListe.length === 0) {
            return <div style={{marginTop:10, marginBottom:20}}>Keine Boards vorhanden.</div>
        }
        let rows = [];
        for (const board of boardListe) {
            let existierentesBoardGameZurBoardId = getBoardMitGame(board.id);
            rows.push(<><div className="grid" key={"key_board_" + board.id} style={{fontSize:16, marginBottom:5}}>
                <div className="col-fixed" style={{width:250}}>Bezeichnung: '{board.bezeichnung}'</div>
                <div className="col">
                    {existierentesBoardGameZurBoardId !== undefined &&
                        <span>Turnier: {existierentesBoardGameZurBoardId.tournamentBezeichnung} /
                            Spiel: {existierentesBoardGameZurBoardId.gameDescription}
                            {existierentesBoardGameZurBoardId.schreiberName && <span style={{marginLeft:10}}>/ Schreiber: {existierentesBoardGameZurBoardId.schreiberName}</span>}
                            {existierentesBoardGameZurBoardId.tournamentGroup && <span style={{marginLeft:10}}>/ Gruppe: {existierentesBoardGameZurBoardId.tournamentGroup}</span>}
                            <i className={PrimeIcons.PENCIL} style={{marginLeft:20, fontSize:20, cursor:"pointer"}} onClick={() => {
                                setSelectedBoardGame(existierentesBoardGameZurBoardId);
                                setShowErgebnisDialog(true);}}
                            />
                        </span>
                    }
                </div>
                <div className="col-fixed" style={{width:250}}>
                    {existierentesBoardGameZurBoardId !== undefined && <>
                        {existierentesBoardGameZurBoardId.aufgerufen &&  <span style={{color:"green"}}>Bereits aufgerufen</span>}
                        <BoardAufrufRow label="Aufruf 1" timestamp={existierentesBoardGameZurBoardId.aufgerufenZeitpunkt1} minutesToError={minutesToError} />
                        <BoardAufrufRow label="Aufruf 2" timestamp={existierentesBoardGameZurBoardId.aufgerufenZeitpunkt2} minutesToError={minutesToError} />
                        <BoardAufrufRow label="Aufruf 3" timestamp={existierentesBoardGameZurBoardId.aufgerufenZeitpunkt3} minutesToError={minutesToError} />
                    </>
                    }
                </div>
                <div className="col-fixed" style={{width:50}}>
                    {existierentesBoardGameZurBoardId !== undefined &&
                        <i className={PrimeIcons.VOLUME_DOWN} style={{fontSize:40, cursor:"pointer"}} onClick={() => {
                            setSelectedBoardGameId(existierentesBoardGameZurBoardId.id);
                            setShowAufrufDialog(true);}}
                        />
                    }
                </div>
            </div>
                <hr/>
            </>);
        }
        return rows;
    }

    const getBoardsNichtAufgerufen = () => {
        if (boards === null || boards === undefined || boards.length === 0) {
            return [];
        }
        let returnArray = [];
        for (const board of boards) {
            let existierentesBoardGameZurBoardId = getBoardMitGame(board.id);
            if( existierentesBoardGameZurBoardId === undefined) {
                continue;
            }
            if (existierentesBoardGameZurBoardId.aufgerufen === null ||
                existierentesBoardGameZurBoardId.aufgerufen === undefined ||
                !existierentesBoardGameZurBoardId.aufgerufen) {
                returnArray.push(board);
            }
        }
        return returnArray;
    }

    const getBoardsAufgerufen = () => {
        if (boards === null || boards === undefined || boards.length === 0) {
            return [];
        }
        let returnArray = [];
        for (const board of boards) {
            let existierentesBoardGameZurBoardId = getBoardMitGame(board.id);
            if( existierentesBoardGameZurBoardId === undefined) {
                continue;
            }
            if (existierentesBoardGameZurBoardId.aufgerufen !== null &&
                existierentesBoardGameZurBoardId.aufgerufen !== undefined &&
                existierentesBoardGameZurBoardId.aufgerufen) {
                returnArray.push(board);
            }
        }
        return returnArray;
    }

    const getBoardsOhneSpiel = () => {
        if (boards === null || boards === undefined || boards.length === 0) {
            return [];
        }
        let returnArray = [];
        for (const board of boards) {
            let existierentesBoardGameZurBoardId = getBoardMitGame(board.id);
            if( existierentesBoardGameZurBoardId === undefined) {
                returnArray.push(board);
            }
        }
        return returnArray;
    }

    const headerRow = (text) => {
        return <div style={{backgroundColor:"gray", fontSize:20, marginBottom:10, padding:10, color:"white"}}>{text}</div>
    }

    return <>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <Fieldset legend="Hinweis" toggleable collapsed={!showHinweis} onToggle={(e) => setShowHinweis(!showHinweis)} style={{marginBottom:10}}>
            <div style={{fontSize:16}}>Hier kannst du Turnierübergreifend die aktuelle Spiele an den Boards sehen und diese als aufgerufen markieren.<br/>
                Die Markierung als aufgerufen kann bis zu 3 mal erfolgen.<br/>
                Sobald an einem Board ein Aufruf erfolgt ist und nach {minutesToError} Minuten kein Ergebnis eintragen wurde, wird dieser Aufruf rot markiert.
                <br/><br/>
                Hier kannst du die Minuten einstellen, ab wann ein Aufruf ohne Ergebnis rot werden soll:<br/>
                <InputNumber value={minutesToError} onValueChange={(e) => setMinutesToError(e.value)} min={1}/>
            </div>
        </Fieldset>
        {headerRow("Boards die noch nicht aufgerufen wurden")}
        {getBoardRows(getBoardsNichtAufgerufen())}
        {headerRow("Boards die bereits aufgerufen wurden")}
        {getBoardRows(getBoardsAufgerufen())}
        {headerRow("Boards ohne Spiel")}
        {getBoardRows(getBoardsOhneSpiel())}
        {showErgebnisDialog &&
            <ErgebnisDialog onClose={() => {
                setSelectedBoardGame(undefined);
                setShowErgebnisDialog(false);
            }
            } boardGame={selectedBoardGame} onError={(message) => {
                setErrorMessage(message);
                setShowErgebnisDialog(false);
                setSelectedBoardGame(undefined);
            }} onSuccess={() => {
                setShowErgebnisDialog(false);
                setSelectedBoardGame(undefined);
            }}/>
        }
        {showAufrufDialog &&
            <ConfirmDialog header="Aufrufen...?" textUnten="Board als aufgerufen markieren?" visible={true}
                           callBackOnNein={() => setShowAufrufDialog(false)} callBackOnJa={() => boardAufrufen()}/>}

    </>
}
