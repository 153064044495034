import DHTextFieldSearch from "../../general/DHTextFieldSearch";
import {Button} from "primereact/button";
import React, {useState} from "react";


export default function LigaTeilnehmerFilterArea({isAdmin, filterText, filterBezahlt, filterAnwesend, filterWarteliste, onFilterText, onFilterBezahlt, onFilterAnwesend, onFilterWarteliste}) {

    const [showFilterDescription, setShowFilterDescription] = useState(false);


    return <div style={{padding:10}}>
        <div style={{display: "flex", textAlign: "left", alignItems: "center"}}>
                <span style={{marginRight: 10}}>
                    <DHTextFieldSearch id={"id-search-teilnehmer"} value={filterText} onChange={(text) => onFilterText(text)} width={100}
                                       label="Filter"/>
                </span>
            {isAdmin && <>
                {filterBezahlt === undefined && <>
                    <img src="/images/euro_durchgestrichen.png"
                         style={{width: 40, height: 40, cursor: "pointer", marginRight: 10, marginTop: 5}} alt="Filter"
                         onClick={() => onFilterBezahlt("ja")}/>
                    {showFilterDescription &&
                        <span style={{marginRight:10}} onClick={() => onFilterBezahlt("ja")}>Kein Filter</span>
                    }
                </>
                }
                {filterBezahlt === "ja" && <>
                    <img src="/images/euro_green.png"
                         style={{width: 40, height: 40, cursor: "pointer", marginRight: 10, marginTop: 5}} alt="Filter"
                         onClick={() => onFilterBezahlt("nein")}/>
                    {showFilterDescription &&
                        <span style={{marginRight:10}} onClick={() => onFilterBezahlt("nein")}>Nur bezahlte</span>
                    }
                </>
                }
                {filterBezahlt === "nein" && <>
                    <img src="/images/euro_black.png"
                         style={{width: 40, height: 40, cursor: "pointer", marginRight: 10, marginTop: 5}} alt="Filter"
                         onClick={() => onFilterBezahlt(undefined)}/>
                    {showFilterDescription &&
                        <span style={{marginRight:10}} onClick={() => onFilterBezahlt(undefined)}>nur nicht bezahlte</span>
                    }
                </>
                }
                {filterAnwesend === undefined && <>
                    <img src="/images/anwesend_filter.png"
                         style={{width: 30, height: 30, cursor: "pointer", marginRight: 10, marginTop: 5}} alt="Filter"
                         onClick={() => onFilterAnwesend("ja")}/>
                    {showFilterDescription &&
                        <span style={{marginRight:10}} onClick={() => onFilterAnwesend("ja")}>Kein Filter</span>
                    }
                </>
                }
                {filterAnwesend === "ja" && <>
                    <img src="/images/anwesend_color.png"
                         style={{width: 30, height: 30, cursor: "pointer", marginRight: 10, marginTop: 5}} alt="Filter"
                         onClick={() => onFilterAnwesend("nein")}/>
                    {showFilterDescription &&
                        <span style={{marginRight:10}} onClick={() => onFilterAnwesend("nein")}>Nur anwesend</span>
                    }
                </>
                }
                {filterAnwesend === "nein" && <>
                    <img src="/images/anwesend_black.png"
                         style={{width: 30, height: 30, cursor: "pointer", marginRight: 10, marginTop: 5}} alt="Filter"
                         onClick={() => onFilterAnwesend(undefined)}/>
                    {showFilterDescription &&
                        <span style={{marginRight:10}} onClick={() => onFilterAnwesend(undefined)}>nur nicht anwesend</span>
                    }
                </>
                }
                <img src={filterWarteliste ? "/images/warteliste.png" : "/images/warteliste_nein.png"}
                     style={{width: 30, height: 30, cursor: "pointer", marginRight:10, marginTop: 5}} alt="Filter"
                     onClick={() => onFilterWarteliste(!filterWarteliste)}/>
                {showFilterDescription &&
                    <span style={{marginRight:10}} onClick={() => onFilterWarteliste(!filterWarteliste)}>{filterWarteliste ? "Filter Warteliste aktiv" : "Filter Warteliste NICHT aktiv"}</span>
                }
            </>
            }
            <Button label="Filter zurücksetzen" className="p-button-link" onClick={() => {
                onFilterText("");
                onFilterBezahlt(undefined);
                onFilterWarteliste(undefined);
                onFilterAnwesend(undefined);
            }
            } style={{padding: 0, margin: 0, marginLeft: 20}}/>
            <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
               onClick={(e) => setShowFilterDescription(!showFilterDescription)}/>

        </div>
    </div>

}