import React, {useEffect} from "react";

export default function DHCarousel({ model, interval, index, onIndexChange}) {

    useEffect(() => {
        const intervalId = setInterval(() => {
            let tmpModel = model.filter( tmp => tmp.index === index + 1)[0];
            if( tmpModel ) {
                onIndexChange(index + 1);
            } else {
                onIndexChange(0);
            }
        }, interval);

        return () => {
            clearInterval(intervalId);
        };
    }, [interval, index, model]);

    return <>{model.filter( tmp => tmp.index === index)[0].value}</>
}