import React, {useState} from 'react'
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import PropTypes from "prop-types";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {TOURNAMENTMODUS} from "../../../constants/tournamentModus";
import KODKOX01VerwaltenArea from "./verwalten/KODKOX01VerwaltenArea";
import KODKOCricketVerwaltenArea from "./verwalten/KODKOCricketVerwaltenArea";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";
import DHSelect from "../../general/DHSelect";
import DHSwitch from "../../general/DHSwitch";
import DHButtonSave from "../../general/DHButtonSave";
import DHButtonIcon from "../../general/DHButtonIcon";
import DHIconAdd from "../../general/DHIconAdd";
import DHIconMinus from "../../general/DHIconMinus";
import DHRadioGroup from "../../general/DHRadioGroup";
import DHTextField from "../../general/DHTextField";

function TurnierBronzeStartenDialog({gameType, tournamentid, callBackRefresh, callBackCloseDialog}) {

    const [auslosungLaeuft, setAuslosungLaeuft] = useState(false);

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [finaleVerliererseiteMussZweiSpieleGewinnen, setFinaleVerliererseiteMussZweiSpieleGewinnen] = useState(false);
    let [spielUmPlatz3, setSpielUmPlatz3] = useState(false);
    let [bezeichnung, setBezeichnung] = useState("Bronze");

    let [gewinnerseiteSets, setGewinnerseiteSets] = useState(undefined);
    let [gewinnerseiteFirstToLegs, setGewinnerseiteFirstToLegs] = useState(undefined);
    let [gewinnerseiteModusId, setGewinnerseiteModusId] = useState(undefined);
    let [gewinnerseiteStartpunkte, setGewinnerseiteStartpunkte] = useState(undefined);
    let [gewinnerseiteZufallszahlen, setGewinnerseiteZufallszahlen] = useState(undefined);
    let [gewinnerseiteZufallszahlenJedesLeg, setGewinnerseiteZufallszahlenJedesLeg] = useState(undefined);
    let [gewinnerseiteCutthroat, setGewinnerseiteCutthroat] = useState(undefined);

    let [verliererseiteSets, setVerliererseiteSets] = useState(undefined);
    let [verliererseiteFirstToLegs, setVerliererseiteFirstToLegs] = useState(undefined);
    let [verliererseiteModusId, setVerliererseiteModusId] = useState(undefined);
    let [verliererseiteStartpunkte, setVerliererseiteStartpunkte] = useState(undefined);
    let [verliererseiteZufallszahlen, setVerliererseiteZufallszahlen] = useState(undefined);
    let [verliererseiteZufallszahlenJedesLeg, setVerliererseiteZufallszahlenJedesLeg] = useState(undefined);
    let [verliererseiteCutthroat, setVerliererseiteCutthroat] = useState(undefined);

    let [achtelfinaleSets, setAchtelfinaleSets] = useState(undefined);
    let [achtelfinaleFirstToLegs, setAchtelfinaleFirstToLegs] = useState(undefined);
    let [achtelfinaleModusId, setAchtelfinaleModusId] = useState(undefined);
    let [achtelfinaleStartpunkte, setAchtelfinaleStartpunkte] = useState(undefined);
    let [achtelfinaleZufallszahlen, setAchtelfinaleZufallszahlen] = useState(undefined);
    let [achtelfinaleZufallszahlenJedesLeg, setAchtelfinaleZufallszahlenJedesLeg] = useState(undefined);
    let [achtelfinaleCutthroat, setAchtelfinaleCutthroat] = useState(undefined);

    let [viertelfinaleSets, setViertelfinaleSets] = useState(undefined);
    let [viertelfinaleFirstToLegs, setViertelfinaleFirstToLegs] = useState(undefined);
    let [viertelfinaleModusId, setViertelfinaleModusId] = useState(undefined);
    let [viertelfinaleStartpunkte, setViertelfinaleStartpunkte] = useState(undefined);
    let [viertelfinaleZufallszahlen, setViertelfinaleZufallszahlen] = useState(undefined);
    let [viertelfinaleZufallszahlenJedesLeg, setViertelfinaleZufallszahlenJedesLeg] = useState(undefined);
    let [viertelfinaleCutthroat, setViertelfinaleCutthroat] = useState(undefined);

    let [halbfinaleSets, setHalbfinaleSets] = useState(undefined);
    let [halbfinaleFirstToLegs, setHalbfinaleFirstToLegs] = useState(undefined);
    let [halbfinaleModusId, setHalbfinaleModusId] = useState(undefined);
    let [halbfinaleStartpunkte, setHalbfinaleStartpunkte] = useState(undefined);
    let [halbfinaleZufallszahlen, setHalbfinaleZufallszahlen] = useState(undefined);
    let [halbfinaleZufallszahlenJedesLeg, setHalbfinaleZufallszahlenJedesLeg] = useState(undefined);
    let [halbfinaleCutthroat, setHalbfinaleCutthroat] = useState(undefined);

    let [spielUmPlatz3Sets, setSpielUmPlatz3Sets] = useState(undefined);
    let [spielUmPlatz3FirstToLegs, setSpielUmPlatz3FirstToLegs] = useState(undefined);
    let [spielUmPlatz3ModusId, setSpielUmPlatz3ModusId] = useState(undefined);
    let [spielUmPlatz3Startpunkte, setSpielUmPlatz3Startpunkte] = useState(undefined);
    let [spielUmPlatz3Zufallszahlen, setSpielUmPlatz3Zufallszahlen] = useState(undefined);
    let [spielUmPlatz3ZufallszahlenJedesLeg, setSpielUmPlatz3ZufallszahlenJedesLeg] = useState(undefined);
    let [spielUmPlatz3Cutthroat, setSpielUmPlatz3Cutthroat] = useState(undefined);

    let [finaleSets, setFinaleSets] = useState(undefined);
    let [finaleFirstToLegs, setFinaleFirstToLegs] = useState(undefined);
    let [finaleModusId, setFinaleModusId] = useState(undefined);
    let [finaleStartpunkte, setFinaleStartpunkte] = useState(undefined);
    let [finaleZufallszahlen, setFinaleZufallszahlen] = useState(undefined);
    let [finaleZufallszahlenJedesLeg, setFinaleZufallszahlenJedesLeg] = useState(undefined);
    let [finaleCutthroat, setFinaleCutthroat] = useState(undefined);

    let [showAchtelfinale, setShowAchtelfinale] = useState(false);
    let [showViertelfinale, setShowViertelfinale] = useState(false);
    let [showHalbfinale, setShowHalbfinale] = useState(false);
    let [showSpielUmPlatz3, setShowSpielUmPlatz3] = useState(false);
    let [showFinale, setShowFinale] = useState(false);

    let [tournamentSystem, setTournamentSystem] = useState(undefined);

    let [werKommtWeiter, setWerKommtWeiter] = useState(undefined);
    let [selectedPlatzierung, setSelectedPlatzierung] = useState(undefined);
    let [platzierungen, setPlatzierungen] = useState([]);

    function turnierAnlegen() {
        setErrorMessage(undefined);
        setAuslosungLaeuft(true);
        if( bezeichnung.length === 0 || bezeichnung.length > 12) {
            setErrorMessage("Bezeichnung muss gefüllt und maximal 12stellig sein.");
            return;
        }
        let isDKOModus = tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id;
        let modusArray = [];
        modusArray.push({
            modusTyp: TOURNAMENTMODUS.GEWINNERSEITE.id,
            gameType: gameType,
            modusid: gewinnerseiteModusId,
            sets: gewinnerseiteSets,
            firstToLegs: gewinnerseiteFirstToLegs,
            bestOfLegs: undefined,
            startpunkte: gewinnerseiteStartpunkte,
            zufallszahlen: gewinnerseiteZufallszahlen,
            zufallszahlenjedesleg: gewinnerseiteZufallszahlenJedesLeg,
            cutthroat: gewinnerseiteCutthroat
        });
        if (isDKOModus && (verliererseiteModusId !== undefined || verliererseiteSets !== undefined || verliererseiteFirstToLegs !== undefined || verliererseiteStartpunkte)) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.VERLIERERSEITE.id,
                gameType: gameType,
                modusid: verliererseiteModusId,
                sets: verliererseiteSets,
                firstToLegs: verliererseiteFirstToLegs,
                bestOfLegs: undefined,
                startpunkte: verliererseiteStartpunkte,
                zufallszahlen: verliererseiteZufallszahlen,
                zufallszahlenjedesleg: verliererseiteZufallszahlenJedesLeg,
                cutthroat: verliererseiteCutthroat
            });
        }
        if (showAchtelfinale && (achtelfinaleModusId !== undefined || achtelfinaleSets !== undefined || achtelfinaleFirstToLegs !== undefined || achtelfinaleStartpunkte)) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.ACHTELFINALE.id,
                gameType: gameType,
                modusid: achtelfinaleModusId,
                sets: achtelfinaleSets,
                firstToLegs: achtelfinaleFirstToLegs,
                bestOfLegs: undefined,
                startpunkte: achtelfinaleStartpunkte,
                zufallszahlen: achtelfinaleZufallszahlen,
                zufallszahlenjedesleg: achtelfinaleZufallszahlenJedesLeg,
                cutthroat: achtelfinaleCutthroat
            });
        }
        if (showViertelfinale && (viertelfinaleModusId !== undefined || viertelfinaleSets !== undefined || viertelfinaleFirstToLegs !== undefined || viertelfinaleStartpunkte)) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.VIERTELFINALE.id,
                gameType: gameType,
                modusid: viertelfinaleModusId,
                sets: viertelfinaleSets,
                firstToLegs: viertelfinaleFirstToLegs,
                bestOfLegs: undefined,
                startpunkte: viertelfinaleStartpunkte,
                zufallszahlen: viertelfinaleZufallszahlen,
                zufallszahlenjedesleg: viertelfinaleZufallszahlenJedesLeg,
                cutthroat: viertelfinaleCutthroat
            });
        }
        if (showHalbfinale && (halbfinaleModusId !== undefined || halbfinaleSets !== undefined || halbfinaleFirstToLegs !== undefined || halbfinaleStartpunkte)) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.HALBFINALE.id,
                gameType: gameType,
                modusid: halbfinaleModusId,
                sets: halbfinaleSets,
                firstToLegs: halbfinaleFirstToLegs,
                bestOfLegs: undefined,
                startpunkte: halbfinaleStartpunkte,
                zufallszahlen: halbfinaleZufallszahlen,
                zufallszahlenjedesleg: halbfinaleZufallszahlenJedesLeg,
                cutthroat: halbfinaleCutthroat
            });
        }
        if (showSpielUmPlatz3 && (spielUmPlatz3ModusId !== undefined || spielUmPlatz3Sets !== undefined || spielUmPlatz3FirstToLegs !== undefined || spielUmPlatz3Startpunkte)) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.SPIELUMPLATZDREI.id,
                gameType: gameType,
                modusid: spielUmPlatz3ModusId,
                sets: spielUmPlatz3Sets,
                firstToLegs: spielUmPlatz3FirstToLegs,
                bestOfLegs: undefined,
                startpunkte: spielUmPlatz3Startpunkte,
                zufallszahlen: spielUmPlatz3Zufallszahlen,
                zufallszahlenjedesleg: spielUmPlatz3ZufallszahlenJedesLeg,
                cutthroat: spielUmPlatz3Cutthroat
            });
        }
        if (showFinale && (finaleModusId !== undefined || finaleSets !== undefined || finaleFirstToLegs !== undefined || finaleStartpunkte)) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.FINALE.id,
                gameType: gameType,
                modusid: finaleModusId,
                sets: finaleSets,
                firstToLegs: finaleFirstToLegs,
                bestOfLegs: undefined,
                startpunkte: finaleStartpunkte,
                zufallszahlen: finaleZufallszahlen,
                zufallszahlenjedesleg: finaleZufallszahlenJedesLeg,
                cutthroat: finaleCutthroat
            });
        }

        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/createBronzeTurnier";
        let data = {
            turnierid: tournamentid,
            bezeichnung: bezeichnung,
            tournamentModusEntities: modusArray,
            finaleVerliererseiteMussZweiSpieleGewinnen: finaleVerliererseiteMussZweiSpieleGewinnen,
            spielUmPlatz3: spielUmPlatz3,
            tournamentSystem: tournamentSystem,
            werKommtWeiter: werKommtWeiter,
            platzierungen: platzierungen,
        };
        FetchUtil.fetchPost(url,
            data,
            json => {
                setInfoMessage("Bronze-Turnier wurde erfolgreich angelegt.");
                callBackRefresh();
                callBackCloseDialog();
                setAuslosungLaeuft(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
                setAuslosungLaeuft(false);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
                setAuslosungLaeuft(false);
            });
    }

    function showSaveButton() {
        if( werKommtWeiter === undefined) {
            return false;
        }
        if( werKommtWeiter === "platzierung" && platzierungen.length === 0) {
            return false;
        }
        if (gameType === "x01") {
            if (tournamentSystem === TOURNAMENTSYSTEMS.EINFACHERKO.id) {
                return gewinnerseiteFirstToLegs && gewinnerseiteModusId && gewinnerseiteStartpunkte;
            } else if (tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id) {
                let gewinnerFelder = gewinnerseiteFirstToLegs && gewinnerseiteModusId && gewinnerseiteStartpunkte;
                let verliererFelder = verliererseiteFirstToLegs && verliererseiteModusId && verliererseiteStartpunkte;
                return gewinnerFelder && verliererFelder;
            } else {
                return false;
            }
        } else if (gameType === "cricket") {
            if (tournamentSystem === TOURNAMENTSYSTEMS.EINFACHERKO.id) {
                return gewinnerseiteFirstToLegs && gewinnerseiteZufallszahlen !== undefined && gewinnerseiteCutthroat !== undefined;
            } else if (tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id) {
                let gewinnerFelder = gewinnerseiteFirstToLegs && gewinnerseiteZufallszahlen !== undefined && gewinnerseiteCutthroat !== undefined;
                let verliererFelder = verliererseiteFirstToLegs && verliererseiteZufallszahlen !== undefined && verliererseiteCutthroat !== undefined;
                return gewinnerFelder && verliererFelder;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    function getX01Area() {
        return <KODKOX01VerwaltenArea
            tournamentSystem={tournamentSystem}
            showAchtelfinale={showAchtelfinale} showViertelfinale={showViertelfinale} showHalbfinale={showHalbfinale} showSpielUmPlatz3={showSpielUmPlatz3} showFinale={showFinale}
            achtelfinaleModusId={achtelfinaleModusId} halbfinaleStartpunkte={halbfinaleStartpunkte}
            halbfinaleFirstToLegs={halbfinaleFirstToLegs} finaleStartpunkte={finaleStartpunkte} verliererseiteModusId={verliererseiteModusId}
            finaleFirstToLegs={finaleFirstToLegs} gewinnerseiteFirstToLegs={gewinnerseiteFirstToLegs} achtelfinaleSets={achtelfinaleSets}
            gewinnerseiteStartpunkte={gewinnerseiteStartpunkte} achtelfinaleFirstToLegs={achtelfinaleFirstToLegs} gewinnerseiteModusId={gewinnerseiteModusId}
            achtelfinaleStartpunkte={achtelfinaleStartpunkte} verliererseiteSets={verliererseiteSets} halbfinaleModusId={halbfinaleModusId}
            halbfinaleSets={halbfinaleSets} verliererseiteFirstToLegs={verliererseiteFirstToLegs} verliererseiteStartpunkte={verliererseiteStartpunkte}
            spielUmPlatz3Sets={spielUmPlatz3Sets} spielUmPlatz3FirstToLegs={spielUmPlatz3FirstToLegs} spielUmPlatz3ModusId={spielUmPlatz3ModusId} spielUmPlatz3Startpunkte={spielUmPlatz3Startpunkte}
            viertelfinaleSets={viertelfinaleSets} viertelfinaleFirstToLegs={viertelfinaleFirstToLegs} gewinnerseiteSets={gewinnerseiteSets}
            finaleModusId={finaleModusId} finaleSets={finaleSets} viertelfinaleModusId={viertelfinaleModusId}
            viertelfinaleStartpunkte={viertelfinaleStartpunkte}
            setGewinnerseiteSets={setGewinnerseiteSets} setGewinnerseiteFirstToLegs={setGewinnerseiteFirstToLegs} setGewinnerseiteModusId={setGewinnerseiteModusId} setGewinnerseiteStartpunkte={setGewinnerseiteStartpunkte}
            setVerliererseiteSets={setVerliererseiteSets} setVerliererseiteFirstToLegs={setVerliererseiteFirstToLegs} setVerliererseiteModusId={setVerliererseiteModusId} setVerliererseiteStartpunkte={setVerliererseiteStartpunkte}
            setAchtelfinaleSets={setAchtelfinaleSets} setAchtelfinaleFirstToLegs={setAchtelfinaleFirstToLegs} setAchtelfinaleModusId={setAchtelfinaleModusId} setAchtelfinaleStartpunkte={setAchtelfinaleStartpunkte}
            setViertelfinaleSets={setViertelfinaleSets} setViertelfinaleFirstToLegs={setViertelfinaleFirstToLegs} setViertelfinaleModusId={setViertelfinaleModusId} setViertelfinaleStartpunkte={setViertelfinaleStartpunkte}
            setHalbfinaleSets={setHalbfinaleSets} setHalbfinaleFirstToLegs={setHalbfinaleFirstToLegs} setHalbfinaleModusId={setHalbfinaleModusId} setHalbfinaleStartpunkte={setHalbfinaleStartpunkte}
            setSpielUmPlatz3Sets={setSpielUmPlatz3Sets} setSpielUmPlatz3FirstToLegs={setSpielUmPlatz3FirstToLegs} setSpielUmPlatz3ModusId={setSpielUmPlatz3ModusId} setSpielUmPlatz3Startpunkte={setSpielUmPlatz3Startpunkte}
            setFinaleSets={setFinaleSets} setFinaleFirstToLegs={setFinaleFirstToLegs} setFinaleModusId={setFinaleModusId} setFinaleStartpunkte={setFinaleStartpunkte}
            setShowAchtelfinale={setShowAchtelfinale} setShowViertelfinale={setShowViertelfinale} setShowHalbfinale={setShowHalbfinale} setShowSpielUmPlatz3={setShowSpielUmPlatz3} setShowFinale={setShowFinale}
        />
    }

    function getCricketArea() {
        return <KODKOCricketVerwaltenArea
            tournamentSystem={tournamentSystem}
            showAchtelfinale={showAchtelfinale} showViertelfinale={showViertelfinale} showHalbfinale={showHalbfinale} showFinale={showFinale}
            gewinnerseiteSets={gewinnerseiteSets} gewinnerseiteFirstToLegs={gewinnerseiteFirstToLegs} gewinnerseiteZufallszahlen={gewinnerseiteZufallszahlen} gewinnerseiteZufallszahlenJedesLeg={gewinnerseiteZufallszahlenJedesLeg} gewinnerseiteCutthroat={gewinnerseiteCutthroat}
            verliererseiteSets={verliererseiteSets} verliererseiteFirstToLegs={verliererseiteFirstToLegs} verliererseiteZufallszahlen={verliererseiteZufallszahlen} verliererseiteZufallszahlenJedesLeg={verliererseiteZufallszahlenJedesLeg} verliererseiteCutthroat={verliererseiteCutthroat}
            achtelfinaleSets={achtelfinaleSets} achtelfinaleFirstToLegs={achtelfinaleFirstToLegs} achtelfinaleZufallszahlen={achtelfinaleZufallszahlen} achtelfinaleZufallszahlenJedesLeg={achtelfinaleZufallszahlenJedesLeg} achtelfinaleCutthroat={achtelfinaleCutthroat}
            viertelfinaleSets={viertelfinaleSets} viertelfinaleFirstToLegs={viertelfinaleFirstToLegs} viertelfinaleZufallszahlen={viertelfinaleZufallszahlen} viertelfinaleZufallszahlenJedesLeg={viertelfinaleZufallszahlenJedesLeg} viertelfinaleCutthroat={viertelfinaleCutthroat}
            halbfinaleSets={halbfinaleSets} halbfinaleFirstToLegs={halbfinaleFirstToLegs} halbfinaleZufallszahlen={halbfinaleZufallszahlen} halbfinaleZufallszahlenJedesLeg={halbfinaleZufallszahlenJedesLeg} halbfinaleCutthroat={halbfinaleCutthroat}
            finaleSets={finaleSets} finaleFirstToLegs={finaleFirstToLegs} finaleZufallszahlen={finaleZufallszahlen} finaleZufallszahlenJedesLeg={finaleZufallszahlenJedesLeg} finaleCutthroat={finaleCutthroat}
            setGewinnerseiteSets={setGewinnerseiteSets} setGewinnerseiteFirstToLegs={setGewinnerseiteFirstToLegs} setGewinnerseiteZufallszahlen={setGewinnerseiteZufallszahlen} setGewinnerseiteZufallszahlenJedesLeg={setGewinnerseiteZufallszahlenJedesLeg} setGewinnerseiteCutthroat={setGewinnerseiteCutthroat}
            setVerliererseiteSets={setVerliererseiteSets} setVerliererseiteFirstToLegs={setVerliererseiteFirstToLegs} setVerliererseiteZufallszahlen={setVerliererseiteZufallszahlen} setVerliererseiteZufallszahlenJedesLeg={setVerliererseiteZufallszahlenJedesLeg} setVerliererseiteCutthroat={setVerliererseiteCutthroat}
            setAchtelfinaleSets={setAchtelfinaleSets} setAchtelfinaleFirstToLegs={setAchtelfinaleFirstToLegs} setAchtelfinaleZufallszahlen={setAchtelfinaleZufallszahlen} setAchtelfinaleZufallszahlenJedesLeg={setAchtelfinaleZufallszahlenJedesLeg} setAchtelfinaleCutthroat={setAchtelfinaleCutthroat}
            setViertelfinaleSets={setViertelfinaleSets} setViertelfinaleFirstToLegs={setViertelfinaleFirstToLegs} setViertelfinaleZufallszahlen={setViertelfinaleZufallszahlen} setViertelfinaleZufallszahlenJedesLeg={setViertelfinaleZufallszahlenJedesLeg} setViertelfinaleCutthroat={setViertelfinaleCutthroat}
            setHalbfinaleSets={setHalbfinaleSets} setHalbfinaleFirstToLegs={setHalbfinaleFirstToLegs} setHalbfinaleZufallszahlen={setHalbfinaleZufallszahlen} setHalbfinaleZufallszahlenJedesLeg={setHalbfinaleZufallszahlenJedesLeg} setHalbfinaleCutthroat={setHalbfinaleCutthroat}
            setFinaleSets={setFinaleSets} setFinaleFirstToLegs={setFinaleFirstToLegs} setFinaleZufallszahlen={setFinaleZufallszahlen} setFinaleZufallszahlenJedesLeg={setFinaleZufallszahlenJedesLeg} setFinaleCutthroat={setFinaleCutthroat}
            setShowAchtelfinale={setShowAchtelfinale} setShowViertelfinale={setShowViertelfinale} setShowHalbfinale={setShowHalbfinale} setShowFinale={setShowFinale}
        />
    }

    const dialogFooter = (
        <div>
            <DHButtonSave label="Bronze-Turnier starten..."
                          disabled={auslosungLaeuft || !showSaveButton()} working={auslosungLaeuft} onClick={() => turnierAnlegen()}/>
        </div>
    );

    const removePlatzierung = (platzierung) => {
        let tmpPlatzierungen = [];
        for (const platzierungenElement of platzierungen) {
            if( platzierungenElement !== platzierung) {
                tmpPlatzierungen.push(platzierungenElement);
            }
        }
        setPlatzierungen(tmpPlatzierungen);
        setSelectedPlatzierung(undefined);
    }

    const addPlatzeriung = () => {
        let tmpPlatzierungen = [...platzierungen];
        tmpPlatzierungen.push(selectedPlatzierung);
        setPlatzierungen(tmpPlatzierungen);
        setSelectedPlatzierung(undefined);
    }

    const getPlatzierungDropdown = () => {
        let values = [];
        // Gruppen können nur 20 Teilnehmer habe, zur Sicherheit 30
        for( let i = 1; i <= 30; i++) {
            if( platzierungen.includes(i)) {
                continue;
            }
            values.push({id: i, value:i});
        }
        return <div key={"key_dropdown_platzierung"}>
            <DHSelect value={selectedPlatzierung} onChange={(id) => setSelectedPlatzierung(id)} id="id-platzierung" width={200}
                      label="Platzierung" model={values}/>
        </div>
    }

    const getPlatzungAnzeige = () => {
        let tmpPlatzierungen = platzierungen.sort(function(a, b) {
            return a - b;
        });
        let value = [];
        for (const platzierungenElement of tmpPlatzierungen) {
            value.push(<span key={"key_plazierung_" + platzierungenElement}
                             style={{display:"flex", margin:"auto", verticalAlign:"left",
                                 textAlign:"left", alignItems:"center", height:30, marginTop:10, cursor:"pointer"}}
                             onClick={() => removePlatzierung(platzierungenElement)}
            >{platzierungenElement}<DHIconMinus fontSize={25} style={{marginLeft:5}}/></span>);
        }
        return <div>
            {value}
        </div>;
    }

    return <DHDialog onClose={callBackCloseDialog} title="Bronze-Turnier starten" buttons={dialogFooter} showCloseButton={true} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{padding: 20}}>
            <div style={{marginBottom: 30, marginLeft: 10}}>
                <div>
                    <DHTextField id="id-bezeichnung" value={bezeichnung} onChange={(text) => setBezeichnung(text)} required={true} label="Bezeichnung"/>
                </div>
                <div style={{marginTop: 5, opacity: "0.6", fontSize: 10}}>
                    Gibt hier eine Bezeichnung (max. 12stellig) für das Bronze-Turnier ein.<br/>
                    Diese Bezeichnung wird im Turnier in der Admin- sowie der öffentlichen Ansicht angezeigt.<br/>
                    Stelle sicher, dass du einen anderen Name wie beim Gold- und Silber-Turnier vergibst.
                </div>
            </div>
            <div style={{marginBottom: 0}}>
                <DHRadioGroup id={"id-tournament-system"} label="Auswahl Turniermodus im Bronze-Turnier"
                              value={tournamentSystem} onChange={(value) => setTournamentSystem(value)}
                              model={[{value: TOURNAMENTSYSTEMS.EINFACHERKO.id, label: "Einfaches-KO"}, {value: TOURNAMENTSYSTEMS.DOPPELTERKO.id, label: "Doppel-KO"}]}/>
            </div>
            {tournamentSystem === TOURNAMENTSYSTEMS.EINFACHERKO.id &&
                <div style={{marginBottom: 20}}>
                    <DHSwitch id="id-spielumplatz3"
                              label="Spiel um Platz 3"
                              value={spielUmPlatz3} onChange={() => setSpielUmPlatz3(!spielUmPlatz3)}/>
                </div>
            }
            {tournamentSystem !== undefined && tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id &&
                <div style={{marginBottom: 20}}>
                    <DHSwitch id="id-finalesatzausgleich"
                              label="Finale mit Satzausgleich"
                              value={finaleVerliererseiteMussZweiSpieleGewinnen} onChange={() => setFinaleVerliererseiteMussZweiSpieleGewinnen(!finaleVerliererseiteMussZweiSpieleGewinnen)}/>
                </div>
            }
            <div style={{marginTop: 30, marginBottom: 30}}>
                <DHRadioGroup id={"id-werkommtweiter"} label="Wer soll ins Bronze-Turnier kommen?"
                              value={werKommtWeiter} onChange={(value) => setWerKommtWeiter(value)}
                              model={[{value: "alle", label: "Alle die nicht weitergekommen sind"},
                                  {value: "platzierung", label: "Platzierungen auswählen"}]}/>
                {werKommtWeiter === "platzierung" && <>
                    <div style={{display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center", marginTop: 20}}>
                        {getPlatzierungDropdown()}
                        <DHButtonIcon disabled={selectedPlatzierung === undefined} icon={<DHIconAdd fontSize={30}/>} onClick={() => addPlatzeriung()}/>
                    </div>
                    {getPlatzungAnzeige()}
                </>
                }
            </div>

            {tournamentSystem !== undefined &&
                <div style={{marginBottom: 20}}>
                    Daten für KO bzw./oder DKO Phase festlegen:
                </div>
            }
            {tournamentSystem !== undefined && gameType === "x01" && getX01Area()}
            {tournamentSystem !== undefined && gameType === "cricket" && getCricketArea()}
        </div>
    </>}/>
}

TurnierBronzeStartenDialog.propTypes = {
    tournamentid: PropTypes.string.isRequired,
    gameType: PropTypes.string.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
    callBackCloseDialog: PropTypes.func.isRequired,
};

export default TurnierBronzeStartenDialog
