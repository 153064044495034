import React from 'react'
import RanglisteTable from "./RanglisteTable";
import {Button} from "primereact/button";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import {NavLink} from "react-router-dom";
import DHTextFieldSearchPublicArea from "../../../general/DHTextFieldSearchPublicArea";


export default function PublicTournamentRanglisteTab({participants, eventOperatorId, rankingId}) {

    const [filterText, setFilterText] = React.useState("");

    const filterParticipants = () => {
        if( filterText === "") {
            return participants;
        }
        let returnList = [];
        for (const participant of participants) {
            if( participant.name.toUpperCase().includes(filterText.toUpperCase())) {
                returnList.push(participant);
            }
        }
        return returnList;
    }

    return <div>
        <div style={{display: "flex", marginTop:20, marginLeft: 15, marginBottom: 10, alignItems:"center"}}>
            <div style={{color: "white", marginRight: 10, fontSize: 20}}>Anzahl der
                Teilnehmer: {participants.length}</div>
            <DHTextFieldSearchPublicArea id="id-name-filter" value={filterText} onChange={(text) => setFilterText(text)} width={100} label="Suche" variant="filled" />
            {filterText !== "" &&
                <div style={{color: "white", marginLeft: 10, fontSize: 20}}>Gefiltert: {filterParticipants().length}</div>
            }
            {rankingId !== null && rankingId !== undefined &&
                <div style={{marginLeft:10}}>
                    <NavLink to={NAVIGATION_ITEM.PUBLIC_EVENTOPERATOR.route + "/" + eventOperatorId +"?tab=ranking&ranking=" + rankingId } style={{textDecoration: "none"}}>
                        <Button label="Zur Gesamtrangliste"/>
                    </NavLink>
                </div>
            }
        </div>
        <RanglisteTable participants={filterParticipants()}/>
    </div>
}

