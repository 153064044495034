import React, {useState} from 'react'
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";
import DHSelect from "../../general/DHSelect";

export default function TurnierGruppeAddPlayerDialog({tournamentId, anzahlGruppen, players, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [filter, setFilter] = useState("");
    const [selectedGruppe, setSelectedGruppe] = useState(undefined);
    const [selectedPlayerId, setSelectedPlayerId] = useState(undefined);

    const addPlayer = (playerId) => {
        setErrorMessage(undefined);
        if( selectedGruppe === undefined) {
            setSelectedPlayerId(undefined);
            setErrorMessage("Bitte Gruppe wählen.");
            return;
        }
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/gruppenturnier/player/add/" + tournamentId + "/" + playerId + "/" + selectedGruppe,
            {},
            () => {
                setInfoMessage("Spieler wurde der Gruppe hinzugefügt.");
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Löschen: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Löschen: " + error.message);
            });
    }


    const getPlayersArea = () => {
        if( players.length === 0 ) {
            return <div style={{marginTop:20}}>Keine Spieler vorhanden...</div>
        }
        let rows = [];
        for (const player of players) {
            if( filter !== "" && !player.idShort.includes(filter) && !player.name.toUpperCase().includes(filter.toUpperCase())) {
                continue;
            }
            if( selectedPlayerId !== undefined && selectedPlayerId !== player.id) {
                continue;
            }
            rows.push(<div style={{fontSize:14, marginBottom:20}}>
                <Button icon="pi pi-plus" onClick={() => setSelectedPlayerId(player.id)}
                        style={{height: "40px", width: "40px", marginRight: "10px"}}/>
                SpielerId: {player.idShort} / {player.name}
                {selectedPlayerId === player.id && <div style={{marginLeft:20,marginTop:20}}>
                    <div style={{marginBottom:10}}>Folgenden Spieler wirklich hinzufügen:  SpielerId {player.idShort} / {player.name}</div>
                    <div><Button style={{marginRight:10}} label="Ja" onClick={() => addPlayer(player.id)}/><Button label="Nein" onClick={() => setSelectedPlayerId(undefined)}/></div>
                </div>
                }
            </div>)
        }
        return rows;
    }

    const getGruppenauswahlArea = () => {
        let anzahlOptions = [];
        for (let i = 1; i < anzahlGruppen + 1; i++) {
            anzahlOptions.push({value: i + "", id: i});
        }
        return <div style={{marginBottom: 20}}>
            <DHSelect id="id-gruppen" value={selectedGruppe} onChange={(id) => setSelectedGruppe(id)}
                      label="Gruppen" model={anzahlOptions} width={300}/>
        </div>
    }

    return <DHDialog onClose={() => onClose()} title="Teilnehmer in Gruppe schieben" show={true} showCloseButton={true}
                     comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{padding:20}}>
            <div style={{fontSize:14, fontWeight:"bold", marginBottom:20}}>Hinweis: Wähle eine Gruppe und einen Teilnehmer der noch 'keiner' Gruppe zugewiesen ist und speichere. Darhelfer wird den Teilnehmer der Gruppe zuweisen.</div>
            <div style={{marginBottom: 20}}>
                <DHTextFieldSearch id="id-search" value={filter} onChange={(text) => setFilter(text)} label={"Filter"}/>
            </div>
            {getGruppenauswahlArea()}
            {getPlayersArea()}
        </div>
    </>}/>
}
