import React, {useEffect, useState} from 'react'
import DHDialog from "../../general/DHDialog";
import DHButtonSave from "../../general/DHButtonSave";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHTextField from "../../general/DHTextField";
import DHSwitch from "../../general/DHSwitch";
import DHButtonNew from "../../general/DHButtonNew";
import LigaVerwaltungFieldDescription from "./LigaVerwaltungFieldDescription";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import {TableContainer} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DoneIcon from "@mui/icons-material/Done";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDialog from "../../general/ConfirmDialog";

export default function LigaRegionVerwaltenDialog({eventOperatorId, onRefresh, onClose}) {

    const CELL_PADDING_VALUE = 10;

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [working, setWorking] = useState(false);

    const [id, setId] = useState(undefined);
    const [bezeichnung, setBezeichnung] = useState("");
    const [aktiv, setAktiv] = useState(true);

    const [selectedRegion, setSelectedRegion] = useState(undefined);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [filter, setFilter] = useState("");
    const [regionen, setRegionen] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/regionen/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setRegionen(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const deleteRegion = () => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.league + "/region/delete/" + selectedRegion.id;
        FetchUtil.fetchDelete(url,
            {},
            json => {
                if( json) {
                    setErrorMessage(json);
                    setSelectedRegion(undefined);
                    setShowDeleteDialog(false);
                } else {
                    setInfoMessage("Löschen war erfolgreich...");
                    setSelectedRegion(undefined);
                    setShowDeleteDialog(false);
                    ladeDaten();
                    onRefresh();
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedRegion(undefined);
                setShowDeleteDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setSelectedRegion(undefined);
                setShowDeleteDialog(false);
            });
    }

    const save = () => {
        setWorking(true);
        let data = {id: id, bezeichnung: bezeichnung, aktiv: aktiv}
        let url = ConfigUtil.getConfig().resourceUrls.league + "/region/save/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            data,
            json => {
                setId(json.id);
                setInfoMessage("Speichern erfolgreich...");
                onRefresh();
                setWorking(false);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setWorking(false);
            });
    }

    const initDialog = () => {
        setId(undefined);
        setBezeichnung("");
        setAktiv(true);
    }

    const isDisabled = () => {
        return bezeichnung === "";
    }

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        let filteredRegionen;
        if( filter.length > 0 ) {
            filteredRegionen = regionen.filter(tmp => tmp.bezeichnung.toUpperCase().includes(filter.toUpperCase()));
        } else {
            filteredRegionen = regionen;
        }

        filteredRegionen.forEach(region => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={region.id}>
                <TableCell style={style}>{region.bezeichnung}</TableCell>
                <TableCell style={style}>{region.aktiv ? <DoneIcon/> : <BlockIcon/>}</TableCell>
                <TableCell style={style}>
                    <EditIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setId(region.id);
                        setBezeichnung(region.bezeichnung);
                        setAktiv(region.aktiv);
                    }}/>
                    <DeleteIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setSelectedRegion(region);
                        setShowDeleteDialog(true);
                    }}/>
                </TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    const getTable = () => {
        return <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
            <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Bezeichnung</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 50,}}>Aktiv</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 100,}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows()}
                </TableBody>
            </Table>
        </TableContainer>
    }

    const getButtons = () => {
        return [<DHButtonSave onClick={() => save()} working={working} disabled={isDisabled()}/>,
            <DHButtonNew onClick={() => initDialog()}/>];
    }

    const getDataArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <div style={{marginBottom: 20}}>
                Eine Liga hängt normal unter einer Region, sofern du nur eine kleine Liga führst, kannst du natürlich z.B. nur eine Region anlegen und da z.B. Bayern eingeben.
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-region-bezeichnung" label="Bezeichnung" width={500} required={true} value={bezeichnung} onChange={(text) => setBezeichnung(text)}/>
            </div>
            <LigaVerwaltungFieldDescription text="z.B. Bayern, Berlin, Thüringen West, Frankfurt Mitte..."/>
            <div style={{marginTop: 10}}>
                <DHSwitch id="id-aktiv" label="Aktiv" value={aktiv} onChange={(value) => setAktiv(value)} required={false}/>
            </div>
            <div>
                {getButtons()}
            </div>
            <hr/>
            <div style={{paddingTop: 20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
                <DHTextFieldSearch id="id-filter" onChange={(text) => setFilter(text)} value={filter} width={100} label="Filter"/>
            </div>

            {getTable()}

            {showDeleteDialog && <ConfirmDialog visible={true} header={"Spielort löschen"} textOben={<div>
                <div style={{color: "red", marginBottom: 20}}>Du kannst die Region nur löschen, sobald die noch keiner Liga zugeordnet wurde.</div>
                <div>Möchtest du die Region wirklich löschen?</div>
            </div>} callBackOnNein={() => {
                setShowDeleteDialog(false);
                setSelectedRegion(undefined);
            }} callBackOnJa={() => deleteRegion()}/>}
        </div>
    }

    return <DHDialog onClose={() => onClose()} title="Regionen verwalten" show={true} showCloseButton={true}
                     buttons={[]} comp={getDataArea()}/>
}
