import React, {useState} from 'react'
import ConfirmDialog from "../../general/ConfirmDialog";
import KalenderNewEventDialog from "./KalenderNewEventDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHSwitch from "../../general/DHSwitch";
import DHDateTimePicker from "../../general/DHDateTimePicker";
import DHButtonSave from "../../general/DHButtonSave";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import DHButtonIconDelete from "../../general/DHButtonIconDelete";
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';
import DHButtonIcon from "../../general/DHButtonIcon";

export default function KalenderComponent({userId, tournamentId, readOnly, events, flatEvents, orgEvents}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [date, setDate] = useState(undefined);
    const [selectedEvent, setSelectedEvent] = useState(undefined);
    const [vergangenheitAnzeigen, setVergangenheitAnzeigen] = useState(false);
    const [nurAktuellesTurnier, setNurAktuellesTurnier] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showTerminAnnehmenDialog, setShowTerminAnnehmenDialog] = useState(false);
    const [showNewEventDialog, setShowNewEventDialog] = useState(false);

    const terminAnnehmen = () => {
        if (readOnly) {
            return;
        }
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.event + "/annehmen?eventId=" + selectedEvent.id,
            {},
            () => {
                setInfoMessage("Dein Termin wurde eingetragen");
                setShowTerminAnnehmenDialog(false);
                setSelectedEvent(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
                setShowTerminAnnehmenDialog(false);
                setSelectedEvent(undefined);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
                setShowTerminAnnehmenDialog(false);
                setSelectedEvent(undefined);
            }
        );
    }


    const storniereEvent = () => {
        if (readOnly) {
            return;
        }
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.event + "/stornieren?eventId=" + selectedEvent.id,
            {},
            () => {
                setInfoMessage("Dein Termin wurde storniert");
                setShowDeleteDialog(false);
                setSelectedEvent(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
                setShowDeleteDialog(false);
                setSelectedEvent(undefined);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
                setShowDeleteDialog(false);
                setSelectedEvent(undefined);
            }
        );
    }

    const terminSpeichern = () => {
        setErrorMessage(undefined);
        if (!date) {
            return;
        }

        let data = {isoTime: date, tournamentId: nurAktuellesTurnier ? tournamentId: undefined};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.event,
            data,
            () => {
                setInfoMessage("Dein Termin wurde eingetragen");
                setDate(null);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
                setDate(undefined);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
                setDate(undefined);
            }
        );
    }

    const canCancel = (event) => {
        return event.userEigentuemerId === userId || event.gegnerId === userId;
    }

    const canAnnehmen = (event) => {
        return event.userEigentuemerId !== userId && !event.gegnerId;
    }

    const getFlatCalendar = () => {
        if (flatEvents === null || flatEvents === undefined) {
            return "Keine Daten vorhanden";
        }
        let rows = [];
        for (const event of flatEvents) {
            if (event.vergangeheit && !vergangenheitAnzeigen) {
                continue;
            }

            let eintraege = [];
            for (const einzelEvent of event.events) {
                let title = einzelEvent.userEigentuemerName;
                if (einzelEvent.gegnerName !== null && einzelEvent.gegnerName !== undefined) {
                    title += " vs. " + einzelEvent.gegnerName;
                }
                eintraege.push(<div style={{width: "100%", padding:5, textAlign:"left"}}
                                    key={"key_kalender_component_einzelevent" + einzelEvent.id}>
                    <div style={{marginTop: 5}}>
                        {canAnnehmen(einzelEvent) && <img src="/images/dot_green.png" style={{height: 20, marginRight: 10}}/>}
                        {einzelEvent.timeOnlyFormatted} / {title}
                        {canCancel(einzelEvent) && <DHButtonIconDelete onClick={() => {
                            setShowDeleteDialog(true);
                            setSelectedEvent(einzelEvent);
                    }} />}
                        {canAnnehmen(einzelEvent) && <DHButtonIcon icon={<AddTaskRoundedIcon/>} disabled={false} onClick={() => {
                            setShowTerminAnnehmenDialog(true);
                            setSelectedEvent(einzelEvent);
                        }}/>}
                    </div>
                    <hr style={{borderTop: "1px dashed gray"}}/>
                </div>)
            }
            rows.push(<div key={"key_kalender_componente_ueberevent_" + event.timeFormatted}>
                <div style={{fontWeight: "bold", marginBottom: 5, textAlign:"left"}}>{event.day} / {event.timeFormatted}</div>
                {eintraege}
            </div>);
        }
        return <div>
            <div style={{marginBottom: 20, textAlign:"left"}}>
                <DHSwitch id="id-vergangene-anzeigen" value={vergangenheitAnzeigen} onChange={() => setVergangenheitAnzeigen(!vergangenheitAnzeigen)}
                          label="vergangene Termine anzeigen"/>
            </div>
            <hr/>
            <div style={{marginBottom: 5, textAlign:"left"}}>
                Neuen freien Termin anlegen:
            </div>
            {tournamentId !== undefined &&
                <div style={{marginTop:10, marginBottom:10, textAlign:"left"}}>
                    <DHSwitch id="id-nur-aktuelle-turniere" value={nurAktuellesTurnier} onChange={() => setNurAktuellesTurnier(!nurAktuellesTurnier)}
                              label="nur am aktuellen Turnier/Liga anzeigen"/>
                </div>
            }
            <div style={{marginBottom: 5, textAlign:"left"}}>
                <DHDateTimePicker label="" value={date} changeValue={(date) => setDate(date)}/>
            </div>
            <div style={{display:"flex", marginBottom: 20, textAlign:"left"}}>
                <DHButtonSave disabled={!date} onClick={() => terminSpeichern()}/>
            </div>
            {rows}
        </div>;
    }

    return (
        <div className='demo-app'>
            <ErrorInfoComponent errorMessage={errorMessage} infoMessage={infoMessage} warnMessage={undefined}
                                onClearErrorMessage={() => setErrorMessage(undefined)}
                                onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearWarnMessage={() => ""}/>
            <div>
                {getFlatCalendar()}
            </div>
            {showNewEventDialog &&
                <KalenderNewEventDialog tournamentId={tournamentId}
                                        onClose={() => setShowNewEventDialog(false)}
                                        onSave={() => terminSpeichern()}
                />
            }
            <ConfirmDialog visible={showDeleteDialog}
                           callBackOnNein={() => setShowDeleteDialog(false)}
                           callBackOnJa={() => storniereEvent()}
                           header="Zeitpunkt löschen..."
                           textOben="Wenn du Eigentümer des Termins bist, wird der Termin gelöscht. Sofern du den Termin nur von einem anderen Benutzer angenommen hast, wirst du ausgetragen. Dein Gegner wird darüber informiert."
                           textUnten={"Möchtest du den Eintrag löschen?"}
            />
            <ConfirmDialog visible={showTerminAnnehmenDialog}
                           callBackOnNein={() => setShowTerminAnnehmenDialog(false)}
                           callBackOnJa={() => terminAnnehmen()}
                           header="Termin annehmen..."
                           textOben=""
                           textUnten={"Möchtest du den Termin wirklich annehmen?"}
            />
        </div>
    )
}