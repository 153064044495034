import React, {useEffect, useState} from "react";
import DHDialog from "../../../general/DHDialog";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import {isGruppenturnier, isRoundRobin} from "../../../../constants/tournamentSystems";
import DHSelectionComponent from "../../../general/DHSeclectionCompontent";
import {ToggleButton} from "primereact/togglebutton";
import DHNumberTextField from "../../../general/DHNumberTextField";
import DHSwitch from "../../../general/DHSwitch";
import DHButtonSave from "../../../general/DHButtonSave";

const GRUPPE_FOLGE_MODUS_ZUR_AUSWAHL = [
    {name: 'Round Robin', code: 'round_robin'},
    {name: 'DKO', code: 'dko'},
];

export default function TournamentRoundRobinOrGruppenturnierAuslosungDialog({tournament, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const[hinUndRueckspiel, setHinUndRueckspiel] = useState(false)
    const[freilose, setFreilose] = useState([])
    const[gruppeFolgeModus, setGruppeFolgeModus] = useState(undefined)
    const[anzahlSpielerGold, setAnzahlSpielerGold] = useState(0)
    const[anzahlSpielerSilber, setAnzahlSpielerSilber] = useState(0)
    const[anzahlSpielerBronze, setAnzahlSpielerBronze] = useState(0)
    const[anzahlGruppen, setAnzahlGruppen] = useState(0)
    const[bestplatzierteNaechsteKommenWeiter, setBestplatzierteNaechsteKommenWeiter] = useState(false)
    const[working, setWorking] = useState(false)

    useEffect(() => {
        if( tournament ) {
            if( tournament.anzahlSpielerGold ) {
                setAnzahlSpielerGold(tournament.anzahlSpielerGold);
            }
            if( tournament.anzahlSpielerSilber ) {
                setAnzahlSpielerSilber(tournament.anzahlSpielerSilber);
            }
            if( tournament.anzahlSpielerBronze ) {
                setAnzahlSpielerBronze(tournament.anzahlSpielerBronze);
            }
        }
    }, [ tournament ]);

    const starteAuslosung = () => {
        setWorking(true);
        setErrorMessage(undefined);
        let id = tournament.id;

        let data = {id: id, anzahlGruppen: anzahlGruppen, anzahlSpielerGold: anzahlSpielerGold,
            anzahlSpielerSilber: anzahlSpielerSilber, anzahlSpielerBronze: anzahlSpielerBronze,
            bestplatzierteNaechsteKommenWeiter: bestplatzierteNaechsteKommenWeiter,
            gruppeFolgeModusId:gruppeFolgeModus, gruppenFuerFreilose: freilose, hinUndRueckspiel: hinUndRueckspiel};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/starttournament",
            data,
            json => {
                setWorking(false);
                setInfoMessage("Auslosung wurde erfolgreich durchgeführt.");
                onRefresh();
                onClose();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Durchführen der Auslosung: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler beim Durchführen der Auslosung: " + error.message);
                setWorking(false);
            }, 60000
        );
    }

    const getBereichDYP = () => {
        if( tournament.dyp && getAnzahlSpieler() % 2 !== 0) {
            return <div>Es sind {anzahlSpieler} Teilnehmer im Turnier vorhanden. Bei DYP muss die Anzahl der Spieler durch 2 teilbar sein. z.B. 8, 10, 12...</div>
        }
        return undefined;
    }

    const getAnzahlFreiloseDieVergebenWerdenMuessen = () => {
        let anzahlSpieler = getAnzahlSpieler();
        let dyp = tournament.dyp;
        if( !anzahlGruppen) {
            return 0;
        }
        let tmpAnzahlSpieler = dyp ? anzahlSpieler / 2 : anzahlSpieler;
        if( tmpAnzahlSpieler % anzahlGruppen === 0 ) {
            return 0
        } else {
            let wert = tmpAnzahlSpieler % anzahlGruppen;
            return anzahlGruppen - wert;
        }
    }

    const addFreilos = (group) => {
        if( freilose.length === getAnzahlFreiloseDieVergebenWerdenMuessen()) {
            return;
        }
        let newFreilose = [...freilose];
        newFreilose.push(group);
        setFreilose(newFreilose);
    }

    const removeFreilos = (group) => {
        let tmpFreilose = freilose;
        let newFreilose = [];
        for (const freiloseElement of tmpFreilose) {
            if( freiloseElement !== group) {
                newFreilose.push(freiloseElement);
            }
        }
        setFreilose(newFreilose);
    }

    const isFreilos = (group) => {
        return freilose.includes(group);
    }

    const getFreilosArea = () => {
        if( getAnzahlFreiloseDieVergebenWerdenMuessen() === 0) {
            return "";
        }

        let buttons = [];
        for(let group = 1; group <= anzahlGruppen; group++) {
            buttons.push(<ToggleButton onLabel={""+group} offLabel={""+group} checked={isFreilos(group)}
                                       style={{marginRight:2}}
                                       onChange={() => isFreilos(group) ? removeFreilos(group) : addFreilos(group)}/> )
        }
        return <div>
            <div className="grid" style={{marginTop:20}}>
                <div>Du musst {getAnzahlFreiloseDieVergebenWerdenMuessen()} Freilos(e) für Gruppe(n) vergeben. Bitte markiere die Gruppe(n) die ein Freilos erhalten sollen. Die Gruppe(n) mit Freilos haben dann einen Spieler weniger.</div>
            </div>
            <div className="grid" style={{marginTop:10}}>
                <div>{buttons}</div>
            </div>
            <div className="grid" style={{marginTop:20}}>
                <div style={{color: freilose.length !== getAnzahlFreiloseDieVergebenWerdenMuessen() ? "red": ""}}>Vergebene Freilose: {freilose.length}</div>
            </div>
        </div>
    }

    const getBereichFuerRoundRobin = () => {
        let anzahlSpieler = getAnzahlSpieler();
        let dyp = tournament.dyp;
        if( !isRoundRobin(tournament.turniersystem)) {
            return "";
        }
        if( anzahlSpieler === 0) {
            return <div>Turnier mit 0 Teilnehmern kann nicht gestartet werden.</div>
        }
        if( isRoundRobin(tournament.turniersystem) && tournament.auslosungVonHand) {
            return <>
                {getHinUndRueckspiel()}
            </>
        }
        let bereichDYP = getBereichDYP();
        if (bereichDYP !== undefined) {
            return bereichDYP;
        }
        return <div style={{textAlign: "left"}}>
            <div className="grid" style={{marginTop: "20px"}}>
                Beachte bitte, in Darthelfer können in einer Gruppe maximal 20 Spieler sein!
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                Bitte geben Sie die gewünschte Anzahl an Gruppen ein.
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                Anzahl Spieler: {anzahlSpieler} (ohne Warteliste) {dyp && " - nach Auslosung DYP " + (anzahlSpieler / 2) + " Doppelpaarungen"}
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                <div style={{fontWeight: "bold"}}>
                    Hinweis: Anzahl muss größer 0 und kleiner 129 sein.
                </div>
            </div>
            {getAnzahlGruppen()}
            {getHinUndRueckspiel()}
            {getFreilosArea()}
        </div>;
    }

    const getHinweisGruppeAlsDKO = () => {
        if (gruppeFolgeModus !== undefined && gruppeFolgeModus === "dko") {
            return <div className="grid" style={{marginTop: 10, color: "red"}}>
                Hinweis: Sobald die Gruppenphase als DKO gespielt wird, können maximal 2 Teilnehmer in die KO Phase kommen.<br/><br/>
                Sobald du auswählst, dass eine Person weiterkommt, wird das Finale auf der Gewinnerseite gespielt und der 'Gruppengewinner' kommt weiter.<br/><br/>
                Sofern du auswählst, dass zwei Personen weiterkommen, wird das Finale der Gewinnerseite NICHT gespielt und damit nicht von der automatischen Boardvergabe aufgerufen.<br/>
                Der Gewinner der Verliererseite und der Gewinnerseite sind automatisch weiter.<br/>
                Natürlich kannst du das Spiel spielen lassen, allerdings ist es nicht relevant für das Weiterkommen.
            </div>
        }
        return "";
    }

    const getAnzahlGruppen = () => {
        return <div style={{marginTop: 10}}>
            <DHNumberTextField id="id-anzahl-gruppen" value={anzahlGruppen} onChange={(value) => {
                setAnzahlGruppen(value);
                setFreilose([]);
            }} label="Anzahl Gruppen" min={1} width={300} required={true}/>
        </div>
    }

    const getHinUndRueckspiel = () => {
        return <div style={{marginTop: 20}}>
            <DHSwitch id="id-hinundrueckspiel" label="Hin- und Rückspiel" value={hinUndRueckspiel}
                      onChange={() => setHinUndRueckspiel(!hinUndRueckspiel)}/>
        </div>
    }

    const getAnzahlSpielerGold = () => {
        return <div style={{marginTop: 20}}>
            <div>
                <DHNumberTextField id="id-spieler-pro-gruppe-gold" value={anzahlSpielerGold} onChange={(value) => setAnzahlSpielerGold(value)}
                                   label="Anzahl Spieler pro Gruppe für Gold KO/DKO" min={1} width={300} required={true}/>
            </div>
            <div style={{marginTop:5, opacity:"0.6", fontSize:10}}>
                Normales KO/DKO Turnier wird in Darthelfer Gold genannt.<br/>
                Stellst du hier 2 ein, gehen die ersten beiden in der Tabelle in das Gold Turnier.<br/>
                Kann jederzeit bearbeitet werden, wird hier nur für die Anzeige in der Tabelle eingestellt.
            </div>
        </div>
    }

    const getAnzahlSpielerSilber = () => {
        return <div style={{marginTop: 20}}>
            <div>
                <DHNumberTextField id="id-spieler-pro-gruppe-silber" value={anzahlSpielerSilber} onChange={(value) => setAnzahlSpielerSilber(value)}
                                   label="Anzahl Spieler pro Gruppe für Silber KO/DKO" min={1} width={300} required={false}/>
            </div>
            <div style={{marginTop: 5, opacity: "0.6", fontSize: 10}}>
                Lucky Loser Turnier wird in Darthelfer Silber genannt.<br/>
                Stellst du hier z.B. 2 ein, gehen die nächsten beiden nach dem Gold Turnier in das Silber Turnier.<br/>
                Kann jederzeit bearbeitet werden, wird hier nur für die Anzeige in der Tabelle eingestellt.
            </div>
        </div>
    }

    const getAnzahlSpielerBronze = () => {
        return <div style={{marginTop: 20}}>
            <div>
                <DHNumberTextField id="id-spieler-pro-gruppe-bronze" value={anzahlSpielerBronze} onChange={(value) => setAnzahlSpielerBronze(value)}
                                   label="Anzahl Spieler pro Gruppe für Bronze KO/DKO" min={1} width={300} required={false}/>
            </div>
            <div style={{marginTop: 5, opacity: "0.6", fontSize: 10}}>
                Stellst du hier z.B. 1 ein, geht der nächste nach dem Gold-Turnier und Silber-Turnier in das Bronze-Turnier.<br/>
                Kann jederzeit bearbeitet werden, wird hier nur für die Anzeige in der Tabelle eingestellt.
            </div>
        </div>
    }

    const getBereichFuerGruppeDannDannGoldSilberBronze = () => {
        let dyp = tournament.dyp;
        let anzahlSpieler = getAnzahlSpieler();
        if (!isGruppenturnier(tournament.turniersystem)) {
            return "";
        }
        if( anzahlSpieler === 0) {
            return <div>Turnier mit 0 Teilnehmern kann nicht gestartet werden.</div>
        }
        let bereichDYP = getBereichDYP();
        if( bereichDYP !== undefined) {
            return bereichDYP;
        }
        if( tournament.auslosungVonHand) {
            return <>
                <div className="grid" style={{marginTop: "20px"}}>
                    <div style={{float: "left", width: 350}}>
                        Gruppe soll als Round Robin o. DKO gespielt werden:
                    </div>
                    <div style={{float: "left"}}>
                        <DHSelectionComponent value={gruppeFolgeModus} onChange={(code) => setGruppeFolgeModus(code)} values={GRUPPE_FOLGE_MODUS_ZUR_AUSWAHL} title="Folgemodus"/>
                    </div>
                </div>
                {getAnzahlSpielerGold()}
                {getAnzahlSpielerSilber()}
                {getAnzahlSpielerBronze()}
                {getHinUndRueckspiel()}
                {getHinweisGruppeAlsDKO()}
                {getHinweisGruppeAlsDKO()}
            </>
        }
        return <div style={{textAlign: "left"}}>
            <div className="grid">
                <p style={{verticalAlign: "bottom"}}>
                    Es sind <span style={{fontWeight: "bold", fontSize: 25, marginRight: 5, marginLeft: 5}}>{anzahlSpieler}</span>
                    Spieler (ohne Warteliste) für das Turnier '{tournament.bezeichnung}' angelegt.
                    {dyp && " Nach Auslosung DYP " + (anzahlSpieler / 2) + " Doppelpaarungen"}
                </p>
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                Beachte bitte, in Darthelfer können in einer Gruppe maximal 20 Spieler sein!
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                Bitte geben Sie die gewünschte Anzahl an Gruppen und die Anzahl der Spieler die von der Gruppenphase in die KO/DKO Phase übernommen werden sollen ein.
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                <div style={{fontWeight: "bold"}}>
                    Hinweis: Anzahl muss größer 0 und kleiner 129 sein. Wir empfehlen im Zusammenspiel mit den Spielern die weiter kommen, eine Gruppenanzahl zu wählen die sinnvoll ist und keine
                    Freilose verursacht.
                </div>
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                <div style={{fontWeight: "bold"}}>
                    Hinweis: Wir empfehlen eine gerade Gruppenanzahl (z.B. 2,4,6,8 usw.). In diesem Fall wird ein KO/DKO abhängig von der Gruppentabelle über Kreuz zusammengestellt.
                </div>
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                <div style={{float: "left", width: 350}}>
                    Gruppe soll als Round Robin o. DKO gespielt werden:
                </div>
                <div style={{float: "left"}}>
                    <DHSelectionComponent value={gruppeFolgeModus} onChange={(code) => setGruppeFolgeModus(code)} values={GRUPPE_FOLGE_MODUS_ZUR_AUSWAHL} title="Folgemodus"/>
                </div>
            </div>
            {gruppeFolgeModus === "dko" && <>
                {getAnzahlGruppen()}
                <div style={{marginTop:20}}>
                    <DHNumberTextField id="id-spieler-pro-gruppe" value={anzahlSpielerGold} onChange={(value) => setAnzahlSpielerGold(value)}
                                   label="Anzahl Spieler pro Gruppe für KO/DKO" min={1} width={300} required={true}/>
                </div>
            </>
            }
            {gruppeFolgeModus === "round_robin" && <>
                {getAnzahlGruppen()}
                {getAnzahlSpielerGold()}
                {getAnzahlSpielerSilber()}
                {getAnzahlSpielerBronze()}
                <div style={{marginTop: "20px"}}>
                    <DHSwitch id="id-bestplatzierteNachete" label="Bestplatzierte Nächste kommen weiter ins Gold" value={bestplatzierteNaechsteKommenWeiter}
                              onChange={() => setBestplatzierteNaechsteKommenWeiter(!bestplatzierteNaechsteKommenWeiter)}/>
                </div>
                {getHinUndRueckspiel()}
            </>
            }
            {getHinweisGruppeAlsDKO()}
            {getFreilosArea()}
            <div className="grid" style={{marginTop: "20px"}}>
                <div style={{float: "left", color: "red"}}>
                    - Nach der Gruppenphase sollten 4, 8, 16, 32, 64, 128... Spieler in die KO/DKO Phase übergehen.<br/>Sofern dies nicht der Fall ist, werden die Gruppenersten Freilose erhalten.
                </div>
            </div>
        </div>;
    }


    const getAnzahlSpieler = () => {
        let anzahlSpieler = 0;

        let players = tournament.tournamentPlayerEntities;
        if( players !== undefined && players !== null) {
            players = players.filter(player => !player.warteliste);
            anzahlSpieler = players.length;
        }
        return anzahlSpieler;
    }

    const canSave = () => {
        if( isRoundRobin(tournament.turniersystem) && tournament.auslosungVonHand) {
            return true;
        } else if (isRoundRobin(tournament.turniersystem) && !tournament.auslosungVonHand) {
            let richtigeAnzahlFreilose = freilose.length === getAnzahlFreiloseDieVergebenWerdenMuessen();
            return anzahlGruppen && anzahlGruppen > 0 && anzahlGruppen < 129 && richtigeAnzahlFreilose;
        } else if(isGruppenturnier(tournament.turniersystem)  && tournament.auslosungVonHand) {
            return anzahlSpielerGold && gruppeFolgeModus;
        } else if(isGruppenturnier(tournament.turniersystem)  && !tournament.auslosungVonHand) {
            let richtigeAnzahlFreilose = freilose.length === getAnzahlFreiloseDieVergebenWerdenMuessen();
            return anzahlGruppen && anzahlGruppen > 0 && anzahlGruppen < 129 &&
                anzahlGruppen && anzahlSpielerGold > 0 &&
                richtigeAnzahlFreilose && gruppeFolgeModus;
        }
        return false;
    }

    return <DHDialog title={"Auslosung starten"} showCloseButton={true} onClose={() => onClose()}
                     buttons={[<DHButtonSave label="Auslosung starten" disabled={!canSave()} onClick={() => starteAuslosung()} working={working}/>
                     ]} comp={<div style={{padding:10}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {working && <div style={{color: "red", marginTop:10, marginBottom:10}}>Die Auslosung wird durchgeführt, dies kann etwas dauern!!!</div>}
        {getBereichFuerRoundRobin()}
        {getBereichFuerGruppeDannDannGoldSilberBronze()}
    </div>}/>
}