import React from 'react'
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import LiveViewGame from "./LiveViewGame";
import PropTypes from "prop-types";
import ConfigUtil from "../../../../util/ConfigUtil";
import FetchUtil from "../../../../util/FetchUtil";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import {NavLink} from "react-router-dom";
import {PrimeIcons} from "primereact/api";
import {TOURNAMENTSYSTEMS} from "../../../../constants/tournamentSystems";
import PublicTournamentBreadCumb from "../tournaments/PublicTournamentBreadCrumb";
import LiveViewGameDetail from "./LiveViewGameDetail";
import {Menu} from "primereact/menu";
import DateUtil from "../../../../util/DateUtil";
import LiveViewTable from "../tournaments/LiveViewTable";
import DHMobileDiv from "../../../general/DHMobileDiv";
import DHDesktopDiv from "../../../general/DHDesktopDiv";
import LiveTVGame from "./LiveTVGame";
import {Button} from "primereact/button";
import BoardAuswahlDialog from "../../veranstalter/BoardAuswahlDialog";

let stompClient = undefined;

class LiveView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            games: [],
            connected: false,
            counter: 0,
            viewModus: "kachel",
            tournamentSystem: "",
            liveTVShowAverage: false,
            liveTVShowLastThrow: false,
            favorits: [],
            onlineTournament: false,
            detailBoardId: props.boardId !== null && props.boardId !== undefined ? props.boardId : undefined,
            gamesFoundDate: undefined,
            showBoardAuswahlDialog: false,
            playerIdsChecked: [],
        };

        this.menu = React.createRef();

        this.onMessageReceived = this.onMessageReceived.bind(this);
    }

    componentDidMount() {
        this.connect();

        this.mytimer = setInterval(() => {
            this.searchForGames();
        }, 30000);

        this.checkPlayerTimer = setInterval(() => {
            this.clearCheckPlayers();
        }, 1000);
    }

    componentWillUnmount() {
        if (stompClient !== undefined) {
            stompClient.disconnect();
        }
        clearInterval(this.mytimer);
        clearInterval(this.checkPlayerTimer);
    }

    getTournamentLink() {
        if (!this.state.onlineTournament) {
            return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" + this.props.tournamentid;
        }
        if (this.state.tournamentSystem === TOURNAMENTSYSTEMS.EINFACHERKO.id || this.state.tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id) {
            return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.TURNIERDETAIL.route + "?turnierid=" + this.props.tournamentid;
        } else if (this.state.tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id || this.state.tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id) {
            return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.TURNIERGRUPPENDETAIL.route + "?turnierid=" + this.props.tournamentid;
        } else if (this.state.tournamentSystem === TOURNAMENTSYSTEMS.WEEKENDLEAGUE.id) {
            return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.WEEKENDLEAGUEDETAIL.route + "?turnierid=" + this.props.tournamentid;
        } else if (this.state.tournamentSystem === TOURNAMENTSYSTEMS.LIGA.id) {
            return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.LIGADETAIL.route + "?ligaid=" + this.props.tournamentid;
        } else {
            return "";
        }
    }

    getEventOperatorLink() {
        return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_EVENTOPERATOR.route + "/" + this.props.eventOperatorId;
    }

    searchForGames() {
        if (this.state.gamesFoundDate !== undefined) {
            // prüfen ob die letzen 2 Min über den WebSocket etwas geliefert wurde, wenn ja wird nicht über den RestCall nach Games gesucht.
            let letztesDatum = this.state.gamesFoundDate;
            let aktuell = new Date();
            let isBefore = DateUtil.isDateBeforeDateWithMinutes(aktuell, letztesDatum, 1);
            if (!isBefore) {
                return;
            }
        }
        let url = undefined;
        if (this.props.tournamentid !== null && this.props.tournamentid !== undefined) {
            url = ConfigUtil.getConfig().resourceUrls.public + "/tournament/gameinfos/available/" + this.props.tournamentid;
        } else if (this.props.eventOperatorId !== null && this.props.eventOperatorId !== undefined) {
            url = ConfigUtil.getConfig().resourceUrls.public + "/eventoperator/gameinfos/available/" + this.props.eventOperatorId;
        }
        FetchUtil.fetchGet(url,
            dto => {
                if (dto.gamesVorhanden) {
                    if (!this.state.connected) {
                        this.connect();
                        this.setState({
                            counter: 0,
                            tournamentSystem: dto.tournamentSystem,
                            onlineTournament: dto.onlineTournament,
                            tournamentId: dto.tournamentId,
                            tournamentBezeichnung: dto.tournamentBezeichnung,
                            eventOperatorId: dto.eventOperatorId,
                            eventOperatorBezeichnung: dto.eventOperatorBezeichnung
                        });
                    }
                } else {
                    if (this.state.counter > 0 && this.state.counter % 10 === 0) {
                        // im Normalfall ist er Verbunden, wenn er 10 mal nix gefunden hat soll disconnected werden.
                        this.disconnect();
                    }
                    // wenn er 120 mal nichts gefunden hat, wird auf darthelfer.de gesprungen.
                    if (this.state.counter >= 120) {
                        window.open("http://www.darthelfer.de", "_self")
                    }
                    this.setState({
                        games: [],
                        counter: this.state.counter + 1,
                        tournamentSystem: dto.tournamentSystem,
                        onlineTournament: dto.onlineTournament,
                        tournamentId: dto.tournamentId,
                        tournamentBezeichnung: dto.tournamentBezeichnung,
                        eventOperatorId: dto.eventOperatorId,
                        eventOperatorBezeichnung: dto.eventOperatorBezeichnung
                    });
                }
            },
            responseNotOk => {
                this.disconnect();
            },
            error => {
                this.disconnect();
            });
    }

    disconnect() {
        this.setState({connected: false});
        if (stompClient !== null && stompClient !== undefined) {
            console.log("cleanup stompclient");
            stompClient.disconnect();
        }
    }

    connect() {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                let destination = undefined;
                if (this.props.tournamentid !== null && this.props.tournamentid !== undefined) {
                    destination = "/topic/public/tournament/game/infos/" + this.props.tournamentid;
                } else if (this.props.eventOperatorId !== null && this.props.eventOperatorId !== undefined) {
                    destination = "/topic/public/eventoperator/game/infos/" + this.props.eventOperatorId;
                }
                console.log("Websocket - connect success");
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, this.onMessageReceived);
                this.setState({connected: true});
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    onMessageReceived(payload) {
        let payloadBody = payload.body;
        const json = JSON.parse(payloadBody);
        this.ueberpruefeLegsGecheckt(json.gameShortInfos);
        this.setState({
            games: json.gameShortInfos,
            gamesFoundDate: json.gameShortInfos.length > 0 ? new Date() : undefined
        });
    }

    ueberpruefeLegsGecheckt(newGames) {
        for (const game of this.state.games) {
            let newGame = newGames.filter(tmGame => tmGame.id === game.id)[0];
            if( newGame !== null && newGame !== undefined) {
                if( game.inGame || newGame.inGame) {
                    // bei inGame wird kein Leg mit geliefert, aus dem Grund kam es zu Problemen wenn man erneut in ein
                    // Spiel am Scorer eingestiegen ist.
                    continue;
                }
                // game ist bereits verhanden.
                if( game.spieler1Legs < newGame.spieler1Legs) {
                    let playerIds = this.state.playerIdsChecked;
                    playerIds.push({playerId: game.spieler1Id, counter: 0});
                    this.setState({playerIdsChecked: playerIds});
                }
                if( game.spieler2Legs < newGame.spieler2Legs) {
                    let playerIds = this.state.playerIdsChecked;
                    playerIds.push({playerId: game.spieler2Id, counter: 0});
                    this.setState({playerIdsChecked: playerIds});
                }
            }
        }
    }

    getMenuItems() {
        let selectedGame = this.getSelectedGame(this.state.games);
        let returnValue = [];
        returnValue.push({
            label: 'Turnierübersicht',
            icon: PrimeIcons.FOLDER,
            command: () => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_TOURNAMENTS.route, '_self'),
            style: {}
        });
        {
            selectedGame !== undefined && this.state.detailBoardId !== undefined &&
            returnValue.push({
                label: 'Detail schliessen',
                icon: PrimeIcons.TIMES_CIRCLE,
                command: () => this.setState({detailBoardId: undefined}),
                style: {}
            });
        }
        {
            this.props.tournamentid !== null && this.props.tournamentid !== undefined &&
            returnValue.push({
                label: 'Zum Turnier',
                icon: PrimeIcons.FOLDER_OPEN,
                command: () => window.open(this.getTournamentLink(), '_self'),
                style: {}
            });
        }
        {
            this.props.eventOperatorId !== null && this.props.eventOperatorId !== undefined &&
            returnValue.push({
                label: 'Zum Veranstalter',
                icon: PrimeIcons.ID_CARD,
                command: () => window.open(this.getEventOperatorLink(), '_self'),
                style: {}
            });
        }
        returnValue.push({
            label: 'Home',
            icon: PrimeIcons.HOME,
            command: () => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.HOME.route, '_self')
        });
        return returnValue;
    }

    getHeaderMobileMenu() {
        if (this.state.detailBoardId !== undefined) {
            return "";
        }

        return <DHMobileDiv comp={
            <div className="grid" style={{
                height: "100%",
                margin: 0,
                padding: 0,
                display: "flex",
                justifyContent: "center",
                verticalAlign: "middle",
                alignItems: "center"
            }}>
                <div className="col" style={{
                    fontSize: 20, color: "white",
                    marginRight: 20, display: "flex",
                    justifyContent: "left", alignItems: "center"
                }}>
                    <div>
                        <i className="pi pi-bars" style={{margin: 10, fontSize: 50, color: "black"}}
                           onClick={(e) => this.menu.current.toggle(e)}/>
                        <Menu model={this.getMenuItems()} popup ref={this.menu} style={{fontSize: 30, width: 300}}/>
                    </div>
                    <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENTS.route} style={{textDecoration: "none"}}>
                        <img alt="Logo" src={"/images/darthelfer_quer.png"} style={{height: 50, marginLeft: 20}}/>
                    </NavLink>
                </div>
            </div>
        }/>
    }

    getHeaderSelectedGame() {
        let selectedGame = this.getSelectedGame(this.state.games);

        if (selectedGame === undefined && this.state.detailBoardId === undefined) {
            return "";
        }

        return <div className="grid" style={{
            height: "100%",
            margin: 0,
            padding: 0,
            display: "flex",
            justifyContent: "center",
            verticalAlign: "middle",
            alignItems: "center"
        }}>
            <div className="col" style={{
                marginRight: 20,
                margin: "auto",
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                verticalAlign: "middle",
                fontSize: 20,
                fontWeight: "bold",
                color: "#ac1c27"
            }}>
                {selectedGame !== undefined && this.state.detailBoardId === undefined && <>
                    <NavLink
                        to={NAVIGATION_ITEM.PUBLIC_EVENTOPERATOR.route + "/" + this.getSelectedGame(this.state.games).eventOperatorId}
                        style={{textDecoration: "none"}}>
                        <i className={PrimeIcons.ID_CARD}
                           style={{fontSize: 30, marginRight: 20, color: "#ac1c27", cursor: "pointer"}}/>
                    </NavLink>
                    {this.getSelectedGame(this.state.games).tournamentId !== null && this.getSelectedGame(this.state.games).tournamentId !== undefined &&
                        <NavLink
                            to={NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" + this.getSelectedGame(this.state.games).tournamentId}
                            style={{textDecoration: "none"}}>
                            <i className={PrimeIcons.FOLDER}
                               style={{fontSize: 30, marginRight: 20, color: "#ac1c27", cursor: "pointer"}}/>
                        </NavLink>
                    }
                </>
                }
                {this.state.detailBoardId !== undefined && <>
                    <i className={PrimeIcons.TIMES_CIRCLE}
                       style={{fontSize: 30, marginLeft: 20, marginRight: 20, color: "#ac1c27", cursor: "pointer"}}
                       onClick={() => this.setState({detailBoardId: undefined})}/>
                </>
                }
            </div>
        </div>
    }

    getBoardSelectionArea() {
        if( this.props.showBoardSelection === undefined || !this.props.showBoardSelection) {
            return "";
        }
        return <div style={{padding:20, color:"white"}}>
            <div>Dieser Bereich dient dazu, alle Boards zu deinem Veranstalter (Eventübergreifend) anzuzeigen.</div>
            <div>Sobald du ein Board ausgewählt hast, wird dieses immer als Favorit angezeigt. Dies wird von Streamern genutzt, die den Live-View eines Boards anzeigen lassen wollen.</div>
            <div style={{marginTop: 20, marginBottom: 20}}>
                <Button label="Boards auswählen"
                        onClick={() => this.setState({showBoardAuswahlDialog: true})}
                        style={{height: 40, marginLeft: 10}}/>
            </div>
            {this.state.showBoardAuswahlDialog &&
                <BoardAuswahlDialog eventOperatorId={this.props.eventOperatorId}
                                    onSelection={(boardId, boardName) => {
                                        this.addFavorit(boardId, boardName);
                                    }}
                                    favorits={this.state.favorits}
                                    onDelete={(boardId) => this.addFavorit(boardId, "")}
                                    onClose={() => this.setState({showBoardAuswahlDialog: false})}/>}
            <hr/>
        </div>
    }

    getHeaderArea() {
        if (this.props.showEmbedded !== undefined && this.props.showEmbedded) {
            return "";
        }
        if ((this.props.eventOperatorId === null || this.props.eventOperatorId === undefined) &&
            (this.props.tournamentid === null || this.props.tournamentid === undefined)) {
            return "";
        }

        return <div>
            <div style={{
                width: "100%",
                backgroundColor: "white",
                padding: 5,
                verticalAlign: "middle"
            }}>
                {this.getHeaderMobileMenu()}
                {this.getHeaderSelectedGame()}
            </div>
        </div>
    }

    getSelectedGame = (games) => {
        let gameOffen = games.filter(game => !game.abgeschlossen && game.boardId === this.state.detailBoardId)[0];
        let gameAbgeschlossen = games.filter(game => game.abgeschlossen && game.boardId === this.state.detailBoardId)[0];
        if (gameOffen !== undefined) {
            return gameOffen
        } else if (gameAbgeschlossen !== undefined) {
            return gameAbgeschlossen;
        } else {
            return undefined;
        }
    }

    getFavGamesArea() {
        let cols = [];

        for (const favorit of this.state.favorits) {
            let myGame = undefined;
            let myGames = this.state.games.filter(game => game.boardId === favorit.boardId);
            if (myGames.length > 0) {
                myGame = myGames[0];
            }
            let boardId = favorit.boardId;
            let boardName = favorit.boardName;
            if( this.state.viewModus === "tv") {
                cols.push(<div key={"key_live_view_game_fav_" + boardId}>
                    <LiveTVGame game={myGame}
                                proBoardName={boardName}
                                playerIdsChecked={this.getIdsFromPlayerChecked()}
                                boardId={boardId}
                                favorits={this.state.favorits}
                                showAverage={this.state.liveTVShowAverage}
                                showLastThrow={this.state.liveTVShowLastThrow}
                                onLastThrow={(value) => this.setState({liveTVShowLastThrow: value})}
                                onShowAverage={(value) => this.setState({liveTVShowAverage: value})}
                                onFavorit={(boardId, boardName) => this.addFavorit(boardId, boardName)}
                                onDetail={(boardId) => {
                                    this.setState({detailBoardId: boardId});
                                }}/>
                </div>);

            } else {
                cols.push(<div key={"key_live_view_game_fav_" + boardId}>
                <LiveViewGame game={myGame} boardName={favorit.boardName}
                              playerIdsChecked={this.getIdsFromPlayerChecked()}
                              onFavorit={(boardId, boardName) => this.addFavorit(boardId, boardName)}
                              favorits={this.state.favorits}
                              onDetail={(boardId) => {
                                  this.setState({detailBoardId: boardId});
                              }}/></div>)
            }
        }

        if( cols.length === 0 ) {
            return "";
        }
        return <div style={{padding:20}}>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", margin:10, fontWeight:"bold", textDecoration:"underline", color:"white", fontSize:16}}>
                Favoriten
            </div>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                {cols}
            </div>
            <hr/>
        </div>
    }


    addFavorit(boardId, boardName) {
        if( this.state.favorits.filter(fav => fav.boardId === boardId).length > 0) {
            let newFavorits = this.state.favorits.filter(fav => fav.boardId !== boardId);
            this.setState({favorits: newFavorits});
        } else {
            let favorits = this.state.favorits;
            favorits.push({boardId: boardId, boardName: boardName});
            this.setState({favorits: favorits});
        }
    }

    getIdsFromPlayerChecked() {
        let playerIds = [];
        for (const tmp of this.state.playerIdsChecked) {
            playerIds.push(tmp.playerId);
        }
        return playerIds;
    }

    clearCheckPlayers() {
        let playerIdsChecked = [];
        for (const tmp of this.state.playerIdsChecked) {
            if( tmp.counter <= 6) {
                tmp.counter = tmp.counter + 1;
                playerIdsChecked.push(tmp);
            }
        }
        if( this.state.playerIdsChecked.length === 0 && playerIdsChecked.length === 0) {
            return;
        }
        this.setState({playerIdsChecked: playerIdsChecked});
    }

    render() {

        let selectedGame = this.getSelectedGame(this.state.games);
        if (selectedGame !== undefined && this.state.detailBoardId !== undefined) {
            return <div
                style={{
                    backgroundColor: "#022836",
                    padding: 5,
                    height: "100vw",
                    minHeight: "100vh",
                    width: "100%"
                }}>
                {this.getHeaderArea()}
                <div style={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                    <LiveViewGameDetail game={selectedGame}
                                        onClose={() => this.setState({detailBoardId: undefined})}/>
                </div>
            </div>
        } else if (selectedGame === undefined && this.state.detailBoardId !== undefined) {
            return <div style={{
                backgroundColor: "#022836",
                padding: 5,
                height: "100vw",
                minHeight: "100vh",
                width: "100%"
            }}>
                {this.getHeaderArea()}
                {this.props.showEmbedded && !this.state.onlineTournament && <>
                    <i className={PrimeIcons.TIMES_CIRCLE} style={{
                        fontSize: 30,
                        marginLeft: 20,
                        marginRight: 20,
                        color: "#ac1c27",
                        cursor: "pointer"
                    }} onClick={() => this.setState({detailBoardId: undefined})}/>
                </>
                }
                <div style={{
                    justifyContent: "center",
                    textAlign: "center",
                    fontSize: "3vw",
                    color: "white",
                    marginTop: 20
                }}>
                    <div style={{marginBottom: 10}}>Am Board wird aktuell nicht gespielt!!!</div>
                    <div>
                        <img alt="Logo" src={"/images/darthelfer.svg"} style={{width: 250, maxWidth: 250}}/>
                    </div>
                </div>
            </div>
        }

        return <div style={{backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
            {this.getBoardSelectionArea()}
            {this.getHeaderArea()}
            {!this.props.showEmbedded && !this.state.onlineTournament && <>
                <DHDesktopDiv comp={
                    <div style={{width: "100%"}}>
                        <PublicTournamentBreadCumb isMobil={false} isLiveView={true}
                                                   tournamentBezeichnung={this.state.tournamentBezeichnung}
                                                   eventOperatorId={this.state.eventOperatorId}
                                                   eventOperatorBezeichnung={this.state.eventOperatorBezeichnung}
                                                   tournamentId={this.state.tournamentId}/>
                    </div>
                }/>
                <DHMobileDiv comp={
                    <div>
                        <PublicTournamentBreadCumb isMobil={true} isLiveView={true}
                                                   tournamentBezeichnung={this.state.tournamentBezeichnung}
                                                   eventOperatorId={this.state.eventOperatorId}
                                                   eventOperatorBezeichnung={this.state.eventOperatorBezeichnung}
                                                   tournamentId={this.state.tournamentId}/>
                    </div>
                }/>
            </>
            }
            <div style={{textAlign: "left", padding: 10, paddingLeft: 20, display:"flex"}}>
                <i className={PrimeIcons.TABLE}
                   style={{fontSize: 30, marginRight: 20, color: "white", cursor: "pointer"}}
                   onClick={() => this.setState({viewModus: "kachel"})}/>
                <i className={PrimeIcons.LIST}
                   style={{fontSize: 30, marginRight: 20, color: "white", cursor: "pointer"}}
                   onClick={() => this.setState({viewModus: "liste"})}/>
                <i className={PrimeIcons.BARS}
                   style={{fontSize: 30, marginRight: 20, color: "white", cursor: "pointer"}}
                   onClick={() => this.setState({viewModus: "splitliste"})}/>
                <img src="/images/tv_white.png" style={{height: 25, marginTop:2, cursor: "pointer"}}
                     onClick={() => this.setState({viewModus: "tv"})}/>
            </div>
            {(this.state.viewModus === "tv" || this.state.viewModus === "kachel") &&
                <div style={{textAlign:"left", marigin:0, marginLeft:20, color:"white"}}>Mit dem <i className={PrimeIcons.STAR}
                            style={{fontSize: 10, color: "white"}}/> kannst du das Board als Favorit oben anpinnen.
                </div>
            }
            {this.getFavGamesArea()}
            {this.state.games.length === 0 &&
                <div style={{color: "white", fontSize: 18, marginTop: 20, paddingLeft: 20}}>Aktuell keine Spiele
                    vorhanden!</div>}
            {this.state.viewModus === "kachel" &&
                <div style={{padding: 20}}>
                    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                        {this.state.games.map(game => <div key={"key_live_view_game_" + game.gameId}>
                            <LiveViewGame game={game} onFavorit={(boardId, boardName) => this.addFavorit(boardId, boardName)}
                                          favorits={this.state.favorits}
                                          playerIdsChecked={this.getIdsFromPlayerChecked()}
                                          onDetail={(boardId) => {
                                              this.setState({detailBoardId: boardId});
                                          }}/></div>)}
                    </div>
                </div>
            }
            {this.state.viewModus === "tv" &&
                <div style={{padding: 20}}>
                    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                        {this.state.games.map(game => <div key={"key_live_view_game_" + game.gameId}>
                            <LiveTVGame game={game}
                                        boardId={game.boardId}
                                        playerIdsChecked={this.getIdsFromPlayerChecked()}
                                        showAverage={this.state.liveTVShowAverage}
                                        showLastThrow={this.state.liveTVShowLastThrow}
                                        onLastThrow={(value) => this.setState({liveTVShowLastThrow: value})}
                                        onShowAverage={(value) => this.setState({liveTVShowAverage: value})}
                                        onFavorit={(boardId, boardName) => this.addFavorit(boardId, boardName)}
                                        favorits={this.state.favorits}
                                        onDetail={(boardId) => {
                                            this.setState({detailBoardId: boardId});
                                        }}
                                        /></div>)
                        }
                    </div>
                </div>
            }
            {this.state.viewModus === "liste" && <div className="grid" style={{padding: 0, margin: 0}}>
                <div className="col" style={{padding: 0, margin: 0}}>
                    <LiveViewTable games={this.state.games}
                                   playerIdsChecked={this.getIdsFromPlayerChecked()}
                                   onDetail={(boardId) => {
                                       this.setState({detailBoardId: boardId});
                                   }
                    }/>
                </div>
            </div>
            }

            {this.state.viewModus === "splitliste" && <div className="grid" style={{padding: 0, margin: 0}}>
                <div className="col" style={{padding: 0, margin: 0}}>
                    <LiveViewTable games={this.state.games.slice(this.state.games.length / 2)}
                                   playerIdsChecked={this.getIdsFromPlayerChecked()}
                                   onDetail={(boardId) => {
                                       this.setState({detailBoardId: boardId});
                                   }}/>
                </div>
                <div className="col" style={{padding: 0, margin: 0}}>
                    <LiveViewTable games={this.state.games.slice(0, this.state.games.length / 2)}
                                   playerIdsChecked={this.getIdsFromPlayerChecked()}
                                   onDetail={(boardId) => {
                                       this.setState({detailBoardId: boardId});
                                   }}/>
                </div>
            </div>
            }
        </div>
    }
}

LiveView.propTypes = {
    tournamentid: PropTypes.string,
    eventOperatorId: PropTypes.string,
    boardId: PropTypes.string,
    showEmbedded: PropTypes.bool.isRequired,
    showBoardSelection: PropTypes.bool,
};

export default LiveView;
