import React, {useEffect, useState} from 'react'
import DHSelect from "../../general/DHSelect";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";


export default function SelectZeitraumComp({eventOperatorId, refreshCounter, zeitraumId, onError, onChange}) {

    const [zeitraeume, setZeitraeume] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, [refreshCounter]);

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/zeitraeume/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setZeitraeume(json);
            },
            responseNotOk => {
                onError("Fehler: " + responseNotOk.message);
            },
            error => {
                onError("Fehler: " + error.message)
            });
    }

    const getModel = () => {
        let rows = [];
        for (const zeitraeumeElement of zeitraeume) {
            rows.push({id: zeitraeumeElement.id, value: zeitraeumeElement.bezeichnung});
        }
        return rows;
    }

    return <DHSelect width={200} value={zeitraumId} onChange={(id) => onChange(id)} id={"id-zeitraum"} label="Zeitraum" model={getModel()}/>
}
