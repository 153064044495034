import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import {PrimeIcons} from "primereact/api";
import ConfigUtil from "../../../../util/ConfigUtil";
import FetchUtil from "../../../../util/FetchUtil";
import TournamentTable from "../tournaments/TournamentTable";
import {openTournament} from "../../../../containers/views/public/PublicTournamentHelper";
import LoginDialog from "../tournaments/LoginDialog";
import PublicEventOperatorRanglisteTab from "./PublicEventOperatorRanglisteTab";
import PublicTournamentBreadCrumb from "../tournaments/PublicTournamentBreadCrumb";
import PublicEventOperatorLiveTab from "./PublicEventOperatorLiveTab";
import DHMobileDiv from "../../../general/DHMobileDiv";
import DHDesktopDiv from "../../../general/DHDesktopDiv";
import LastEventComp from "../../../LastEventComp";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import {ProgressSpinner} from "primereact/progressspinner";
import {useMediaQuery} from "@mui/material";

function PublicEventoperator({eventOperatorId, isLoggedIn, tab, ranking}) {

    const isMobile = useMediaQuery('(max-width:900px)');

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [tabValue, setTabValue] = useState(tab === undefined || tab === null ? "events" : tab);
    const [eventoperatorBezeichnung, setEventoperatorBezeichnung] = useState("");
    const [tournamentsNew, setTournamentsNew] = useState(undefined);
    const [tournamentsOpen, setTournamentsOpen] = useState(undefined);
    const [tournamentsClosed, setTournamentsClosed] = useState(undefined);

    const [showLoginDialog, setShowLoginDialog] = React.useState(false);

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        if (eventOperatorId === null || eventOperatorId === undefined) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.public + "/eventoperator/" + eventOperatorId;
        FetchUtil.fetchGet(url,
            json => {
                setTournamentsNew(json.allTournamentsNew);
                setTournamentsOpen(json.allTournamentsStarted);
                setTournamentsClosed(json.allTournamentsClosed.reverse());
                setEventoperatorBezeichnung(json.eventoperatorBezeichnung);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Daten: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Daten: " + error.message);
            });
    }

    const erzeugeTab = (value, key, icon, width) => {
        return <div style={{color: "white", fontSize: 20, width: isMobile ? "100%" : width, marginLeft:5, cursor:"pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>

            <i className={icon}
               style={{fontSize: 20, marginLeft: 5, marginRight: 5, color: "white", cursor: "pointer"}}/>
            {!isMobile && value.toUpperCase()}
        </div>
    }

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Events", "events", PrimeIcons.TH_LARGE, 250));
        returnValue.push(erzeugeTab("Abgeschlossene Events", "tournament_closed", PrimeIcons.TIMES_CIRCLE, 350));
        returnValue.push(erzeugeTab("Ranglisten", "ranking", PrimeIcons.STAR, 200));
        returnValue.push(erzeugeTab("Live", "live", PrimeIcons.EYE, 120));
        return returnValue;
    }

    const getTabpanel = () => {
        if( tabValue === "events" ) {
            return <div className="darthelfer-background-blue" style={{padding: 10}}>
                <div style={{color: "white", fontSize: 20, marginLeft:5}}>
                    Laufende Events
                </div>
                {getTournamentTableArea(tournamentsOpen)}
                <div style={{color: "white", fontSize: 20, marginLeft:5, marginTop:20}}>
                    Events zur Anmeldung
                </div>
                {getTournamentTableArea(tournamentsNew)}
            </div>
        } else if (tabValue === "tournament_closed") {
            return <div className="darthelfer-background-blue" style={{padding: 10}}>
                <div style={{color: "white", fontSize: 20, marginLeft:5}}>
                    Abgeschlossene Events
                </div>
                {getTournamentTableArea(tournamentsClosed)}
            </div>
        } else if (tabValue === "ranking") {
            return getRanglistenArea();
        } else if (tabValue === "live") {
            return getLiveViewArea();
        }
    }

    const getRanglistenArea = () => {
        return <PublicEventOperatorRanglisteTab eventOperatorId={eventOperatorId} ranking={ranking}/>
    }

    const getLiveViewArea = () => {
        return <PublicEventOperatorLiveTab eventOperatorId={eventOperatorId} />
    }


    const getTournamentTableArea = (tournaments) => {
        return <div className="darthelfer-background-blue">
            <TournamentTable tournaments={tournaments} showEventOperator={false}
                             showOnline={true} showSteeldarts={true} showEDart={true}
                             onOpenEventOperator={() => ""}
                             onOpen={(tournament) => openTournament(tournament, isLoggedIn, () => setShowLoginDialog(true))}/>
        </div>
    }

    if( tournamentsOpen === undefined ) {
        return <div>
            <ErrorInfoComponent infoMessage={undefined} errorMessage={errorMessage} onClearInfoMessage={() => ""}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <ProgressSpinner style={{width:100, height:100}}/>
        </div>
    }

    return <div style={{padding: 0, backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
        <DHMobileDiv comp={
            <>
                <div>
                    <PublicTournamentBreadCrumb isMobil={true} isLiveView={false} eventOperatorId={eventOperatorId} eventOperatorBezeichnung={eventoperatorBezeichnung}/>
                </div>
                <hr style={{marginTop: 0, marginBottom: 0}}/>
                <div style={{paddingLeft: 10, backgroundColor: "white", color: "black", fontSize: 20}}>
                    {eventoperatorBezeichnung}
                </div>
                <div style={{display: "flex"}}>
                    <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENTS.route} style={{textDecoration: "none"}}>
                        <img alt="Logo" src={"/images/darthelfer_quer_weiss.png"} style={{height: 50, margin: 10}}/>
                    </NavLink>
                </div>
                <LastEventComp currentId={""}/>
            </>
        }/>
        <DHDesktopDiv comp={
            <>
                <div style={{width: "100%"}}>
                    <PublicTournamentBreadCrumb isMobil={false} isLiveView={false} eventOperatorId={eventOperatorId} eventOperatorBezeichnung={eventoperatorBezeichnung}/>
                </div>
                <hr style={{marginTop: 0, marginBottom: 0}}/>
                <div style={{paddingLeft: 20, marginBottom: 5, backgroundColor: "white", color: "black", fontSize: "2em"}}>
                    {eventoperatorBezeichnung}
                </div>
                <LastEventComp currentId={""}/>
            </>
        }/>
        <div style={{display: "flex", marginBottom: 20}}>
            {getTabs()}
        </div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        {getTabpanel()}
        {showLoginDialog &&
            <LoginDialog onClose={() => setShowLoginDialog(false)}/>
        }
    </div>
}

PublicEventoperator.propTypes = {
    userId: PropTypes.string,
    isLoggedIn: PropTypes.bool.isRequired,
    eventOperatorId: PropTypes.string.isRequired,
};

export default PublicEventoperator;
