import React, {useState} from 'react'
import Turnierbaum from "../../tournament/Turnierbaum";
import KoDKOGameListe from "../../tournament/KoDKOGameListe";
import {Button} from "primereact/button";

export default function PublicTournamentTreeTab({tournament, model, boardGames, liveGames, tournamentBeschreibungen, boardReihenfolge}) {
    let [showListe, setShowListe] = useState(false);

    return <div style={{backgroundColor: "#022836"}}>
        { !showListe &&
            <div>
                <div style={{textAlign:"left", padding:10}}>
                    <Button icon="pi pi-list"
                            onClick={() => setShowListe(!showListe)}
                            style={{marginRight: 10}}/>
                </div>
                <Turnierbaum userId={"keine-angabe"}
                             turnier={tournament}
                             tournamentGroup={1}
                             userGames={[]}
                             model={model}
                             readonly={true}
                             boardGames={boardGames}
                             liveGames={liveGames}
                             tournamentBeschreibungen={tournamentBeschreibungen}
                             boardReihenfolge={boardReihenfolge}
                             loadData={() => ""}/>
            </div>
        }
        {showListe &&
            <KoDKOGameListe tournamentPlayerEntities={tournament.tournamentPlayerEntities}
                            scoringWithDarthelfer={tournament.scoringWithDarthelfer}
                            tournamentBeschreibungen={tournamentBeschreibungen}
                            online={tournament.online}
                            models={model}
                            eventOperatorId={tournament.eventOperatorId}
                            ergebnisse={tournament.tournamentErgebnisEntities}
                            boardReihenfolge={boardReihenfolge}
                            boardGames={boardGames}
                            liveGames={liveGames}
                            readonly={true}
                            tournamentId={tournament.id}
                            tournamentGroup={1}
                            onRefresh={() => ""}
                            onChangeView={() => setShowListe(!showListe)}/>
        }
    </div>
}
