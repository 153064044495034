import React, {useEffect, useState} from 'react'
import {Button, TableContainer} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import DHTextFieldSearch from "../../general/DHTextFieldSearch";
import SelectZeitraumComp from "./SelectZeitraumComp";
import SelectRegionComp from "./SelectRegionComp";
import LigaVerwaltenDialog from "./LigaVerwaltenDialog";
import ConfirmDialog from "../../general/ConfirmDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import EditIcon from "@mui/icons-material/Edit";
import LigaRegionVerwaltenDialog from "./LigaRegionVerwaltenDialog";
import LigaZeitraumVerwaltenDialog from "./LigaZeitraumVerwaltenDialog";


export default function LigaLigenUebersicht({eventOperatorId, onError}) {

    const CELL_PADDING_VALUE = 10;

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [filter, setFilter] = useState("");
    const [filterRegionId, setFilterRegionId] = useState(undefined);
    const [filterZeitraumId, setFilterZeitraumId] = useState(undefined);

    const [ligen, setLigen] = useState([]);
    const [zeitraeume, setZeitraeume] = useState([]);
    const [regionen, setRegionen] = useState([]);

    const [regionRefreshCounter, setRegionRefreshCounter] = useState(0);
    const [zeitraumRefreshCounter, setZeitraumRefreshCounter] = useState(0);

    const [selectedLiga, setSelectedLiga] = useState(undefined);

    const [showLigaVerwaltenDialog, setShowLigaVerwaltenDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showRegionVerwaltenDialog, setShowRegionVerwaltenDialog] = useState(false);
    const [showZeitraeumeVerwaltenDialog, setShowZeitraemeVerwaltenDialog] = useState(false);

    useEffect(() => {
        ladeDaten();
        ladeZeitraeume();
        ladeRegionen();
    }, []);

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/ligen/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setLigen(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const ladeZeitraeume = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/zeitraeume/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setZeitraeume(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const ladeRegionen = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/regionen/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setRegionen(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const deleteLiga = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/liga/delete/" + selectedLiga.id;
        FetchUtil.fetchDelete(url,
            {},
            json => {
                setInfoMessage("Löschen war erfolgreich...");
                setSelectedLiga(undefined);
                setShowDeleteDialog(false);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedLiga(undefined);
                setShowDeleteDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setSelectedLiga(undefined);
                setShowDeleteDialog(false);
            });
    }


    const getZeitraumBezeichnung = (id) => {
        if( zeitraeume.length === 0) {
            return "";
        }
        return zeitraeume.filter(tmp => tmp.id === id)[0].bezeichnung;
    }

    const getRegionBezeichnung = (id) => {
        if( regionen.length === 0) {
            return "";
        }
        return regionen.filter(tmp => tmp.id === id)[0].bezeichnung;
    }

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        let filteredLigen;
        if( filter.length > 0 ) {
            filteredLigen = ligen.filter(tmp => tmp.name.toUpperCase().includes(filter.toUpperCase()));
        } else {
            filteredLigen = ligen;
        }
        if( filterRegionId ) {
            filteredLigen = filteredLigen.filter(tmp => tmp.regionId === filterRegionId);
        }
        if( filterZeitraumId ) {
            filteredLigen = filteredLigen.filter(tmp => tmp.zeitraumId === filterZeitraumId);
        }

        filteredLigen.forEach(liga => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={liga.id}>
                <TableCell style={style}>{liga.name}</TableCell>
                <TableCell style={style}>{getRegionBezeichnung(liga.regionId)}</TableCell>
                <TableCell style={style}>{getZeitraumBezeichnung(liga.zeitraumId)}</TableCell>
                <TableCell style={style}>{liga.aktiv ? <DoneIcon/> : <BlockIcon/>}</TableCell>
                <TableCell style={style}>
                    <EditIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setSelectedLiga(liga);
                        setShowLigaVerwaltenDialog(true);
                    }}/>
                    <DeleteIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setSelectedLiga(liga);
                        setShowDeleteDialog(true);
                    }}/>
                </TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{paddingTop: 20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
            <span style={{marginRight: 10}}>
                <Button variant="contained" startIcon={<AddCircleOutlineIcon/>} onClick={() => setShowZeitraemeVerwaltenDialog(true)}>Zeiträume verwalten</Button>
            </span>
            <span style={{marginRight: 10}}>
                <Button variant="contained" startIcon={<AddCircleOutlineIcon/>} onClick={() => setShowRegionVerwaltenDialog(true)}>Regionen verwalten</Button>
            </span>
        </div>
        <div style={{paddingTop: 20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
            <span style={{marginRight: 10}}>
                <Button variant="contained" startIcon={<AddCircleOutlineIcon/>} onClick={() => setShowLigaVerwaltenDialog(true)}>Ligen hinzufügen</Button>
            </span>
            <span style={{marginRight: 5}}>
                <DHTextFieldSearch id="id-filter-ligen" onChange={(text) => setFilter(text)} value={filter} variant={"filled"} width={100} label="Filter"/>
            </span>
            <span style={{marginRight: 5}}>
                <SelectRegionComp eventOperatorId={eventOperatorId}
                                  regionId={filterRegionId} refreshCounter={regionRefreshCounter}
                                  onChange={(id) => setFilterRegionId(id)} onError={(message) => setErrorMessage(message)}/>
            </span>
            <span style={{marginRight: 5}}>
                <SelectZeitraumComp eventOperatorId={eventOperatorId}
                                    zeitraumId={filterZeitraumId} refreshCounter={zeitraumRefreshCounter}
                                    onChange={(id) => setFilterZeitraumId(id)} onError={(message) => setErrorMessage(message)}/>
            </span>
        </div>
        <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
            <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Bezeichnung</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Region</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Zeitraum</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 50,}}>Aktiv</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 130,}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows()}
                </TableBody>
            </Table>
        </TableContainer>
        {showLigaVerwaltenDialog && <LigaVerwaltenDialog eventOperatorId={eventOperatorId} selectedLiga={selectedLiga}
                                                         onClose={() => {
                                                             setShowLigaVerwaltenDialog(false);
                                                             setSelectedLiga(undefined);
                                                         }} onRefresh={() => ladeDaten()}/>}
        {showDeleteDialog && <ConfirmDialog visible={true} header={"Liga löschen"} textOben={<div>
            <div>Möchtest du die Liga wirklich löschen?</div>
        </div>} callBackOnNein={() => {
            setShowDeleteDialog(false);
            setSelectedLiga(undefined);
        }} callBackOnJa={() => deleteLiga()}/>}
        {showRegionVerwaltenDialog && <LigaRegionVerwaltenDialog eventOperatorId={eventOperatorId}
                                                                 onClose={() => setShowRegionVerwaltenDialog(false)} onRefresh={() => {
            ladeRegionen();
            setRegionRefreshCounter(regionRefreshCounter+1);
        }}/>}
        {showZeitraeumeVerwaltenDialog && <LigaZeitraumVerwaltenDialog eventOperatorId={eventOperatorId}
                                                                       onClose={() => setShowZeitraemeVerwaltenDialog(false)}
                                                                       onRefresh={() => {
                                                                           ladeZeitraeume();
                                                                           setZeitraumRefreshCounter(zeitraumRefreshCounter+1);
                                                                       }}/>}
    </div>
}
