import React, {useEffect, useState} from 'react'
import ConfigUtil from "../../../../util/ConfigUtil";
import FetchUtil from "../../../../util/FetchUtil";
import TournamentTable from "./TournamentTable";
import PropTypes from "prop-types";
import LoginDialog from "./LoginDialog";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import {InputText} from "primereact/inputtext";
import {PrimeIcons} from "primereact/api";
import {Button} from "primereact/button";
import {openEventOperator, openTournament} from "../../../../containers/views/public/PublicTournamentHelper";
import PublicTournamentBreadCumb from "./PublicTournamentBreadCrumb";

import DHDesktopDiv from "../../../general/DHDesktopDiv";
import DHMobileDiv from "../../../general/DHMobileDiv";
import PublicEventoperatorSearchTab from "../eventoperator/PublicEventoperatorSearchTab";
import LastEventComp from "../../../LastEventComp";
import {useMediaQuery} from "@mui/material";


function PublicTournaments({isLoggedIn}) {

    const isMobile = useMediaQuery('(max-width:900px)');

    const [filterTournamentText, setFilterTournamentText] = React.useState("");

    const [showLoginDialog, setShowLoginDialog] = React.useState(false);
    const [tabValue, setTabValue] = React.useState("turniere");
    let [tournaments, setTournaments] = useState([]);
    let [tournamentsLive, setTournamentsLive] = useState([]);
    const [showEDart, setShowEDart] = useState(true);
    const [showSteeldarts, setShowSteeldarts] = useState(true);
    const [showOnline, setShowOnline] = useState(true);

    useEffect(() => {
        searchForTournaments();
    }, []);

    const filterTournaments = () => {
        if (filterTournamentText === "") {
            return tournaments;
        }
        let returnList = [];
        for (const tournament of tournaments) {
            let leagueOperatorBezeichnungIncludes = tournament.leagueOperatorBezeichnung !== null && tournament.leagueOperatorBezeichnung.toUpperCase().includes(filterTournamentText.toUpperCase());
            let eventOperatorBezeichnungIncludes = tournament.eventOperatorBezeichnung !== null && tournament.eventOperatorBezeichnung.toUpperCase().includes(filterTournamentText.toUpperCase());
            let bezeichnungIncludes = tournament.bezeichnung.toUpperCase().includes(filterTournamentText.toUpperCase());
            if (leagueOperatorBezeichnungIncludes || eventOperatorBezeichnungIncludes || bezeichnungIncludes) {
                returnList.push(tournament);
            }
        }
        return returnList;
    }

    const searchForTournaments = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.public + "/tournaments",
            json => {
                setTournaments(json.tournaments);
                setTournamentsLive(json.liveTournaments);
            },
            responseNotOk => {
                console.log(responseNotOk.message);
            },
            error => {
                console.log(error.message);
            });
    }

    const erzeugeTab = (value, key, icon, width) => {
        return <div style={{color: "white", fontSize: 20, width: isMobile ? "100%" : width, marginLeft:5, cursor:"pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>

            <i className={icon}
               style={{fontSize: 20, marginLeft: 5, marginRight: 5, color: "white", cursor: "pointer"}}/>
            {!isMobile && value.toUpperCase()}
        </div>
    }

    const getVeranstalterTab = () => {
        return <div style={{
            marginTop: 10,
            padding: 20,
            color:"white",
        }}>
            <PublicEventoperatorSearchTab />
        </div>
    }

    const getLiveTab = () => {
        return <div style={{marginTop: 10, padding: 10}}>
            <div style={{padding: 20, color: "white", fontSize: 20}}>
                {tournamentsLive.length === 0 ? "Aktuell keine Live-Events" : "Live-Events"}
            </div>
            {tournamentsLive.length !== 0 &&
                <TournamentTable tournaments={tournamentsLive} showEventOperator={true}
                             showOnline={true} showSteeldarts={true} showEDart={true}
                             onOpenEventOperator={(tournament) => openEventOperator(tournament)}
                             onOpen={(tournament) => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_TOURNAMENT_LIVE_VIEW.route + "?tournamentid=" + tournament.id, "_self")}/>
            }
        </div>
    }

    const getTournamentsTab = () => {
        return <>
            <div style={{display: "flex", marginLeft: 15, marginBottom: 10}}>
                <div style={{color: "white", marginTop: 10, marginRight: 10, fontSize: 12}}>Anzahl
                    Events: {tournaments.length}</div>
            </div>
            <div style={{display: "flex", marginLeft: 15, marginBottom: 10}}>
                <InputText id="filterTournamentText" value={filterTournamentText} placeholder={"Suche"}
                           onChange={(e) => setFilterTournamentText(e.target.value)} style={{width: 70, marginRight: 5}}/>
                <Button label="Steeldarts"
                        className={showSteeldarts ? "p-button-primary" : "p-button-secondary"}
                        style={{width: 90}} onClick={() => setShowSteeldarts(!showSteeldarts)}/>
                <Button label="E-Dart"
                        className={showEDart ? "p-button-primary" : "p-button-secondary"}
                        style={{width: 70}} onClick={() => setShowEDart(!showEDart)}/>
                <Button label="Online"
                        className={showOnline ? "p-button-primary" : "p-button-secondary"}
                        style={{width: 70}} onClick={() => setShowOnline(!showOnline)}/>
            </div>
            <div className="darthelfer-background-blue" style={{padding: 10}}>
                <TournamentTable tournaments={filterTournaments()} showEventOperator={true}
                                 showSteeldarts={showSteeldarts}
                                 showEDart={showEDart}
                                 showOnline={showOnline}
                                 onOpenEventOperator={(tournament) => openEventOperator(tournament)}
                                 onOpen={(tournament) => openTournament(tournament, isLoggedIn, () => setShowLoginDialog(true))}/>
            </div>
        </>
    }

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Events", "turniere", PrimeIcons.TH_LARGE, "100%"));
        returnValue.push(erzeugeTab("Livescoring", "live", PrimeIcons.EYE, "100%"));
        returnValue.push(erzeugeTab("Veranstalter", "veranstalter", PrimeIcons.BOX, "100%"));
        return returnValue;
    }

    const getTabpanel = () => {
        if (tabValue === "turniere") {
            return getTournamentsTab();
        } else if (tabValue === "live") {
            return getLiveTab();
        } else if (tabValue === "veranstalter") {
            return getVeranstalterTab();
        }
    }

    const getDesktopHeader = () => {
        return <>
            <div style={{width: "100%", display: "block"}}>
                <PublicTournamentBreadCumb isMobil={false}/>
                <LastEventComp currentId={""}/>
            </div>
        </>
    }

    const getMobileHeader = () => {
        return <>
            <div>
                <PublicTournamentBreadCumb isMobil={true}/>
            </div>
            <div style={{display: "flex"}}>
                <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENTS.route} style={{textDecoration: "none"}}>
                    <img alt="Logo" src={"/images/darthelfer_quer_weiss.png"} style={{height: 50, margin: 10}}/>
                </NavLink>
            </div>
            <LastEventComp currentId={""}/>
        </>
    }

    return <div style={{backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
        <DHDesktopDiv comp={getDesktopHeader()}/>
        <DHMobileDiv comp={getMobileHeader()}/>
        <div style={{marginBottom: 20}}>
            <div style={{display: "flex"}}>
                {getTabs()}
            </div>
        </div>
        {getTabpanel()}
        {showLoginDialog &&
            <LoginDialog onClose={() => setShowLoginDialog(false)}/>
        }
    </div>
}

PublicTournaments.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired
};

export default PublicTournaments;
