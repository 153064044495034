import React, {useEffect, useState} from 'react'
import DHDialog from "../general/DHDialog";
import PlayerUtil from "../../util/PlayerUtil";
import {InputNumber} from "primereact/inputnumber";
import {PrimeIcons} from "primereact/api";
import {Button} from "primereact/button";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import ErrorInfoComponent from "../general/ErrorInfoComponent";

export default function BestplatzierteManuellDialog({tournament, gruppenTabellen, bestplatzierteDtos, disabled, onClose, onRefresh }) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [playerValues, setPlayerValues] = useState([]);

    useEffect(() => {
        load();
    }, []);

    const load = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament+ "/bestplatzierte/load/" + tournament.id,
            json => {
                let newPlayerValues = [];
                for (const jsonElement of json) {
                    newPlayerValues.push({playerId: jsonElement.playerId, value: jsonElement.platz})
                }
                setPlayerValues(newPlayerValues);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden! " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler beim Laden!" + error.message)
            }
        );
    }

    const save = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament+ "/bestplatzierte/save/" + tournament.id,
            playerValues,
            json => {
                setInfoMessage("Es wurde erfolgreich gespeichert.");
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern! " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler beim Speichern! " + error.message)
            }
        );
    }

    const getFaktor = (playerId) => {
        for (const bestplatzierteDto of bestplatzierteDtos) {
            if( bestplatzierteDto.playerId === playerId) {
                return bestplatzierteDto.faktor;
            }
        }
        return undefined;
    }

    const getBestplatzietenPlayer = (tabelle) => {
        return tabelle[tournament.anzahlSpielerGold];
    }

    const getValue = (playerId) => {
        let tmp = playerValues.find(p => p.playerId === playerId);
        if( tmp ) {
            return tmp.value;
        }
        return 0;
    }

    const valueToStore = (playerId, value) => {
        let newValues;
        let tmp = playerValues.find(p => p.playerId === playerId);
        if( tmp ) {
            newValues = [...playerValues.filter(t => t.playerId !== playerId)];
            newValues.push({playerId: playerId, value: value});
        } else {
            newValues = [...playerValues];
            newValues.push({playerId: playerId, value: value});
        }
        setPlayerValues(newValues);
    }

    const getPlayers = () => {
        let players = [];
        for (const gruppe of gruppenTabellen) {
            let bestplatzietenPlayer = getBestplatzietenPlayer(gruppe.rangliste);
            if( bestplatzietenPlayer !== undefined) {
                let spielerName = PlayerUtil.getSpielerName(tournament.tournamentPlayerEntities, bestplatzietenPlayer.playerid);
                let faktor = getFaktor(bestplatzietenPlayer.playerid);
                players.push(<div key={"key_player_" + bestplatzietenPlayer.playerid} style={{paddingBottom: 10,display:"flex", verticalAlign:"middle", alignItems:"center"}}>
                    <InputNumber inputStyle={{width: 50}} minFractionDigits={0} min={0} max={tournament.anzahlGruppen} style={{width: 50}}
                                 disabled={disabled}
                                 value={getValue(bestplatzietenPlayer.playerid)}
                                 onChange={(e) => valueToStore(bestplatzietenPlayer.playerid, e.value)}/>
                    <span style={{marginLeft: 10}}>Gruppe: {gruppe.gruppe}</span>
                    <span style={{marginLeft: 10}}>Spieler: {spielerName}</span>
                    <span style={{marginLeft: 20}}>Faktor: {faktor}</span>
                </div>)
            }
        }
        return players;
    }

    const getDataArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
            <div style={{marginBottom: 20, fontWeight: "bold"}}>
                Wichtig: Sofern es mehrere gleich bewertete Bestplatzierte gibt, muss eine manuelle Auswahl erfolgen.<br/>
                Nimm diese Einstellung erst vor sobald in der Gruppenphase alle Spiele gespielt sind. Vorher macht es keinen Sinn, da sich die Bestplatzierten noch mal ändern
                können.<br/>
                Sofern du die Auswahl schon vor Abschluss aller Spiele triffst, wird darthelfer die von dir gewählten Spieler, die dann nicht mehr auf dem bestplatzierten Platz sind, ignorieren.<br/><br/>
                Das System entscheidet nach dem Faktor. Die Spieler mit dem höheren Faktor kommen zuerst weiter.<br/><br/>
                In diesem Dialog wird die Reihenfolge der Bestplatzierten festgelegt, in der die dann zur Auslosung berücksichtigt werden.<br/>
                Nummeriere einfach von 1-x durch, nach dieser Reihenfolge kommen die Spieler weiter. Sofern du Felder mit 0 füllst, werden diese Spieler für den KO-Baum nicht berücksichtigt.<br/>
            </div>
            <div style={{marginBottom: 20}}>Hier werden alle Bestplatzierten, somit alle {tournament.anzahlSpielerGold + 1}., angezeigt.</div>
            <div style={{marginBottom: 10}}>
                <Button label="Speichern" icon={PrimeIcons.SAVE} onClick={() => save()} style={{marginLeft:10}} disabled={disabled}/>
            </div>
            {getPlayers()}
        </div>
    }

    return <DHDialog onClose={onClose} buttons={[]} showCloseButton={true} title="Bestplatzierte verwalten"
                     comp={getDataArea()}/>
}
