import React, {useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Checkbox} from "primereact/checkbox";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";
import DHButtonSave from "../../general/DHButtonSave";
import DHNumberTextField from "../../general/DHNumberTextField";
import DHSelect from "../../general/DHSelect";
import {IconButton} from "@mui/material";
import DHIconAdd from "../../general/DHIconAdd";
import DHIconMinus from "../../general/DHIconMinus";

export default function TournamentGroupManuellDialog({tournamentId, players, callBackOnClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [spielerNurOhneZuordnung, setSpielerNurOhneZuordnung] = useState(false);
    let [groupEinteilung, setGroupEinteilung] = useState([]);

    let [anzahlGruppen, setAnzahlGruppen] = useState(1);

    const [selectedGroup, setSelectedGroup] = useState(undefined);

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/gruppenmanuell/" + tournamentId,
            json => {
                setAnzahlGruppen(json.anzahlGruppen);
                setGroupEinteilung(json.einteilungDtos);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            })
    }

    const save = () => {
        setErrorMessage(undefined);
        let data = {anzahlGruppen: anzahlGruppen, einteilungDtos: groupEinteilung};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/gruppenmanuell/" + tournamentId,
            data,
            json => {
                setInfoMessage("Speichern war erfolgreich!")
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );
    }

    const entfernen = (playerId) => {
        let neueEinteilung = groupEinteilung.filter(tmpGroup => tmpGroup.playerId !== playerId);
        setGroupEinteilung(neueEinteilung);
    }

    const hinzufuegen = (playerId) => {
        setErrorMessage(undefined);
        if (selectedGroup === undefined) {
            setErrorMessage("Gruppe muss gewählt sein.");
            return;
        }
        let neueEinteilung = groupEinteilung.slice();
        neueEinteilung.push({gruppe: selectedGroup, playerId: playerId});
        setGroupEinteilung(neueEinteilung);
    }

    const getPlayerRow = (player) => {
        let inKeinerGruppeEnthalten = groupEinteilung.filter(tmpGroup => tmpGroup.playerId === player.id).length === 0;
        let gruppe = undefined;
        let problem = false;
        if (!inKeinerGruppeEnthalten) {
            gruppe = groupEinteilung.filter(tmpGroup => tmpGroup.playerId === player.id)[0].gruppe;
            problem = gruppe > anzahlGruppen;
        }
        if (spielerNurOhneZuordnung && gruppe !== undefined) {
            return "";
        }
        return <div key={"key_player_" + player.id} style={{marginBottom: 10}}>
            {!problem && <IconButton disabled={!inKeinerGruppeEnthalten} color="primary" onClick={() => hinzufuegen(player.id)}>
                <DHIconAdd fontSize={30}/>
            </IconButton>
            }
            {problem && <IconButton color="primary" onClick={() => entfernen(player.id)}>
                <DHIconMinus fontSize={30}/>
            </IconButton>
            }
            <span style={{color: problem ? "red" : "black"}}>{player.name} {gruppe !== undefined && " (Gruppe: " + gruppe + ")"}</span>
            {problem && <>
                <br/> <span style={{color: "red"}}>Entfernen, Gruppe ist nicht vorhanden.</span>
            </>
            }
        </div>
    }

    const getGroupAuswahl = () => {
        let groups = [];
        if (anzahlGruppen === null || anzahlGruppen === undefined || anzahlGruppen === "") {
            return groups;
        }
        for (let i = 1; i <= anzahlGruppen; i++) {
            groups.push({value: i + "", id: i});
        }
        return groups;
    }

    const getPlayerGruppenRows = (gruppe) => {
        let tmpPlayers = [];
        for (const player of players) {
            let enthalten = groupEinteilung.filter(tmpGroup => tmpGroup.gruppe === gruppe && tmpGroup.playerId === player.id).length !== 0;
            if (enthalten) {
                tmpPlayers.push(player);
            }
        }
        let rows = [];
        for (const player of tmpPlayers) {
            rows.push(<div key={"key_group_" + gruppe + "_player_" + player.id} style={{marginBottom: 10}}>
                <IconButton color="primary" onClick={() => entfernen(player.id)}>
                    <DHIconMinus fontSize={30}/>
                </IconButton>
                {player.name}
            </div>)
        }
        return rows;
    }

    const getGruppen = () => {
        let gruppen = [];
        for (let i = 1; i <= anzahlGruppen; i++) {
            gruppen.push(<Panel key={"key_gruppe_" + i} header={"Gruppe: " + i} style={{width: 200, marginLeft: 10, marginBottom: 10}}>
                {getPlayerGruppenRows(i)}
            </Panel>)
        }
        return gruppen;
    }

    const canSave = () => {
        for (const player of players) {
            let gruppeVorhanden = groupEinteilung.filter(tmpGroup => tmpGroup.playerId === player.id).length > 0;
            if (gruppeVorhanden) {
                let gruppe = groupEinteilung.filter(tmpGroup => tmpGroup.playerId === player.id)[0].gruppe;
                let problem = gruppe > anzahlGruppen;
                if (problem) {
                    return false
                }
            }
        }
        return true;
    }

    const dialogFooter = (
        <div>
            <DHButtonSave disabled={!canSave()} onClick={() => save()}/>
        </div>
    );

    return <DHDialog onClose={() => callBackOnClose()} showCloseButton={true} title="Gruppen von Hand" show={true} buttons={dialogFooter}
                     comp={<>
                         <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                             onClearErrorMessage={() => setErrorMessage(undefined)}/>
                         <div style={{marginBottom: 20}}>
                             <DHNumberTextField id="id-anzahl-gruppen" value={anzahlGruppen} onChange={(text) => setAnzahlGruppen(text)}
                                                label="Anzahl Gruppen"
                                                min={1} width={300}/>
                         </div>
                         <div style={{marginBottom: 20}}>
                             <DHSelect id="id-gruppen" value={selectedGroup} width={300} label="Gruppe" onChange={(id) => setSelectedGroup(id)}
                                       model={getGroupAuswahl()}/>
                         </div>
                         <div className="grid">
                             <div className="col-12 md:col-6 lg:col-3">
                                 <Panel header="Spielerübersicht">
                                     <div style={{marginLeft: 10, marginBottom: 10}}>
                                         <Checkbox onChange={() => setSpielerNurOhneZuordnung(!spielerNurOhneZuordnung)}
                                                   checked={spielerNurOhneZuordnung}></Checkbox>
                                         <span style={{marginLeft: 10}} onClick={() => setSpielerNurOhneZuordnung(!spielerNurOhneZuordnung)}>Nur Spieler ohne Zuordnung anzeigen</span>
                                     </div>
                                     <hr style={{marginBottom: 10}}/>
                                     {players.map(player => getPlayerRow(player))}
                                 </Panel>
                             </div>
                             <div className="col-12 md:col-6 lg:col-9">
                                 <div style={{display: "flex", flexWrap: "wrap"}}>
                                     {getGruppen()}
                                 </div>
                             </div>
                         </div>
                     </>}/>
}
